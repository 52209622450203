import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import moment from "moment/moment";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { SUPLIMENTALSALES_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import SupplementalSalesForm from "./SupplementalSalesForm";
import { getAllSupplementalSales } from "../ShopifySlice";
import "react-toastify/dist/ReactToastify.css";

const SupplementalSales = () => {
  const [suplimentalSalesDetails, setSuplimentalSalesDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteSuplimentalSales, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getSupplementalSalesRecords,
    {
      data: supplementalSalesList,
      isError: isSupplementalSalesError,
      isLoading: isSupplementalSalesLoading,
      isFetching: isSupplementalSalesFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Product",
      accessor: "ShopifyProductTitle",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "SKU",
      accessor: "ShopifySKU",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Vendor",
      accessor: "Vendor",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Date",
      accessor: "CreatedAt",
      type: "Date",
      Cell: ({ value }) => {
        return value ? moment(value).format("YYYY-MM-DD") : "-";
      },
    },
    {
      Header: "Quantity",
      accessor: "Quantity",
      type: "Number",
      filter: true,
    },
    {
      Header: "Price",
      accessor: "Price",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      })
    },
    {
      Header: "GrossSales",
      accessor: "GrossSales",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      })
    },
    {
      Header: "Return Quantity",
      accessor: "ReturnQuantity",
      type: "Number",
      filter: true,
    },
    {
      Header: "Return Discount",
      accessor: "ReturnDiscount",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      })
    },
    {
      Header: "Return",
      accessor: "Return",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      })
    },
    {
      Header: "Discount",
      accessor: "Discount",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      })
    },
    {
      Header: "NetSales",
      accessor: "NetSales",
      type: "Number",
      filter: true,
      Cell: ({ value }) => {
        return value ? <span className={value <= 0 && "text-danger"}>${value}</span> : <span className="text-danger">$0</span>
      },
    },
  ];

  const handleEditData = (supplementalSalesData) => {
    setSelectedRecord(supplementalSalesData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteSuplimentalSales({
      entity: "ShopifyReference/SupplementalEntry",
      id: selectedRecord.Id,
      tag: SUPLIMENTALSALES_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("SupplementalSales deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getSupplementalSalesRecords({
      entity: "ShopifyReference/ShopifySupplementalEntries",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SUPLIMENTALSALES_TAG,
    });
    setSuplimentalSalesDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isSupplementalSalesLoading &&
      !isSupplementalSalesError &&
      supplementalSalesList &&
      !isSupplementalSalesFetching
    ) {
      dispatch(getAllSupplementalSales(supplementalSalesList.value));
      let suplimentalSalesData = [...supplementalSalesList.value];
      setSuplimentalSalesDetails({
        ...suplimentalSalesDetails,
        tableRowsData: supplementalSalesList.value ? suplimentalSalesData : [],
        totalData:
          supplementalSalesList && supplementalSalesList["@odata.count"]
            ? supplementalSalesList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isSupplementalSalesError,
    supplementalSalesList,
    isSupplementalSalesLoading,
    isSupplementalSalesFetching,
  ]);


  return (
    <Container data-testid="test-app">
      <Row>
        {suplimentalSalesDetails && (
          <DataReactTable
            reactTableData={suplimentalSalesDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Supplemental Sales"
            isFetching={isSupplementalSalesFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          testid="addModal"
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Supplemental Sales`}
          header
          btn="Success"
          xl
          message={
            <SupplementalSalesForm
              editData={selectedRecord}
              setIsAddModalOpen={setIsAddModalOpen}
              isAddModalOpen={isAddModalOpen} />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord?.AppName}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default SupplementalSales;
