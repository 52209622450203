import React from 'react';
import TopbarNavAdmin from './TopbarNavAdmin';

const TopbarNav = () => (
  <nav className="topbar__nav">
    <TopbarNavAdmin />
  </nav>
);

export default TopbarNav;
