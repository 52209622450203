import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ onClick, sidebarCollapse }) => {
  const role = useSelector((state) => state.user.role);
  const [displayComponent, setDisplayComponent] = useState({
    component: <Loading loading={true} />,
  });

  useEffect(() => {
    setTimeout(() => {
      setDisplayComponent({ component: <Redirect to="/" /> });
    }, 10000);
  }, []);
  if (
    role &&
    role.length &&
    !(role.includes(ADMIN) || role.includes(SUPER_ADMIN)) &&
    role.includes(USER)
  ) {
    return <Redirect to="/" />;
  }
  return role &&
    role.length > 0 &&
    (role.includes(ADMIN) || role.includes(SUPER_ADMIN)) ? (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Admin Dashboard"
          icon="home"
          route="/admin_dashboard"
          onClick={onClick}
        />
      </ul>
      {role.includes(SUPER_ADMIN) && (
        <>
          <ul className="sidebar__block">
            <SidebarCategory
              title="Auth"
              icon="list"
              sidebarCollapse={sidebarCollapse}
            >
              <SidebarCategory title="Role" sidebarCollapse={sidebarCollapse}>
                <SidebarLink
                  title="Role"
                  route="/auth/role"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Role Types"
                  route="/auth/role-type"
                  onClick={onClick}
                />
              </SidebarCategory>

              <SidebarLink
                title="Auth Media"
                route="/auth/media"
                onClick={onClick}
              />

              <SidebarCategory title="User" sidebarCollapse={sidebarCollapse}>
                <SidebarLink
                  title="User"
                  route="/auth/user"
                  onClick={onClick}
                />
                <SidebarLink
                  title="User Types"
                  route="/auth/user-type"
                  onClick={onClick}
                />
              </SidebarCategory>
            </SidebarCategory>
          </ul>
          <ul className="sidebar__block">
            <SidebarCategory
              title="Org"
              icon="list"
              sidebarCollapse={sidebarCollapse}
            >
              <SidebarCategory title="Entities" sidebarCollapse={sidebarCollapse}>
                <SidebarLink title="Entity" route="/org/entity" onClick={onClick} />
                <SidebarLink
                  title="Entity Type"
                  route="/org/entity-type"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Entity Relation"
                  route="/org/entity-relation"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Relation"
                  route="/org/relation"
                  onClick={onClick}
                />
                <SidebarLink
                  title="Relation Request"
                  route="/org/relation-request"
                  onClick={onClick}
                />
              </SidebarCategory>
            </SidebarCategory>
          </ul>
        </>
      )}
      <ul className="sidebar__block">
        <SidebarCategory
          title="CMS"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarCategory
            title="Application"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Applications"
              route="/cms/application"
              onClick={onClick}
            />
            <SidebarLink
              title="Application Types"
              route="/cms/application-type"
              onClick={onClick}
            />
          </SidebarCategory>

          <SidebarCategory title="Screen" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Screen"
              route="/cms/screen"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen Types"
              route="/cms/screen-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Screen Content"
              route="/cms/screen-content"
              onClick={onClick}
            />
            <SidebarLink
              title="Application Screen"
              route="/cms/application-screen"
              onClick={onClick}
            />
          </SidebarCategory>

          <SidebarLink
            title="Component"
            route="/cms/component"
            onClick={onClick}
          />

          <SidebarCategory title="Content" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Content"
              route="/cms/content"
              onClick={onClick}
            />
            <SidebarLink
              title="Content Type"
              route="/cms/content-type"
              onClick={onClick}
            />
            <SidebarLink
              title="Application Content"
              route="/cms/application-content"
              onClick={onClick}
            />
          </SidebarCategory>

          <SidebarCategory title="Media" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Media"
              route="/cms/media"
              onClick={onClick}
            />
            <SidebarLink
              title="Media Type"
              route="/cms/media-type"
              onClick={onClick}
            />
          </SidebarCategory>

          <SidebarCategory title="Menu" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Menu Type"
              route="/cms/menu-type"
              onClick={onClick}
            />
            <SidebarLink title="Menu" route="/cms/menu" onClick={onClick} />
            <SidebarLink
              title="Application Menu"
              route="/cms/application-menu" onClick={onClick}
            />
            <SidebarLink
              title="Screen Menu"
              route="/cms/screen-menu" onClick={onClick}
            />
          </SidebarCategory>

          <SidebarLink
            title="App Version"
            route="/cms/app-version"
            onClick={onClick}
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory
          title="Shopify"
          icon="list"
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink
            title="Shopify Vendors"
            route="/shopify/vendors"
            onClick={onClick}
          />
          <SidebarLink
            title="Shopify Products"
            route="/shopify/products"
            onClick={onClick}
          />
          <SidebarLink
            title="Supplemental Sales"
            route="/shopify/supplemental-sales"
            onClick={onClick}
          />
          <SidebarLink
            title="BackerKit Orders"
            route="/shopify/backerkit-orders"
            onClick={onClick}
          />

        </SidebarCategory>
      </ul>

    </div>
  ) : (
    displayComponent.component
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => { },
};

export default SidebarContent;
