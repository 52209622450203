import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import Tabs from "@/shared/components/Tabs";
import { APPLICATION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ApplicationForm from "./ApplicationForm";
import ApplicationScreenForm from "./ApplicationScreenForm";
import { getAllApplication } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";

const Application = () => {
  const [appDetails, setAppDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [currentApplicationId, setCurrentApplicationId] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [deleteApp, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    getApplicationRecords,
    {
      data: applicationList,
      isError: isApplicationError,
      isLoading: isApplicationLoading,
      isFetching: isApplicationFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Application Type",
      accessor: "ApplicationTypeDTO.Name",
      filter: true,
    },
  ];

  const handleShowDetails = (applicationData) => {
    history.push({
      pathname: "/cms/application-detail",
      state: {
        applicationId: applicationData.Id,
      },
    });
  };

  const handleEditData = (applicationData) => {
    setCurrentApplicationId(applicationData.Id);
    setSelectedRecord(applicationData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteApp({
      entity: "CMS/Application",
      id: selectedRecord.Id,
      tag: APPLICATION_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Application deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getApplicationRecords({
      entity: "CMS/Applications",
      entities: { ApplicationType: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: APPLICATION_TAG,
    });
    setAppDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isApplicationLoading &&
      !isApplicationError &&
      applicationList &&
      !isApplicationFetching
    ) {
      dispatch(getAllApplication(applicationList.value));
      let appData = [...applicationList.value];
      setAppDetails({
        ...appDetails,
        tableRowsData: applicationList.value ? appData : [],
        totalData:
          applicationList && applicationList["@odata.count"]
            ? applicationList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => handleShowDetails(data),
      });
    }
    // eslint-disable-next-line
  }, [
    isApplicationError,
    applicationList,
    isApplicationLoading,
    isApplicationFetching,
  ]);

  const toggle = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setActiveTab("1");
    setCurrentApplicationId(0);
    setSelectedRecord();
  };

  const tabs = {
    Application: (
      <ApplicationForm
        editData={selectedRecord ? selectedRecord : {}}
        setCurrentApplicationId={setCurrentApplicationId}
        setActiveTab={setActiveTab}
        toggle={toggle}
      />
    ),
    "Application Screen": (
      <ApplicationScreenForm parentData={selectedRecord ? selectedRecord : {}} toggle={toggle} />
    ),
  };

  return (
    <Container data-testid="test-app">
      <Row>
        {appDetails && (
          <DataReactTable
            reactTableData={appDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Application"
            isFetching={isApplicationFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          testid="addModal"
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Application`}
          header
          btn="Success"
          message={
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Application;
