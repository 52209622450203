import React from "react";
import { Col, Row } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { FieldArray, getIn } from "formik";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import { Divider, Link } from "@material-ui/core";
import CustomInput from "@/shared/components/form/CustomInput";

const InsertList = ({ formik, styles, getFieldValue, onFieldChange }) => {
  const { errors, touched, values, handleBlur, setFieldValue } = formik;

  return (
    <Col sm="12" md="12" lg="12">
      {values?.ContentObject?.images && values?.ContentObject?.lists && (
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      )}
      <Row>
        {values.ContentObject?.lists && values.ContentObject?.lists?.length && (
          <Col>
            <FieldArray name="ContentObject.lists">
              {({ push, remove, replace }) => (
                <>
                  {values.ContentObject?.lists?.length &&
                    values.ContentObject.lists.map((list, index) => {
                      const allList = values["ContentObject"]["lists"][index];
                      const touchedName = getIn(touched, allList);
                      return (
                        <div key={`list_container${index}`}>
                          <Row
                            className="align-items-center"
                            key={`list${index}`}
                          >
                            {_.has(list, "name") && (
                              <Col className="mb-2">
                                <h5
                                  style={{
                                    color: "#4ce1b6",
                                  }}
                                >
                                  List {index + 1}
                                </h5>
                              </Col>
                            )}
                            <Col
                              xs={!index ? 12 : 11}
                              sm={!index ? 12 : 11}
                              md={!index ? 12 : 11}
                            >
                              <CreatableSelect
                                name={allList}
                                isMulti
                                placeholder="List fields"
                                value={getFieldValue("lists", allList, index)}
                                onChange={(event) =>
                                  onFieldChange(event, replace, index, list)
                                }
                                styles={styles}
                                className="my-2"
                                isClearable={false}
                                backspaceRemovesValue={false}
                                fieldErrors={errors.InsertList}
                                fieldTouched={touchedName}
                              />
                            </Col>
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              className="p-0"
                              style={{
                                display: !index ? "none" : "block",
                              }}
                            >
                              <MinusCircleIcon
                                onClick={() => {
                                  if (
                                    values.ContentObject?.lists &&
                                    values.ContentObject?.lists?.length === 1
                                  ) {
                                    remove(index);
                                    push({
                                      name: "",
                                    });
                                  } else {
                                    remove(index);
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            {Object.keys(list).map((data, listIndex) => {
                              return (
                                <React.Fragment
                                  key={`List${index}${listIndex}`}
                                >
                                  <Col md={12} key={listIndex}>
                                    <CustomInput
                                      label={data}
                                      name={data}
                                      fieldValue={
                                        values.ContentObject.lists[index][data]
                                      }
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        const contentObjectData =
                                          values.ContentObject &&
                                          values.ContentObject;
                                        contentObjectData.lists[index][data] =
                                          e.target.value;
                                        setFieldValue(
                                          "ContentObject",
                                          contentObjectData
                                        );
                                      }}
                                    />
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                          </Row>
                        </div>
                      );
                    })}

                  {values.ContentObject.lists.length && (
                    <div className="d-flex justify-content-end">
                      <Link
                        onClick={() => push({ name: "" })}
                        className="mt-3 cursor-pointer"
                        style={{ color: "#4ce1b6" }}
                        size="sm"
                      >
                        + Add List
                      </Link>
                    </div>
                  )}
                </>
              )}
            </FieldArray>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default InsertList;
