import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import Summary from "@/shared/components/entity_detail/Summary";
import AddModal from "@/shared/components/AddModal";
import { useLazyGetRecordsQuery } from "@/services/pcApi";
import { MENU_TAG } from "@/shared/tagFile";
import MenuForm from "../Menu/MenuForm";
import Collapse from "@/shared/components/Collapse";
import Crosslinks from "./Crosslinks";
import "react-toastify/dist/ReactToastify.css";

const MenuDetail = (props) => {
  const menuId = JSON.parse(props.location.state.menuId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [menuDetail, setMenuDetail] = useState();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const [
    getMenuRecords,
    {
      data: menuList,
      isError: isMenuError,
      isLoading: isMenuLoading,
      isFetching: isMenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getMenuRecords({
      entity: "CMS/Menus",
      filter: `id eq ${menuId}`,
      entities: { MenuType: {} },
      tag: MENU_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isMenuLoading && !isMenuError && menuList && !isMenuFetching) {
      setMenuDetail(menuList.value[0]);
    }
    // eslint-disable-next-line
  }, [isMenuError, menuList, isMenuLoading, isMenuFetching]);

  return (
    <Container>
      {menuDetail && Object.entries(menuDetail)?.length ? (
        <Row>
          <Summary
            displayData={menuDetail}
            title="Menu"
            setIsAddModalOpen={setIsAddModalOpen}
            restContent={
              menuDetail ? (
                <>
                  <hr />
                  <div>
                    <Collapse
                      className="with-shadow"
                      title="About this menu"
                      collapse={isCollapseOpen}
                      onClick={() => {
                        setIsCollapseOpen(!isCollapseOpen);
                      }}
                    >
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Help Text:</th>
                            <td>
                              {menuDetail.HelpText ? menuDetail.HelpText : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Menu Type:</th>
                            <td>
                              {menuDetail.MenuTypeDTO && menuDetail.MenuTypeDTO.Name
                                ? menuDetail.MenuTypeDTO.Name
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                </>
              ) : (
                <div></div>
              )
            }
          ></Summary>
          <Activity></Activity>
          <Crosslinks displayData={menuDetail}></Crosslinks>
          {isAddModalOpen && (
            <AddModal
              toggle={() => setIsAddModalOpen(!isAddModalOpen)}
              color="success"
              title="Edit Menu"
              header
              xxl
              btn="Success"
              message={
                <MenuForm
                  editData={menuDetail}
                  setIsAddModalOpen={setIsAddModalOpen}
                />
              }
            />
          )}
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

export default MenuDetail;
