import React, { useState } from "react";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { FieldArray, getIn } from "formik";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import { Divider, Link } from "@material-ui/core";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { successToast } from "@/shared/components/toast";

const InsertImage = ({
  formik,
  styles,
  getFieldValue,
  onFieldChange,
  addMedia,
  isMediaAddLoading,
}) => {
  const { errors, touched, values, handleBlur, setFieldValue } = formik;

  const [currImageUploadButton, setCurrImageUploadButton] = useState("");

  const defaultImageField = [
    { value: "name", label: "Image Name" },
    { value: "url", label: "URL" },
  ];

  const uploadImage = (setFieldValue, index, data, values) => {
    setCurrImageUploadButton(`File${index}`);
    var formData = new FormData();
    if (values[`File${index}`] !== undefined) {
      formData.append("MediaTypeId", 1);
      formData.append("PathFolderName", "content");
      formData.append("File", values[`File${index}`]);
    }

    addMedia({
      entity: `CMS/CMSMedia`,
      data: formData,
    }).then((response) => {
      if (!response.error) {
        const contentObjectData = values.ContentObject && values.ContentObject;
        contentObjectData.images[index][data] = response.data.url;
        setFieldValue("ContentObject", contentObjectData);
        successToast("Media uploaded successfully.");
        setFieldValue(`File${index}`, "");
      }
    });
  };


  return (
    <Col sm="12" md="12" lg="12">
      {values.ContentObject?.images && values.ContentObject?.images?.length && (
        <FieldArray name="ContentObject.images">
          {({ push, remove, replace }) => (
            <>
              {values.ContentObject.images.length &&
                values.ContentObject.images.map((image, index) => {
                  const imageName = values["ContentObject"]["images"][index];
                  const touchedName = getIn(touched, imageName);
                  return (
                    <div key={`image_card_container${index}`}>
                      <Label for="ContentObject" style={{ color: "#4ce1b6" }}>
                        Image {index + 1}
                      </Label>
                      <Row
                        className="align-items-center"
                        key={`image_card${index}`}
                      >
                        <Col
                          xs={!index ? 12 : 11}
                          sm={!index ? 12 : 11}
                          md={!index ? 12 : 11}
                        >
                          <CreatableSelect
                            name={imageName}
                            isMulti
                            placeholder="Image fields"
                            value={getFieldValue("images", imageName, index)}
                            onChange={(event) =>
                              onFieldChange(event, replace, index, image)
                            }
                            options={defaultImageField}
                            styles={styles}
                            className="my-2"
                            isClearable={false}
                            backspaceRemovesValue={false}
                            fieldErrors={errors.InsertImage}
                            fieldTouched={touchedName}
                          />
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          className="p-0"
                          style={{ display: !index ? "none" : "block" }}
                        >
                          <MinusCircleIcon
                            onClick={() => {
                              if (
                                values.ContentObject?.images &&
                                values.ContentObject?.images?.length === 1
                              ) {
                                remove(index);
                                push({
                                  url: "",
                                  name: "",
                                });
                              } else {
                                remove(index);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {Object.keys(image).map((data, imgIndex) => {
                          if (data === "url") {
                            return (
                              <React.Fragment key={`Image${index}${imgIndex}`}>
                                <Col md={12} key={imgIndex}>
                                  <Row>
                                    <Col>
                                      <img
                                        src={
                                          values.ContentObject.images &&
                                          values.ContentObject.images[index][
                                          data
                                          ]
                                        }
                                        alt=""
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <AsyncPaginates
                                        label="Choose from Existing"
                                        value={
                                          values.ContentObject.images[index]
                                            .name.length
                                            ? values.ContentObject.images[index]
                                            : values.ContentObject.images[index]
                                              .name
                                        }
                                        name="File"
                                        entity="CMS/CMSMedias?"
                                        allValues={values}
                                        index={index}
                                        setFieldValue={setFieldValue}
                                        fieldErrors={errors.File}
                                        fieldTouched={touched.File}
                                        isAllowToAddContent={true}
                                        handleBlur={handleBlur}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="align-items-center">
                                    <Col xs={5} sm={5} md={5} lg={5}>
                                      <Divider
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      />
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2}>
                                      <h4>OR</h4>
                                    </Col>
                                    <Col xs={5} sm={5} md={5} lg={5}>
                                      <Divider
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <CustomInput
                                        label="File"
                                        name="File"
                                        type="file"
                                        handleChange={(e) => {
                                          setFieldValue(
                                            `File${index}`,
                                            e.target.files[0]
                                          );
                                        }}
                                        fieldErrors={errors.File}
                                        fieldTouched={touched.File}
                                        handleBlur={handleBlur}
                                      />
                                    </Col>
                                    <Col
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        disabled={
                                          !(
                                            values[`File${index}`] !== undefined
                                          ) ||
                                          values[`File${index}`] === "" ||
                                          isMediaAddLoading
                                        }
                                        color="success"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          uploadImage(
                                            setFieldValue,
                                            index,
                                            data,
                                            values
                                          );
                                        }}
                                      >
                                        {!(
                                          values[`File${index}`] !== undefined
                                        ) ||
                                          values[`File${index}`] === "" ||
                                          (isMediaAddLoading &&
                                            currImageUploadButton ===
                                            `File${index}` && (
                                              <Spinner
                                                size="sm"
                                                variant="light"
                                              />
                                            ))}{" "}
                                        Upload
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <Col md={12} key={imgIndex}>
                                <CustomInput
                                  label={data}
                                  name={data}
                                  fieldValue={
                                    values.ContentObject.images[index][data]
                                  }
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    const contentObjectData =
                                      values.ContentObject &&
                                      values.ContentObject;
                                    contentObjectData.images[index][data] =
                                      e.target.value;
                                    setFieldValue(
                                      "ContentObject",
                                      contentObjectData
                                    );
                                  }}
                                />
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  );
                })}
              {values.ContentObject.images.length && (
                <div className="d-flex justify-content-end">
                  <Link
                    onClick={() => push({ url: "", name: "" })}
                    className="mt-3 cursor-pointer"
                    style={{ color: "#4ce1b6" }}
                    size="sm"
                  >
                    + Add Image
                  </Link>
                </div>
              )}
            </>
          )}
        </FieldArray>
      )}
    </Col>
  );
};

export default InsertImage;
