import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { SUPER_ADMIN, USER, ADMIN } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import Layout from "../../../Layout/index";
import AdminDashboard from "../../../Dashboards/AdminDashboard/index";
import DataTable from "../../../Tables/DataTable";
import AuthRoutes from "../AuthRoutes";
import Account from "./Account";
import CMSRoutes from "../CMSRoutes";
import OrgRoutes from "../OrgRoutes";
import ShopifyRoutes from "../ShopifyRoutes";

const WrappedRoutes = () => {
  const role = useSelector((state) => state.user.role);
  if (
    role &&
    role.length &&
    !(role.includes(ADMIN) || role.includes(SUPER_ADMIN)) &&
    role.includes(USER)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Layout />
      {role ? (
        <div className="container__wrap">
          <Route path="/admin_dashboard" component={AdminDashboard} />
          <Route path="/tables/api_table" component={DataTable} />
          <Route path="/account" component={Account} />
          <Route path="/cms" component={CMSRoutes} />
          <Route path="/shopify" component={ShopifyRoutes} />
          {role.includes(SUPER_ADMIN) && (
            <>
              <Route path="/auth" component={AuthRoutes} />
              <Route path="/org" component={OrgRoutes} />
            </>
          )}
        </div>
      ) : (
        <Loading loading={true} />
      )}
    </div>
  );
};

export default WrappedRoutes;
