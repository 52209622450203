import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddMutation } from "@/services/pcApi";
import { successToast } from "@/shared/components/toast";
import CustomInput from "../../../shared/components/form/CustomInput";

const BackerKitOrdersForm = ({ setIsAddModalOpen }) => {
  const [addBackerKitOrdersFromCSV, { isLoading: isShopifyImportPDfAddLoading }] = useAddMutation();

  const shopifyImportPDfValidationSchema = Yup.object().shape({
    File: Yup.mixed().required("Please select CSV file."),
  });

  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("file", values.File)
    addBackerKitOrdersFromCSV({
      entity: "BackerKit_Fulfill/ImportOrderFromBackerKitCSVFile",
      data: formData,
    }).then((response) => {
      if (!response.error) {
        !isShopifyImportPDfAddLoading && setIsAddModalOpen(false);
        successToast("Orders imported successfully.");
      }
    });
  };

  return (
    <Formik
      initialValues={{
        File: "",
      }}
      validationSchema={shopifyImportPDfValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <CustomInput
              label="File"
              name="File"
              type="file"
              handleChange={(e) => {
                setFieldValue("File", e.target.files[0]);
              }}
              fieldErrors={errors.File}
              fieldTouched={touched.File}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isShopifyImportPDfAddLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {isShopifyImportPDfAddLoading && (<Spinner size="sm" variant="light" />)}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik >
  );
};

BackerKitOrdersForm.propTypes = {
  parentData: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
};

export default BackerKitOrdersForm;
