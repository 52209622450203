import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useUpdateMutation } from "@/services/pcApi";
import { RELATION_REQUEST_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const RelationRequestForm = ({ editData, setIsEditModalOpen }) => {
  const [selectedRecord, setSelectedRecord] = useState(editData || {})
  const [updateRelationRequest, { isLoading: isRelationRequestUpdateLoading }] = useUpdateMutation();

  const relationRequestValidationSchema = Yup.object().shape({
    Vendors: Yup.mixed().required("Please select at least one vendor."),
  });

  const onSubmit = (values) => {
    const relationDetail = {
      Id: selectedRecord?.Id,
      UserId: selectedRecord?.UserId,
      RelationId: selectedRecord?.RelationDTO.Id,
      StatusId: selectedRecord?.StatusDTO.Id,
      RejectionReason: selectedRecord?.RejectionReason,
      IsAdminRequested: selectedRecord?.IsAdminRequested,
      Email: selectedRecord?.Email,
      VendorObject: values?.Vendors?.length ? JSON.stringify(values?.Vendors) : ""
    };

    updateRelationRequest({
      entity: "Org/UserRelationRequest",
      data: relationDetail,
      tag: RELATION_REQUEST_TAG,
    }).then((response) => {
      if (!response.error) {
        !isRelationRequestUpdateLoading && setIsEditModalOpen(false);
        successToast("Vendor list updated successfully.");
      }
    });
  };

  return (
    <Formik
      initialValues={{
        UserName: selectedRecord && selectedRecord?.UserDTO ? selectedRecord?.UserDTO.Name : "",
        Email: selectedRecord && selectedRecord?.Email ? selectedRecord?.Email : "",
        Relation:
          selectedRecord && selectedRecord?.RelationDTO
            ? selectedRecord?.RelationDTO
            : "",
        Vendors:
          selectedRecord && selectedRecord?.VendorObject
            ? JSON.parse(selectedRecord?.VendorObject || [])
            : "",
      }}
      validationSchema={relationRequestValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(selectedRecord ?? {}).length > 0 && <CustomInput label="Id" fieldValue={selectedRecord?.Id} readonly />}
            <CustomInput
              label="User Name"
              name="UserName"
              fieldErrors={errors.UserName}
              fieldTouched={touched.UserName}
              fieldValue={values.UserName}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
            />
            <CustomInput
              label="Email"
              name="Email"
              fieldErrors={errors.Email}
              fieldTouched={touched.Email}
              fieldValue={values.Email}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
            />
            <AsyncPaginates
              value={values.Relation}
              label="Relation"
              name="Relation"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Relation}
              fieldTouched={touched.Relation}
              handleBlur={handleBlur}
              readonly
            />
            {selectedRecord?.RelationDTO?.Name?.toLowerCase() === "client" && <AsyncPaginates
              value={values.Vendors}
              label="Vendors"
              name="Vendors"
              entity="ShopifyReference/ShopifyVendors"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Vendors}
              fieldTouched={touched.Vendors}
              handleBlur={handleBlur}
              isMulti={true}
              required
            />}
            <Button
              disabled={isRelationRequestUpdateLoading || selectedRecord?.RelationDTO?.Name?.toLowerCase() === "employee"}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isRelationRequestUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RelationRequestForm.propTypes = {
  editData: PropTypes.object,
  setIsEditModalOpen: PropTypes.func,
  parentData: PropTypes.object,
};

export default RelationRequestForm;
