import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { USER_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const UserTypeForm = ({ editData, setIsOpenAddModal }) => {
  const [addUserType, { isLoading: isUserTypeAddLoading }] = useAddMutation();
  const [updateUserType, { isLoading: isUserTypeUpdateLoading }] =
    useUpdateMutation();

  const userTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    const userTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };
    if (values.SortOrder) userTypeDetail.SortOrder = parseInt(values.SortOrder);

    if (editData && Object.entries(editData).length > 0) {
      userTypeDetail.Id = editData.Id;
      updateUserType({
        entity: "AuthReference/UserType",
        data: userTypeDetail,
        tag: USER_TYPE_TAG,
      }).then((respoanse) => {
        if (!respoanse.error) {
          !isUserTypeUpdateLoading &&
            setIsOpenAddModal(false) &&
            successToast("User type updated successfully.");
        }
      });
    } else {
      addUserType({
        entity: "AuthReference/UserType",
        data: userTypeDetail,
        tag: USER_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isUserTypeAddLoading && setIsOpenAddModal(false);
          successToast("User type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={userTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isUserTypeAddLoading || isUserTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

UserTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsOpenAddModal: PropTypes.func.isRequired,
};

export default UserTypeForm;
