import { createSlice } from "@reduxjs/toolkit";

const CMSSlice = createSlice({
  name: "cms",
  initialState: {
    applications: [],
    applicationTypes: [],
    applicationScreens: [],
    applicationMenus: [],
    components: [],
    screens: [],
    screenTypes: [],
    screenContents: [],
    screenMenus: [],
    contents: [],
    contentTypes: [],
    menus: [],
    menuTypes: [],
    medias: [],
    mediaTypes: [],
    appVersions: [],
  },
  reducers: {
    getAllApplication: (state, action) => {
      state.applications = action.payload;
    },
    getAllApplicationTypes: (state, action) => {
      state.applicationTypes = action.payload;
    },
    getAllApplicationScreen: (state, action) => {
      state.applicationScreens = action.payload;
    },
    getAllApplicationMenus: (state, action) => {
      state.applicationMenus = action.payload
    },
    getAllComponents: (state, action) => {
      state.components = action.payload;
    },
    getAllScreen: (state, action) => {
      state.screens = action.payload;
    },
    getAllScreenTypes: (state, action) => {
      state.screenTypes = action.payload;
    },
    getAllScreenContents: (state, action) => {
      state.screenContents = action.payload;
    },
    getAllScreenMenus: (state, action) => {
      state.screenMenus = action.payload
    },
    getAllContents: (state, action) => {
      state.contents = action.payload
    },
    getAllContentTypes: (state, action) => {
      state.contentTypes = action.payload
    },
    getAllMedia: (state, action) => {
      state.medias = action.payload
    },
    getAllMediaTypes: (state, action) => {
      state.mediaTypes = action.payload
    },
    getAllMenu: (state, action) => {
      state.menus = action.payload
    },
    getAllMenuType: (state, action) => {
      state.menuTypes = action.payload
    },
    getAllAppVersion: (state, action) => {
      state.appVersions = action.payload
    },
  },
});

export default CMSSlice.reducer;
export const {
  getAllApplication,
  getAllApplicationTypes,
  getAllApplicationScreen,
  getAllComponents,
  getAllScreen,
  getAllScreenTypes,
  getAllScreenContents,
  getAllContents,
  getAllContentTypes,
  getAllScreenMenus,
  getAllApplicationMenus,
  getAllMedia,
  getAllMediaTypes,
  getAllMenu,
  getAllMenuType,
  getAllAppVersion,
} = CMSSlice.actions;
