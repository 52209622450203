import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import FirebaseIcon from "mdi-react/FirebaseIcon";
import withAuthFirebase from "@/shared/components/auth/withAuthFirebase";
import LogInForm from "@/shared/components/loginForm/LogInForm";
import showResults from "@/utils/showResults";
import GoogleAuthBtn from "../AuthBtn/googleAuthBtn";
import FacebookAuthBtn from "../AuthBtn/fbAuthBtn";
import MicrosoftAuthBtn from "../AuthBtn/microsoftAuthBtn";

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = ({ changeIsOpenModalFireBase }) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome to
            <span className="account__logo">
              {" "}
              PC
              <span className="account__logo-accent">Admin</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">
            Start your business easily
          </h4>
        </div>
        <LogInForm onSubmit={showResults} form="log_in_form" />
        <div className="account__or">
          <p>Or Easily Using</p>
        </div>
        <div className="account__social">
          <FacebookAuthBtn />
          <GoogleAuthBtn />
          <MicrosoftAuthBtn />
          <Button
            className="account__social-btn account__social-btn--firebase"
            onClick={changeIsOpenModalFireBase}
          >
            <FirebaseIcon />
          </Button>
          <Button
            className="account__social-btn account__social-btn--auth0"
            onClick={() => { }}
          >
            <img className="customizer__btn-icon" src={auth0Icon} alt="icon" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
