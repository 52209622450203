import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { FormGroup, Label } from "reactstrap";

const AsyncPaginates = ({
  value = {},
  label,
  name,
  fieldType = "",
  fieldName = "",
  type = "",
  entity = "",
  options,
  allValues,
  index,
  setFieldValue,
  fieldErrors,
  fieldTouched,
  handleBlur,
  readonly = false,
  required = false,
  expandRelation = [],
  isAllowToAddContent = false,
  handleEditRecords = false,
  isMulti = false,
  cacheUniqs = [],
  handleChange,
  isSearchable = true,
}) => {
  const loadOptions = async (e, _, { page }) => {
    if (options) {
      let finalOptions = options;
      if (e) {
        finalOptions = options.filter((value) => value.Name.includes(e));
      }
      return {
        options:
          finalOptions && finalOptions.length
            ? finalOptions.slice((page - 1) * 5, page * 5)
            : finalOptions,
        hasMore: page * 5 < finalOptions.length,
        additional: {
          page: page + 1,
        },
      };
    } else {
      const baseUrl = process.env.REACT_APP_API_URL;
      const urlString = `${baseUrl}${entity}?$skip=${(page - 1) * 5
        }&$top=5&$count=true&$orderby=Id desc&$filter=IsDeleted ne true`;
      let updatedUrlString = "";
      if (value && e) updatedUrlString = urlString;
      if (e || !value)
        updatedUrlString =
          urlString + ` and (contains(tolower(Name),'${e.toLowerCase().replaceAll("'", "''")}'))`;
      if (!e) updatedUrlString = urlString;
      let accessToken = localStorage.getItem("accessToken");
      const response = await fetch(updatedUrlString, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const responseJSON = await response.json();

      return {
        options: responseJSON.value,
        hasMore: page * 5 < responseJSON["@odata.count"],
        additional: {
          page: page + 1,
        },
      };
    }
  };
  const handleExistingRecords = (e, setFieldValue) => {
    let secondObject = JSON.parse(e.ContentObject)
    let firstObject = JSON.parse(handleEditRecords.ContentObject)
    for (let k in firstObject) {
      if (k === 'lists' || k === 'images' || k === 'videos') {
        let existingLists = []
        firstObject[k] && firstObject[k].forEach((val, pIndex) => {
          if (firstObject[k].some((_, cIndex) => pIndex === cIndex)) {
            existingLists.push(firstObject[k][pIndex])
          } else {
            existingLists.push(val)
          }
          secondObject[k] = existingLists;
        })
      }
      else {
        secondObject[k] = firstObject[k];
      }
    }
    setFieldValue(
      "ContentObject",
      JSON.parse(secondObject ? JSON.stringify(secondObject) : "{}")
    );
  }

  const setAllFieldValues = (e) => {
    if (name === "File") {
      const contentObjectData = allValues.ContentObject && allValues.ContentObject;
      if (type === "video") {
        contentObjectData.videos[index].url = e.URL;
        contentObjectData.videos[index].name = e.Name;
      } else if (type === "image") {
        contentObjectData.images[index].url = e.URL;
        contentObjectData.images[index].name = e.Name;
      } else if (type === "singleFile") {
        if (fieldType === "image") {
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        } else if (fieldType === "video") {
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        } else {
          contentObjectData[fieldName].url = e.URL;
          contentObjectData[fieldName].name = e.Name;
        }
      }
      setFieldValue("ContentObject", contentObjectData);
    } else {
      setFieldValue(name, e);
      if (isAllowToAddContent) {
        if (Object.entries(handleEditRecords).length && value.ContentObject !== e.ContentObject) {
          handleExistingRecords(e, setFieldValue)
        } else {
          let contentObjectData = JSON.parse(e.ContentObject);
          Object.entries(contentObjectData).forEach((content) => {
            if (content[0] !== "images" && content[0] !== "lists" && content[0] !== "videos") {
              if (content[1]?.type !== "image" && content[1]?.type !== "video" && content[1]?.type !== "audio") {
                contentObjectData[content[0]] = "";
              } else {
                contentObjectData[content[0]] = contentObjectData[content[0]].value;
              }
            }
          });
          contentObjectData = JSON.stringify(contentObjectData);
          setFieldValue(
            "ContentObject",
            JSON.parse(e.ContentObject ? contentObjectData : "{}")
          );
        }
      }
    }
  }

  return (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      <AsyncPaginate
        className={`lazySelect ${readonly && "disabled"}`}
        value={value}
        cacheUniqs={cacheUniqs}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.Id}
        getOptionLabel={(option) => {
          if (option.Id) {
            if (expandRelation.length && option && option[expandRelation[0]]) {
              let optionString = `${option.Id}`;
              optionString +=
                "-" +
                expandRelation
                  .map((value) => {
                    return `${option[value].Name}`;
                  })
                  .join("-");
              return optionString;
            } else if (entity === "User") {
              return `${option.Id} - ${option.Name} - ${option.Email}`;
            } else if (entity === "Tile") {
              return `${option.Id} - ${option.UrlFileName}`;
            } else if (entity === "GetAllCell") {
              return `${option.Id} - (X : ${option.X}) (Y : ${option.Y}) (Z : ${option.Z})`;
            } else if (name === "File") {
              return <><img src={option.URL} style={{ width: "50px" }} alt="" /> {option.Name ?? option.name}</>;
            } else {
              // return `${option.Id} + ${option?.Title}`;
              return `${option.Id} - ${option?.Title || option?.Name || option?.name}`;
            }
          } else {
            if (option.Name) return `${option.Name}`
            else return `New ${name}`
          }
        }}
        placeholder={`Select ${label}`}
        isSearchable={isSearchable}
        onBlur={handleBlur}
        onChange={handleChange ? handleChange : setAllFieldValues}
        isDisabled={readonly}
        isMulti={isMulti}
        additional={{
          page: 1,
        }}
      />
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  );
};

export default AsyncPaginates;
