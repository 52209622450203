import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import Modal from "@/shared/components/Modal";
import { successToast } from "@/shared/components/toast";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import { APPLICATION_SCREEN_TAG } from "@/shared/tagFile";

import AddModal from "@/shared/components/AddModal";
import Tabs from "@/shared/components/Tabs";
import ApplicationScreenForm from "../../Application/Applications/ApplicationScreenForm";

const Crosslinks = ({ displayData }) => {
  const { t } = useTranslation("common");
  const [deleteApplicationScreen, { isLoading: isDeleting }] =
    useDeleteMutation();
  const [applicationScreenDetails, setApplicationScreenDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState({
    applicationScreen: false,
  });
  const history = useHistory();

  const [
    getApplicationScreenRecords,
    {
      data: applicationScreenData,
      isError: isApplicationScreenError,
      isFetching: isApplicationScreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getApplicationScreenRecords({
      entity: "CMS/ApplicationScreens",
      top: 3,
      filter: `ApplicationId eq ${displayData.Id}`,
      entities: { Application: {}, Screen: { entities: { ScreenType: {} } } },
      tag: APPLICATION_SCREEN_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !isApplicationScreenFetching &&
      !isApplicationScreenError &&
      applicationScreenData
    ) {
      setApplicationScreenDetails(applicationScreenData.value);
    }
  }, [
    applicationScreenData,
    isApplicationScreenError,
    isApplicationScreenFetching,
  ]);

  const onAddClick = (title) => {
    let formName = `${title.charAt(0).toLowerCase()}${title
      .slice(1)
      .replace(/\s|\(|[0-9]|\)/g, "")}`;
    setIsAddModalOpen({ ...isAddModalOpen, [formName]: true });
  };

  const handleDelete = () => {
    deleteApplicationScreen({
      entity: deleteRecord.entity,
      id: deleteRecord.Id,
      tag: deleteRecord.tag,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(`${deleteRecord.entity} deleted successfully.`);
      }
    });
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("gamecodex.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <CrosslinkPanel
                title={`Application Screen (${applicationScreenData && applicationScreenData["@odata.count"]
                  ? applicationScreenData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  applicationScreenDetails &&
                    applicationScreenDetails.length > 0 ? (
                    <div>
                      {applicationScreenDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMSReference/ApplicationScreen`,
                                    tag: APPLICATION_SCREEN_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Name: </b>
                                  {value.Screen?.Name}
                                </div>
                                <div>
                                  <b>Discription: </b>
                                  {value.Screen?.Description}
                                </div>
                                <div>
                                  <b>Screen Type: </b>
                                  {value.Screen?.ScreenType?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          className="disabledCursor"
                          onClick={(event) => {
                            history.push("/cms/application-screen");
                          }}
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="success"
              ></CrosslinkPanel>
            </div>
          </CardBody>
        </Card>
      </Col>
      {isAddModalOpen.applicationScreen && (
        <AddModal
          toggle={() =>
            setIsAddModalOpen({ ...isAddModalOpen, applicationScreen: false })
          }
          color="success"
          title={`Add Application Screen`}
          header
          btn="Success"
          xl
          message={
            <ApplicationScreenForm
              parentData={displayData}
              setIsAddModalOpen={setIsAddModalOpen}
              isAddModalOpen={isAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default Crosslinks;
