import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import Summary from "@/shared/components/entity_detail/Summary";
import AddModal from "@/shared/components/AddModal";
import { useLazyGetRecordsQuery } from "@/services/pcApi";
import { APPLICATION_TAG } from "@/shared/tagFile";
import "react-toastify/dist/ReactToastify.css";

const ShopifyProductDetails = (props) => {
  const applicationId = JSON.parse(props.location.state?.applicationId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState();

  const [
    getApplicationRecords,
    {
      data: applicationList,
      isError: isApplicationError,
      isLoading: isApplicationLoading,
      isFetching: isApplicationFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (applicationId) {
      getApplicationRecords({
        entity: "CMS/Applications",
        filter: `Id eq ${applicationId}`,
        entities: { ApplicationType: {} },
        tag: APPLICATION_TAG,
      });
    }
    // eslint-disable-next-line
  }, [applicationId]);

  useEffect(() => {
    if (
      !isApplicationLoading &&
      !isApplicationError &&
      applicationList &&
      !isApplicationFetching
    ) {
      setApplicationDetail(applicationList.value[0]);
    }
    // eslint-disable-next-line
  }, [
    isApplicationError,
    applicationList,
    isApplicationLoading,
    isApplicationFetching,
  ]);

  return (
    <Container>
      {applicationDetail && applicationDetail.Name ? (
        <Row>
          <Summary
            displayData={applicationDetail}
            title="Application"
            setIsAddModalOpen={setIsAddModalOpen}
          ></Summary>
          <Activity></Activity>
          <Crosslinks displayData={applicationDetail}></Crosslinks>
          {isAddModalOpen && (
            <AddModal
              toggle={() => setIsAddModalOpen(!isAddModalOpen)}
              color="success"
              title="Edit Application"
              header
              btn="Success"
              message={<></>}
            />
          )}
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

export default ShopifyProductDetails;
