import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { APPLICATION_TAG, APPLICATION_SCREEN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ApplicationForm = ({
  editData,
  setActiveTab,
  parentId,
  setIsAddModalOpen,
  isAddModalOpen,
  setCurrentApplicationId,
}) => {
  const [addApplication, { isLoading: isApplicationAddLoading }] =
    useAddMutation();
  const [addApplicationScreen, { isLoading: isApplicationScreenAddLoading }] =
    useAddMutation();
  const [updateApplication, { isLoading: isApplicationUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );

  const applicationValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ApplicationType: Yup.mixed().required("Please select application-type."),
  });

  const onSubmit = (values) => {
    const appDetail = {
      Name: values.Name,
      Description: values.Description,
      ApplicationTypeId: values.ApplicationType.Id,
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      appDetail.Id = editRecordDetail.Id;
      updateApplication({
        entity: "CMS/Application",
        data: appDetail,
        tag: APPLICATION_TAG,
      }).then((response) => {
        if (!response.error) {
          if (setActiveTab) {
            setActiveTab("2");
            successToast("Application updated successfully.");
          } else {
            setIsAddModalOpen(false);
            successToast("Application updated successfully.");
          }
        }
      });
    } else {
      addApplication({
        entity: "CMS/Application",
        data: appDetail,
        tag: APPLICATION_TAG,
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail(response.data);
          if (setActiveTab) {
            setCurrentApplicationId(response.data.Id);
            setActiveTab("2");
            successToast("Application added successfully.");
          } else {
            const applicationScreenDetail = {
              ScreenId: parentId,
              ApplicationId: response.data.Id,
            };
            addApplicationScreen({
              entity: "CMSReference/ApplicationScreen",
              data: applicationScreenDetail,
              tag: APPLICATION_SCREEN_TAG,
            }).then((response) => {
              if (!response.error) {
                successToast("Application added successfully.");
                setIsAddModalOpen({ ...isAddModalOpen, appScreen: false });
              }
            });
          }
        }
      });
    }
  };
  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        ApplicationType:
          editRecordDetail && editRecordDetail.ApplicationTypeDTO
            ? editRecordDetail.ApplicationTypeDTO
            : "",
      }}
      onSubmit={onSubmit}
      validationSchema={applicationValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.ApplicationType}
              label="Application Type"
              name="ApplicationType"
              entity="CMSReference/ApplicationTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.ApplicationType}
              fieldTouched={touched.ApplicationType}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isApplicationAddLoading || isApplicationUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isApplicationAddLoading ||
                isApplicationUpdateLoading ||
                isApplicationScreenAddLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ApplicationForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
  setCurrentApplicationId: PropTypes.func,
};

export default ApplicationForm;
