import { TextareaAutosize } from "@mui/base";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const CustomInput = ({
  label,
  placeholder,
  name,
  type,
  fieldErrors,
  fieldTouched,
  fieldValue,
  handleBlur,
  isDisabled,
  handleChange,
  readonly = false,
  required = false,
  isChecked = false,
  min,
}) => {
  switch (type) {
    case "checkbox":
      return (
        <FormGroup>
          <Input
            className="ml-0 mt-1"
            name={name}
            id={name}
            type={type}
            disabled={isDisabled}
            placeholder={placeholder || name}
            required={required}
            value={fieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
            readOnly={readonly}
            checked={isChecked}
          />
          <Label for={name} className="ml-4">
            {label}
            {required && <font color="red"> *</font>}
          </Label>
          {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
        </FormGroup>
      )
    case "codeblock":
      return (
        <>
          <Label>
            {name}
            {required && <font color="red"> *</font>}
          </Label>
          <TextareaAutosize
            minRows={5}
            style={{ width: "100%", fontSize: "16px", padding: "10px" }}
            value={fieldValue}
            disabled={readonly}
          />
        </>
      )
    default:
      return (
        <FormGroup>
          <Label for={name}>
            {label}
            {required && <font color="red"> *</font>}
          </Label>
          <Input
            name={name}
            id={name}
            style={{ minHeight: (name === "ContentType" || name === "ContentObject") && type === "textarea" && name !== "Description" ? "400px" : "" }}
            type={type}
            placeholder={placeholder || name}
            required={required}
            value={fieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
            readOnly={readonly}
            min={min}
          />
          {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
        </FormGroup>
      )
  }
};

export default CustomInput;
