import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { ENTITY_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";

const EntityForm = ({ editData, setIsAddModalOpen }) => {
  const [addEntity, { isLoading: isEntityAddLoading }] = useAddMutation();
  const [updateEntity, { isLoading: isEntityUpdateLoading }] =
    useUpdateMutation();

  const entityValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter entity."),
    EntityType: Yup.mixed().required("Please select entity-type."),
    SortOrder: Yup.number().min(0).nullable(true),
  });

  const onSubmit = (values) => {
    const entityDetail = {
      Name: values.Name,
      Description: values.Description,
      EntityTypeId: values.EntityType.Id,
    };
    if (values.SortOrder) entityDetail.SortOrder = parseInt(values.SortOrder);

    if (editData && Object.entries(editData).length > 0) {
      entityDetail.Id = editData.Id;
      updateEntity({
        entity: "Org/Entity",
        data: entityDetail,
        tag: ENTITY_TAG,
      }).then((response) => {
        if (!response.error) {
          !isEntityUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Entity updated successfully.");
        }
      });
    } else {
      addEntity({
        entity: "Org/Entity",
        data: entityDetail,
        tag: ENTITY_TAG,
      }).then((response) => {
        if (!response.error) {
          !isEntityAddLoading && setIsAddModalOpen(false);
          successToast("Entity added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        EntityType: editData && editData.EntityType ? editData.EntityType : "",
        SortOrder:
          editData?.SortOrder === 0
            ? 0
            : editData?.SortOrder
              ? editData?.SortOrder
              : 999,
      }}
      onSubmit={onSubmit}
      validationSchema={entityValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <AsyncPaginates
              value={values.EntityType}
              label="Entity Type"
              name="EntityType"
              entity="OrgReference/EntityTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.EntityType}
              fieldTouched={touched.EntityType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sort Order"
              name="SortOrder"
              type="number"
              fieldErrors={errors.SortOrder}
              fieldTouched={touched.SortOrder}
              fieldValue={values.SortOrder}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isEntityAddLoading || isEntityUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isEntityAddLoading || isEntityUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

EntityForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func,
};

export default EntityForm;
