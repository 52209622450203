import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import Modal from "@/shared/components/Modal";
import { successToast } from "@/shared/components/toast";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import {
  SCREEN_CONTENT_TAG,
  APPLICATION_CONTENT_TAG,
} from "@/shared/tagFile";
import AddModal from "@/shared/components/AddModal";

const Crosslinks = ({ displayData }) => {
  const { t } = useTranslation("common");
  const [deleteContent, { isLoading: isDeleting }] = useDeleteMutation();
  const [applicationContentDetails, setApplicationContentDetails] = useState([]);
  const [screenContentDetails, setScreenContentDetails] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [crossLinkName, setCrossLinkName] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState({
    applicationContent: false,
    screenContent: false,
  });
  const history = useHistory();

  const MyComponent = lazy(() => {
    var table = crossLinkName.charAt(0).toUpperCase() + crossLinkName.slice(1);
    if (table === "ScreenContent") {
      return import(`../../Screen/ScreenContent/ScreenContentForm`);
    } else if (table === "ApplicationContent") {
      return import(`../ApplicationContent/ApplicationContentForm`);
    } else {
      return import(`../${table}/${table}Form`);
    }
  });

  const [
    getApplicationContentRecords,
    {
      data: applicationContentData,
      isError: isApplicationContentError,
      isFetching: isApplicationContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  const [
    getScreenContentRecords,
    {
      data: screenContentData,
      isError: isScreenContentError,
      isFetching: isScreenContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getScreenContentRecords({
      entity: "CMS/ScreenContents",
      top: 3,
      filter: `ContentId eq ${displayData.Id}`,
      entities: { Screen: {} },
      tag: SCREEN_CONTENT_TAG,
    });
    getApplicationContentRecords({
      entity: "CMS/ApplicationContents",
      top: 3,
      filter: `ContentId eq ${displayData.Id}`,
      entities: { Application: {} },
      tag: APPLICATION_CONTENT_TAG,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isScreenContentFetching && !isScreenContentError && screenContentData) {
      setScreenContentDetails(screenContentData.value);
    }
  }, [screenContentData, isScreenContentError, isScreenContentFetching]);

  useEffect(() => {
    if (!isApplicationContentFetching && !isApplicationContentError && applicationContentData) {
      setApplicationContentDetails(applicationContentData.value);
    }
  }, [applicationContentData, isApplicationContentError, isApplicationContentFetching]);

  useEffect(() => {
    if (crossLinkName && crossLinkName !== "") {
      setIsAddModalOpen({ ...isAddModalOpen, [crossLinkName]: true });
    }
    // eslint-disable-next-line
  }, [crossLinkName]);

  const onAddClick = (title) => {
    setCrossLinkName(
      `${title.charAt(0).toLowerCase()}${title
        .slice(1)
        .replace(/\s|\(|[0-9]|\)/g, "")}`
    );
  };

  const handleDelete = () => {
    deleteContent({
      entity: deleteRecord.entity,
      id: deleteRecord.Id,
      tag: deleteRecord.tag,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(`${deleteRecord.entity} deleted successfully.`);
      }
    });
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("gamecodex.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <CrosslinkPanel
                title={`Screen Content (${screenContentData && screenContentData["@odata.count"]
                  ? screenContentData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  screenContentDetails && screenContentDetails.length > 0 ? (
                    <div>
                      {screenContentDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMS/ScreenContent`,
                                    tag: SCREEN_CONTENT_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Screen Name: </b>
                                  {value?.ScreenDTO?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to={{
                            pathname: "/cms/screen-content",
                            state: { name: displayData.Name, title: "Content" },
                          }}
                          className="disabledCursor"
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="primary"
              ></CrosslinkPanel>
              <CrosslinkPanel
                title={`Application Content (${applicationContentDetails?.length && applicationContentData && applicationContentData["@odata.count"] ? applicationContentData["@odata.count"] : "0"})`}
                onAddClick={onAddClick}
                description={
                  applicationContentDetails && applicationContentDetails?.length > 0 ? (
                    <div>
                      {applicationContentDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMS/ApplicationContent`,
                                    tag: SCREEN_CONTENT_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Application Name: </b>
                                  {(value.ApplicationDTO?.Name || value.ApplicationDTO?.name)}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          className="disabledCursor"
                          onClick={() => {
                            history.push("/cms/application-content");
                          }}
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="danger"
              ></CrosslinkPanel>
            </div>
          </CardBody>
        </Card >
      </Col >
      {
        isAddModalOpen[crossLinkName] && (
          <AddModal
            toggle={() => {
              setIsAddModalOpen({ ...isAddModalOpen, [crossLinkName]: false });
              setCrossLinkName("");
            }}
            color="success"
            title={`Add ${crossLinkName.charAt(0).toUpperCase() + crossLinkName.slice(1)
              }`}
            header
            btn="Success"
            message={
              <Suspense fallback={<div>Loading..</div>}>
                <MyComponent
                  setIsAddModalOpen={() => {
                    setIsAddModalOpen({
                      ...isAddModalOpen,
                      [crossLinkName]: false,
                    });
                    setCrossLinkName("");
                  }}
                  parentData={{ Content: displayData }}
                />
              </Suspense>
            }
          />
        )
      }
      {
        isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )
      }
    </>
  );
};

export default Crosslinks;
