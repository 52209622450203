import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { APPLICATION_CONTENT_TAG, CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ApplicationContentForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addApplicationContent, { isLoading: isApplicationContentAddLoading }] =
    useAddMutation();
  const [
    updateApplicationContent,
    { isLoading: isApplicationContentUpdateLoading },
  ] = useUpdateMutation();

  const ContentValidationSchema = Yup.object().shape({
    Application: Yup.mixed().required("Please select application."),
    Content: Yup.mixed().required("Please select Content."),
  });

  const onSubmit = (values) => {
    const applicationContentDetail = {
      ApplicationId: values.Application.Id,
      ContentId: values.Content.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      applicationContentDetail.Id = editData.Id;
      updateApplicationContent({
        entity: "CMS/ApplicationContent",
        data: applicationContentDetail,
        tag: [APPLICATION_CONTENT_TAG, CONTENT_TAG],
      }).then((response) => {
        if (!response.error) {
          !isApplicationContentUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Application-Content updated successfully.");
        }
      });
    } else {
      addApplicationContent({
        entity: "CMS/ApplicationContent",
        data: applicationContentDetail,
        tag: [APPLICATION_CONTENT_TAG, CONTENT_TAG],
      }).then((response) => {
        if (!response.error) {
          !isApplicationContentAddLoading && setIsAddModalOpen(false);
          successToast("Application-Content added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Application:
          editData && editData.ApplicationDTO ? editData.ApplicationDTO : "",
        Content: editData && editData.ContentDTO ? editData.ContentDTO : parentData && parentData.Content ? parentData.Content : "",
      }}
      validationSchema={ContentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <AsyncPaginates
              value={values.Application}
              label="Application"
              name="Application"
              entity="CMS/Applications"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Application}
              fieldTouched={touched.Application}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.Content}
              label="Content"
              name="Content"
              entity="CMS/Contents"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Content}
              fieldTouched={touched.Content}
              handleBlur={handleBlur}
              readonly={parentData && parentData.ContentDTO ? parentData.ContentDTO : parentData.Content}
              required
            />
            <Button
              disabled={
                isApplicationContentAddLoading ||
                isApplicationContentUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isApplicationContentAddLoading ||
                isApplicationContentUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ApplicationContentForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ApplicationContentForm;
