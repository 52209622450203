import { createSlice } from "@reduxjs/toolkit";

const orgSlice = createSlice({
  name: "org",
  initialState: {
    entity: [],
    entityTypes: [],
    entityRelation: [],
    relations: [],
  },
  reducers: {
    getAllEntity: (state, action) => {
      state.entity = action.payload;
    },
    getAllEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    },
    getAllEntityRelation: (state, action) => {
      state.entityRelation = action.payload;
    },
    getAllRelations: (state, action) => {
      state.relations = action.payload;
    },
  },
});

export default orgSlice.reducer;
export const {
  getAllEntity,
  getAllEntityTypes,
  getAllEntityRelation,
  getAllRelations,
} = orgSlice.actions;
