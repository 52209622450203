import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        role: [],
        roleTypes: [],
        users: [],
        userTypes: [],
        authMedia: []
    },
    reducers: {
        getAllRoles: (state, action) => {
            state.role = action.payload;
        },
        getAllRoleTypes: (state, action) => {
            state.roleTypes = action.payload;
        },
        getAllUsers: (state, action) => {
            state.users = action.payload
        },
        getAllUserTypes: (state, action) => {
            state.userTypes = action.payload
        },
        getAllAuthMedia: (state, action) => {
            state.authMedia = action.payload
        },
    },
});

export default authSlice.reducer;
export const {
    getAllRoles,
    getAllRoleTypes,
    getAllUsers,
    getAllUserTypes,
    getAllAuthMedia,
} = authSlice.actions