import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useLazyGetRecordsQuery, useAddMutation } from "@/services/pcApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { SCREEN_CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenContentForm = ({
  displayData,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {
  const [screenContentDetails, setScreenContentDetails] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [addScreenContent, { isLoading: isScreenContentAddLoading }] =
    useAddMutation();

  const [
    getScreenContentRecords,
    {
      data: screenContentList,
      isError: isScreenContentError,
      isLoading: isScreenContentLoading,
      isFetching: isContentscreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getScreenContentRecords({
      entity: "CMS/ScreenContents",
      entities: { Screen: {}, Content: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      filter: `(ScreenId eq ${displayData.ScreenDTO.Id})`,
      sort: sort,
      tag: SCREEN_CONTENT_TAG,
    });
    setScreenContentDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [displayData, pageSize, page, sort]);

  useEffect(() => {
    if (
      !isScreenContentLoading &&
      !isScreenContentError &&
      screenContentList &&
      !isContentscreenFetching
    ) {
      if (displayData > 0) {
        setScreenContentDetails({
          ...screenContentDetails,
          tableRowsData: screenContentList.value
            ? screenContentList.value
            : [],
          totalData:
            screenContentList && screenContentList["@odata.count"]
              ? screenContentList["@odata.count"]
              : 0,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    screenContentList,
    isScreenContentError,
    isScreenContentLoading,
    displayData,
    isContentscreenFetching,
  ]);

  const column = [
    {
      Header: "#",
      accessor: "Id",
    },
    {
      Header: "Screen",
      accessor: "Screen.Name",
    },
    {
      Header: "Content",
      accessor: "Content.Name",
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const screenContentValidationSchema = Yup.object().shape({
    Content: Yup.mixed().required("Please select content."),
    Component: Yup.mixed().required("Please select component."),
  });

  const onSubmit = (values) => {
    const screenContentDetail = {
      ScreenId: displayData.ScreenDTO.Id,
      ContentId: values.Content.Id,
      ComponentId: values.Component.Id,
    };

    addScreenContent({
      entity: "CMS/ScreenContent",
      data: screenContentDetail,
      tag: SCREEN_CONTENT_TAG,
    }).then((response) => {
      if (!response.error) {
        successToast("Content-screen added successfully.");
      }
      setIsAddModalOpen({ ...isAddModalOpen, screenContent: false });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          Screen: displayData?.ScreenDTO ?? "",
          Content: "",
          Component: "",
          SortOrder: "",
        }}
        validationSchema={screenContentValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          } = formik;

          return (
            <Form>
              <AsyncPaginates
                value={values.Screen}
                label="Screen"
                name="Screen"
                entity="Screen"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                readonly={displayData && displayData.ScreenDTO}
                required
              />
              <AsyncPaginates
                value={values.Content}
                label="Content"
                name="Content"
                entity="CMS/Contents"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Content}
                fieldTouched={touched.Content}
                handleBlur={handleBlur}
                required
              />
              <AsyncPaginates
                value={values.Component}
                label="Component"
                name="Component"
                entity="CMSReference/Components"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Component}
                fieldTouched={touched.Component}
                handleBlur={handleBlur}
                required
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  onClick={handleSubmit}
                  className="modal_ok float-right"
                  color="success"
                >
                  {isScreenContentAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}{" "}
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {screenContentDetails && (
          <DataReactTable
            reactTableData={screenContentDetails}
            onAddClick={() => { }}
            title="Content Screen"
            headerContent={false}
            isFetching={isContentscreenFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
          />
        )}
      </div>
    </>
  );
};

ScreenContentForm.propTypes = {
  parentId: PropTypes.number.isRequired,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object,
};

export default ScreenContentForm;
