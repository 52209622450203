import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { RELATION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllRelations } from "../../OrgSlice";
import RelationForm from "./RelationForm";
import "react-toastify/dist/ReactToastify.css";

const Relation = () => {
  const [relationDetails, setRelationDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteRelation, { isLoading: isDeleting }] =
    useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getRelationRecords,
    {
      data: relationList,
      isError: isRelationError,
      isLoading: isRelationLoading,
      isFetching: isRelationFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleEditData = (relationData) => {
    setSelectedRecord(relationData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteRelation({
      entity: "OrgReference/Relation",
      id: selectedRecord.Id,
      tag: RELATION_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Relation deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getRelationRecords({
      entity: "OrgReference/Relations",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: RELATION_TAG,
    });
    setRelationDetails({
      tableHeaderData: column,
      tableRowsData: [],
    });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isRelationLoading &&
      !isRelationError &&
      relationList &&
      !isRelationFetching
    ) {
      dispatch(getAllRelations(relationList.value));
      let relationData = [...relationList.value];
      setRelationDetails({
        ...relationDetails,
        tableRowsData: relationList.value
          ? relationData
          : [],
        totalData:
          relationList && relationList["@odata.count"]
            ? relationList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isRelationError,
    relationList,
    isRelationLoading,
    isRelationFetching,
  ]);

  return (
    <Container>
      <Row>
        {relationDetails && (
          <DataReactTable
            reactTableData={relationDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Relation"
            isFetching={isRelationFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Relation`}
          header
          btn="Success"
          message={
            <RelationForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord?.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Relation;
