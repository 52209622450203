//Auth
export const USER_TAG = "user_tag";
export const USER_TYPE_TAG = "user_type_tag";
export const ROLE_TAG = "role_tag";
export const ROLE_TYPE_TAG = "role_type_tag";
//CMS
//Application
export const APPLICATION_TYPE_TAG = "application_type_tag";
export const APPLICATION_TAG = "application_tag";
//Component
export const COMPONENT_TAG = "application_tag";
//Content
export const CONTENT_TYPE_TAG = "content_type_tag";
export const CONTENT_MENU_TAG = "content_menu_tag";
export const CONTENT_TAG = "content_tag";
export const CONTENT_MEDIA_TAG = "content_media_tag";
export const APPLICATION_CONTENT_TAG = "application_content_tag";
//Media
export const MEDIA_TAG = "media_tag";
export const MEDIA_TYPE_TAG = "media_type_tag";
//Menu
export const MENU_TAG = "menu_tag";
export const MENU_TYPE_TAG = "menu_type_tag";
export const APPLICATION_MENU_TAG = "application_menu_tag";
export const SCREEN_MENU_TAG = "screen_menu_tag";
//Screen
export const SCREEN_TYPE_TAG = "screen_type_tag";
export const SCREEN_CONTENT_TAG = "screen_content_tag";
export const SCREEN_TAG = "screen_tag";
export const APPLICATION_SCREEN_TAG = "app_screen_tag";
//Entity
export const ENTITY_TAG = "entity_tag";
export const ENTITY_TYPE_TAG = "entity_type_tag";
export const ORG_ENTITY_MEDIA_TAG = "org_entity_media_tag";
export const ENTITY_MEDIA_TYPE_TAG = "entity_media_type_tag";
//EntityRelation
export const ENTITY_RELATION_TAG = "entity_relation_tag";
export const RELATION_TAG = "relation_tag";
export const RELATION_REQUEST_TAG = "relation_request_tag";

export const APPVERSION_TAG = "app_version_tag";
// Shopify
export const SHOPIFY_VENDORS_TAG = "shopify_vendors_tag";
export const SHOPIFY_PRODUCT_TAG = "shopify_products_tag";
export const SUPLIMENTALSALES_TAG = "suplimentalsales_tag";
export const BACKERKIT_ORDERS_TAG = "backerKit_orders_tag";

export const TagType = [
  //Auth
  USER_TAG,
  USER_TYPE_TAG,
  ROLE_TAG,
  ROLE_TYPE_TAG,
  //CMS,
  //Application,
  APPLICATION_TYPE_TAG,
  APPLICATION_TAG,
  //Component,
  COMPONENT_TAG,
  //Content,
  CONTENT_TYPE_TAG,
  CONTENT_MENU_TAG,
  CONTENT_TAG,
  CONTENT_MEDIA_TAG,
  APPLICATION_CONTENT_TAG,
  //Media,
  MEDIA_TAG,
  MEDIA_TYPE_TAG,
  //Menu,
  MENU_TAG,
  MENU_TYPE_TAG,
  SCREEN_MENU_TAG,
  APPLICATION_MENU_TAG,
  //Screen,
  SCREEN_TYPE_TAG,
  SCREEN_CONTENT_TAG,
  SCREEN_TAG,
  APPLICATION_SCREEN_TAG,
  //Org
  ENTITY_TAG,
  ENTITY_TYPE_TAG,
  ENTITY_RELATION_TAG,
  RELATION_TAG,
  RELATION_REQUEST_TAG,
  APPVERSION_TAG,
  // Shopify
  SHOPIFY_VENDORS_TAG,
  SHOPIFY_PRODUCT_TAG,
  SUPLIMENTALSALES_TAG,
  BACKERKIT_ORDERS_TAG,
];
