import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { MEDIA_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const MediaForm = ({ editData, setIsAddModalOpen }) => {
  const [addMedia, { isLoading: isMediaAddLoading }] = useAddMutation();
  const [updateMedia, { isLoading: isMediaUpdateLoading }] =
    useUpdateMutation();
  const cmsMediaValidationSchema = Yup.object().shape({
    PathName: Yup.string().required("Please enter Path Name."),
    MediaType: Yup.mixed().required("Please select Media Type."),
    File:
      editData && Object.entries(editData).length > 0
        ? Yup.mixed()
        : Yup.mixed().required("Please select file."),
  });

  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("MediaTypeId", values.MediaType.Id)
    if (editData && Object.entries(editData).length > 0) {
      formData.append("MediaId", editData.Id);
      if (values.File) {
        formData.append("File", values.File);
      }
      updateMedia({
        entity: "CMS/CMSMedia",
        Id: editData.Id,
        data: formData,
        tag: MEDIA_TAG,
      }).then((response) => {
        if (!response.error) {
          !isMediaUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Media updated successfully.");
        }
      });
    } else {
      formData.append("File", values.File);
      formData.append("pathFolderName", values.PathName);
      addMedia({
        entity: "CMS/CMSMedia",
        data: formData,
        tag: MEDIA_TAG,
      }).then((response) => {
        if (!response.error) {
          !isMediaAddLoading && setIsAddModalOpen(false);
          successToast("Media added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        File: "",
        PathName: "",
        MediaType: "",
      }}
      enableReinitialize
      validationSchema={cmsMediaValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleChange,
        } = formik;
        return (
          <Form>
            {Object.keys(editData).length > 0 && (
              <CustomInput label="Id" fieldValue={editData.Id} readonly />
            )}
            <CustomInput
              label="Path Name"
              name="PathName"
              fieldErrors={errors.PathName}
              fieldTouched={touched.PathName}
              fieldValue={values.PathName}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <AsyncPaginates
              value={values.MediaType}
              label="Media Type"
              name="MediaType"
              entity="CMSReference/MediaTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.MediaType}
              fieldTouched={touched.MediaType}
              handleBlur={handleBlur}
              required
              isSearchable={false}
            />
            {editData && editData.Media && !values.File && (
              <img
                src={editData.Media.CloudinaryAddress}
                alt={editData.Media.CloudinaryAddress}
              />
            )}
            <CustomInput
              label="File"
              name="File"
              type="file"
              handleChange={(e) => {
                setFieldValue("File", e.target.files[0]);
              }}
              fieldErrors={errors.File}
              fieldTouched={touched.File}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={isMediaAddLoading || isMediaUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isMediaAddLoading || isMediaUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

MediaForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default MediaForm;
