import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Spinner } from "reactstrap";
import { Formik } from "formik";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import { RELATION_REQUEST_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { useLazyGetRecordsQuery, useUpdateWithParamsMutation, useDeleteMutation } from "@/services/pcApi";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "../../../shared/components/LazySelect";
import SendInvitationForm from "./SendInvitation";
import RelationRequestForm from "./RelationRequestForm";
import "react-toastify/dist/ReactToastify.css";

const RelationRequest = () => {
  const [relationRequestDetails, setRelationRequestDetails] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRejectReasonModal, setIsRejectReasonModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [isApprovedOrRejectRelationRequest, setIsApprovedOrRejectRelationRequest] = useState(false);
  const [selectedApprovedOrRejectedRelationRequest, setSelectedApprovedOrRejectedRelationRequest] = useState("");
  const [selectedRelationRequestStatus, setSelectedRelationRequestStatus] = useState();
  const [updateRelationRequestStatus, { isLoading: isUpdateRelationRequestStatusLoading }] = useUpdateWithParamsMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [deleteRelation, { isLoading: isDeleting }] = useDeleteMutation();
  const [
    getRelationRequestRecords,
    {
      data: relationRequestList,
      isError: isRelationRequestError,
      isLoading: isRelationRequestLoading,
      isFetching: isRelationRequestFetching,
    },
  ] = useLazyGetRecordsQuery();

  const handleStatusChange = async (selectedRelationRequestDetail, selectedRelationRequestStatus, values) => {
    const updatedObjects = values?.Vendors && selectedRelationRequestDetail?.RelationDTO?.Name?.toLowerCase() === "client" ? values?.Vendors?.map(({ Id, Name }) => {
      return (
        { Id, Name, Status: "approved" }
      )
    }) : [];
    if (selectedRelationRequestStatus === "Reject") setIsRejectReasonModal(true);
    else {
      const rejectedVendors = selectedRelationRequestDetail?.VendorObject ? JSON.parse(selectedRelationRequestDetail.VendorObject)?.map(({ Id, Name }) => ({
        Id,
        Name,
        Status: "rejected",
      })) : [];
      const encodedVendors = encodeURIComponent(JSON.stringify(updatedObjects?.length ? updatedObjects : rejectedVendors));
      updateRelationRequestStatus({
        entity: `/Org/ManageUserRelationRequest?id=${selectedRelationRequestDetail.Id}&status=${selectedRelationRequestStatus === "Approve" ? "approve" : "reject"}&rejectionReason=${rejectReason}&vendors=${encodedVendors}`,
        tag: RELATION_REQUEST_TAG,
      }).then((response) => {
        if (!response.error) {
          !isUpdateRelationRequestStatusLoading && setIsApprovedOrRejectRelationRequest(!isApprovedOrRejectRelationRequest)
          setIsRejectReasonModal(false)
          successToast(`RelationRequest ${selectedRelationRequestStatus === "Approve" ? "approve" : "reject"} successfully.`)
        }
      });
    }
  }

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "User Name",
      accessor: "UserDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Email",
      accessor: "Email",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Requested Relation",
      accessor: "RelationDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Admin Requested",
      accessor: "IsAdminRequested",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: "Vendors",
      accessor: "VendorObject",
      filter: true,
      Cell: ({ value }) => {
        let data = []
        if (value) {
          data = JSON.parse(value)
        }

        return (
          <div className="container d-flex flex-wrap ">
            {data?.length ? data?.map((vendor, i) => (
              <div
                key={i}
                className={`bg-secondary text-white `}
                style={{ borderRadius: "10px", padding: "0 5px", margin: "5px 0 " }}
              >
                {vendor.Name}
              </div>
            )) : "-"}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "StatusDTO.Name",
      filter: true,
      Cell: (data) => {
        return data.value && !data?.cell?.row?.original?.IsAdminRequested ? (
          <div className="d-flex">
            {data.value === "Pending" ? (
              <div>
                <CheckCircleOutlineIcon
                  title="Approve"
                  className="cursor-pointer mx-2"
                  color="green"
                  onClick={(e) => {
                    setIsApprovedOrRejectRelationRequest(true);
                    setSelectedApprovedOrRejectedRelationRequest(data.cell.row.original);
                    setSelectedRelationRequestStatus("Approve");
                  }}
                />
                <CloseCircleOutlineIcon
                  className="cursor-pointer mx-2"
                  color="red"
                  onClick={(e) => {
                    setIsApprovedOrRejectRelationRequest(true);
                    setSelectedApprovedOrRejectedRelationRequest(data.cell.row.original);
                    setSelectedRelationRequestStatus("Reject");
                  }}
                />
              </div>
            ) : data.value === "Approve" ? (
              <span className="badge badge-success">{data.value}</span>
            ) : (
              <span className="badge badge-danger">{data.value}</span>
            )}
          </div>
        ) : data?.cell?.row?.original?.IsAdminRequested && data.value === "Approve" ?
          <span className="badge badge-success">{data?.cell?.row?.original?.StatusDTO?.Name}</span> :
          <span className="badge badge-warning">{data?.cell?.row?.original?.StatusDTO?.Name}</span>;
      },
    },
  ];

  const Vendors = ({ formikProps }) => {
    const { values, errors, touched, handleBlur, setFieldValue } = formikProps;
    return (
      <Form className="text-left">
        <AsyncPaginates
          value={values.Vendors}
          label="Vendors"
          name="Vendors"
          entity="ShopifyReference/ShopifyVendors"
          setFieldValue={setFieldValue}
          fieldErrors={errors.Vendors}
          fieldTouched={touched.Vendors}
          handleBlur={handleBlur}
          isMulti={true}
        />
      </Form>
    );
  };

  const handleDelete = () => {
    deleteRelation({
      entity: "Org/UserRelationRequest",
      id: selectedRecord.Id,
      tag: RELATION_REQUEST_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Relation Request deleted successfully.");
      }
    });
  };

  const handleEditData = (data) => {
    setSelectedRecord(data);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getRelationRequestRecords({
      entity: "Org/UserRelationRequests",
      top: pageSize,
      entities: { Relation: {}, Status: {}, User: {} },
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: RELATION_REQUEST_TAG,
    });
    setRelationRequestDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isRelationRequestLoading && !isRelationRequestError && relationRequestList && !isRelationRequestFetching) {
      let relationRequestData = [...relationRequestList.value];
      if (selectedRecord) {
        setSelectedRecord(
          (prev) => relationRequestList.value.find((value) => value.Id === prev.Id)?.RelationRequest
        );
      }
      setRelationRequestDetails({
        ...relationRequestDetails,
        tableRowsData: relationRequestList.value ? relationRequestData : [],
        totalData:
          relationRequestList && relationRequestList["@odata.count"] ? relationRequestList["@odata.count"] : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [isRelationRequestError, relationRequestList, isRelationRequestLoading, isRelationRequestFetching]);

  return (
    <Container>
      <Row>
        {relationRequestDetails && (
          <DataReactTable
            reactTableData={relationRequestDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            buttonText={"Send Invitation"}
            title="relation Request"
            isFetching={isRelationRequestFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isApprovedOrRejectRelationRequest && (
        <Formik
          initialValues={{
            Vendors: Object.entries(selectedApprovedOrRejectedRelationRequest?.VendorObject)?.length ? JSON.parse(selectedApprovedOrRejectedRelationRequest?.VendorObject) : '',
          }}
        >
          {(formikProps) => (
            <Modal
              color={selectedRelationRequestStatus === "Approve" ? "success" : "danger"}
              title={`${selectedRelationRequestStatus} Request`}
              xl={selectedRelationRequestStatus === "Approve" ? "" : "xl"}
              message={<>
                Are you sure you want to {selectedRelationRequestStatus} user relation request ?
                {selectedApprovedOrRejectedRelationRequest?.RelationDTO?.Name?.toLowerCase() === "client" && <Vendors formikProps={formikProps} />}
              </>}
              handleOkClick={() => handleStatusChange(selectedApprovedOrRejectedRelationRequest, selectedRelationRequestStatus, formikProps.values)}
              toggle={() => setIsApprovedOrRejectRelationRequest(!isApprovedOrRejectRelationRequest)}
              isDeleting={isUpdateRelationRequestStatusLoading}
            />
          )}
        </Formik>
      )}
      {isRejectReasonModal && (
        <AddModal
          toggle={() => setIsRejectReasonModal(!isRejectReasonModal)}
          color="success"
          title={`Reason for reject relation request`}
          header
          btn="Success"
          message={
            <>
              <CustomInput
                label="rejectReason"
                name="rejectReason"
                fieldValue={rejectReason}
                handleChange={(e) => setRejectReason(e.target.value)}
                required
              />
              <Button
                onClick={() => handleStatusChange(selectedApprovedOrRejectedRelationRequest)}
                className="modal_ok float-right"
                color="success"
                disabled={isUpdateRelationRequestStatusLoading}
              >
                {isUpdateRelationRequestStatusLoading && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
                Save
              </Button>
            </>
          }
        />
      )}
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Entity`}
          header
          btn="Success"
          message={
            <SendInvitationForm
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isEditModalOpen && (
        <AddModal
          toggle={() => setIsEditModalOpen(!isEditModalOpen)}
          color="success"
          title="Edit Vendor list"
          header
          btn="Success"
          message={
            <div>
              <RelationRequestForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsEditModalOpen={setIsEditModalOpen}
              />
            </div>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord?.UserDTO?.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );


};

export default RelationRequest;
