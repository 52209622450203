import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { ENTITY_RELATION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { getAllEntityRelation } from "../../OrgSlice";
import "react-toastify/dist/ReactToastify.css";

const EntityRelation = () => {
  const [entityRelationDetails, setEntityRelationDetails] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteEntityRelation, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getEntityRelationRecords,
    {
      data: entityRelationList,
      isError: isEntityRelationError,
      isLoading: isEntityRelationLoading,
      isFetching: isEntityRelationFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Entity",
      accessor: "EntityDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Relation",
      accessor: "RelationDTO.Name",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleDelete = () => {
    deleteEntityRelation({
      entity: "Org/EntityRelation",
      id: selectedRecord.Id,
      tag: ENTITY_RELATION_TAG,
    }).then((entityResponse) => {
      if (!entityResponse.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Entity-relation deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getEntityRelationRecords({
      entity: "Org/EntityRelations",
      entities: { Entity: {}, Relation: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: ENTITY_RELATION_TAG,
    });
    setEntityRelationDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isEntityRelationLoading &&
      !isEntityRelationError &&
      entityRelationList &&
      !isEntityRelationFetching
    ) {
      dispatch(getAllEntityRelation(entityRelationList.value));
      let entityRelationData = [...entityRelationList.value];
      setEntityRelationDetails({
        ...entityRelationDetails,
        tableRowsData: entityRelationList.value ? entityRelationData : [],
        totalData:
          entityRelationList && entityRelationList["@odata.count"]
            ? entityRelationList["@odata.count"]
            : 0,
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isEntityRelationError,
    entityRelationList,
    isEntityRelationLoading,
    isEntityRelationFetching,
  ]);

  return (
    <Container>
      <Row>
        {entityRelationDetails && (
          <DataReactTable
            reactTableData={entityRelationDetails}
            title="Entity Relation"
            isFetching={isEntityRelationFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default EntityRelation;
