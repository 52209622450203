import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import EditIcon from "mdi-react/PencilIcon";
import LeftIcon from "mdi-react/KeyboardArrowLeftIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
const Summary = ({ displayData, title, setIsAddModalOpen, restContent }) => {
  const history = useHistory()
  return (
    <>
      <Col xl={3}>
        <Card>
          <CardBody>
            <div className="card__title">
              <div
                className="bold-text cursor-pointer text-primary"
                onClick={() => {
                  history.goBack()
                }}
              >
                <LeftIcon />
                {title}
              </div>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div className="d-flex">
                <div className="profile__avatar">
                  <img src={Ava} alt="avatar" />
                </div>
                <div className="profile__data">
                  <p className="profile__name">{(displayData?.Name || displayData?.name)}</p>
                  <p className="profile__work">
                    {
                      displayData[
                        `${title === "Application" ? "App" : title}Type`
                      ]?.Name
                    }
                  </p>
                  <p className="profile__contact">{(displayData?.Description || displayData?.description)}</p>
                </div>
                <div className="cursor-pointer">
                  <EditIcon onClick={() => setIsAddModalOpen(true)} />
                </div>
              </div>
            </div>
            {restContent && restContent}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Summary;
