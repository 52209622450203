import React from "react";
import { Route } from "react-router-dom";
import ShopifyVendors from "@/components/Shopify/ShopifyVendor/ShopifyVendors";
import ShopifyProducts from "@/components/Shopify/ShopifyProducts/ShopifyProducts";
import ShopifyProductDetails from "@/components/Shopify/ShopifyProducts/ShopifyProductDetails";
import SupplementalSales from "@/components/Shopify/SupplementalSales/SupplementalSales";
import BackerKitOrders from "@/components/Shopify/BackerKitOrdersPDf/BackerKitOrders";

const ShopifyRoutes = () => (
  <div>
    <Route path="/shopify/vendors" component={ShopifyVendors} />
    <Route path="/shopify/products" component={ShopifyProducts} />
    <Route path="/shopify/products-details" component={ShopifyProductDetails} />
    <Route path="/shopify/supplemental-sales" component={SupplementalSales} />
    <Route path="/shopify/backerkit-orders" component={BackerKitOrders} />
  </div>
);

export default ShopifyRoutes;
