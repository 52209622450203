import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import Summary from "@/shared/components/entity_detail/Summary";
import AddModal from "@/shared/components/AddModal";
import { useLazyGetRecordsQuery } from "@/services/pcApi";
import Collapse from "@/shared/components/Collapse";
import { CONTENT_TAG } from "@/shared/tagFile";
import ContentForm from "../Content/ContentForm";
import Crosslinks from "./Crosslinks";
import "react-toastify/dist/ReactToastify.css";

const ContentDetail = (props) => {
  const contentId = JSON.parse(props.location.state.contentId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [contentDetail, setContentDetail] = useState();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [
    getContentRecords,
    {
      data: contentList,
      isError: isContentError,
      isLoading: isContentLoading,
      isFetching: isContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (contentId) {
      getContentRecords({
        entity: "CMS/Contents",
        filter: `Id eq ${contentId}`,
        entities: { ContentType: {} },
        tag: CONTENT_TAG,
      });
    }
    // eslint-disable-next-line
  }, [contentId]);

  useEffect(() => {
    if (
      !isContentLoading &&
      !isContentError &&
      contentList &&
      !isContentFetching
    ) {
      setContentDetail(contentList.value[0]);
    }
    // eslint-disable-next-line
  }, [isContentError, contentList, isContentLoading, isContentFetching]);

  return (
    <Container>
      {contentDetail && contentDetail.Name ? (
        <Row>
          <Summary
            displayData={contentDetail}
            title="Content"
            setIsAddModalOpen={setIsAddModalOpen}
            restContent={
              contentDetail ? (
                <>
                  <hr />
                  <div>
                    <Collapse
                      className="with-shadow"
                      title="About this content"
                      collapse={isCollapseOpen}
                      onClick={() => {
                        setIsCollapseOpen(!isCollapseOpen);
                      }}
                    >
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Name:</th>
                            <td>
                              {(contentDetail?.Name || contentDetail?.name) ?? "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Description:</th>
                            <td>
                              {(contentDetail?.Description || contentDetail?.Description) ?? "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Content Type:</th>
                            <td>
                              {(contentDetail?.ContentTypeDTO?.Name || contentDetail?.ContentTypeDTO?.name) ?? "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                </>
              ) : (
                <div></div>
              )
            }
          ></Summary>
          <Activity tree="Content" displayData={contentDetail}></Activity>
          <Crosslinks displayData={contentDetail}></Crosslinks>
          {isAddModalOpen && (
            <AddModal
              toggle={() => setIsAddModalOpen(!isAddModalOpen)}
              xxl
              color="success"
              title="Edit Content"
              header
              btn="Success"
              message={
                <ContentForm
                  editData={contentDetail}
                  setIsAddModalOpen={setIsAddModalOpen}
                />
              }
            />
          )}
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

export default ContentDetail;
