import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import LaunchIcon from "mdi-react/LaunchIcon";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddContentModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ContentForm from "./ContentForm";
import { getAllContents } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";

const Content = () => {
  const [contentDetails, setContentDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteContent, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    getContentRecords,
    {
      data: contentList,
      isError: isContentError,
      isLoading: isContentLoading,
      isFetching: isContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Content Type",
      accessor: "ContentTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Media",
    },
  ];

  const handleShowDetails = (contentData) => {
    history.push({
      pathname: "/cms/content-detail",
      state: {
        contentId: contentData.Id,
      },
    });
  };

  const handleEditData = (contentData) => {
    let secondObject = JSON.parse(contentData.ContentTypeDTO.ContentObject)
    let firstObject = JSON.parse(contentData.ContentObject)
    let finalResponse = contentData
    for (let k in firstObject) {
      if ((k === 'lists' || k === 'images') && secondObject[k]) {
        let existingLists = []
        firstObject[k] && firstObject[k].forEach((val, pIndex) => {
          if (firstObject[k].some((_, cIndex) => pIndex === cIndex)) {
            existingLists.push(firstObject[k][pIndex])
          } else {
            existingLists.push(val)
          }
          secondObject[k] = existingLists;
        })
      }
      else {
        secondObject[k] = firstObject[k];
      }
    }
    finalResponse['ContentObject'] = JSON.stringify(secondObject)
    setSelectedRecord(finalResponse);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteContent({
      entity: "CMS/Content",
      id: selectedRecord.Id,
      tag: CONTENT_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Content deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getContentRecords({
      entity: "CMS/Contents",
      entities: { ContentType: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: CONTENT_TAG,
    });
    setContentDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isContentLoading &&
      !isContentError &&
      contentList &&
      !isContentFetching
    ) {
      dispatch(getAllContents(contentList.value));
      let contentData = [...contentList.value];
      contentData = contentList.value.map((content) => {
        let newContentData = { ...content };
        return newContentData;
      });
      setContentDetails({
        ...contentDetails,
        tableRowsData: contentList.value ? contentData : [],
        totalData:
          contentList && contentList["@odata.count"]
            ? contentList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => handleShowDetails(data),
      });
    }
    // eslint-disable-next-line
  }, [isContentError, contentList, isContentLoading, isContentFetching]);

  return (
    <Container>
      <Row>
        {contentDetails && (
          <DataReactTable
            reactTableData={contentDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Content"
            isFetching={isContentFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddContentModal
          xxl
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Content`}
          header
          btn="Success"
          message={
            <ContentForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isMediaModalOpen && (
        <AddContentModal
          toggle={() => setIsMediaModalOpen(!isMediaModalOpen)}
          color="success"
          title={`Content Media`}
          header
          btn="Success"
          xl
          message={
            <Row>
              {selectedRecord.ContentMedias.map((val) => {
                return (
                  <Col md={6} key={val.Id}>
                    <img
                      alt={val.Media.Name}
                      src={val.Media.CloudinaryAddress}
                    />
                    <div className="text-center">{val.Media.Name}</div>
                  </Col>
                );
              })}
            </Row>
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Content;
