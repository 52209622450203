import React from "react";
import { Route } from "react-router-dom";
//Entity
import Entity from "@/components/Org/Entity/Entity/Entity";
import EntityType from "@/components/Org/Entity/EntityType/EntityType";
import EntityRelation from "@/components/Org/EntityRelation/EntityRelation/EntityRelation";
import Relation from "@/components/Org/EntityRelation/EntityRelationType/Relation";
import RelationRequest from "@/components/Org/Request/RelationRequest";

const OrgRoutes = () => (
  <div>
    <Route path="/org/entity" component={Entity} />
    <Route path="/org/entity-type" component={EntityType} />
    <Route path="/org/entity-relation" component={EntityRelation} />
    <Route path="/org/relation" component={Relation} />
    <Route path="/org/relation-request" component={RelationRequest} />
  </div>
);

export default OrgRoutes;
