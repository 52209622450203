import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useLiveQuery } from "dexie-react-hooks";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import { db } from "@/containers/Dexie Database/db";
import ReactTable from "@/containers/Tables/DataTable/components/CustomDataTable";
import "react-toastify/dist/ReactToastify.css";

const ShopifyProducts = () => {
  const [appDetails, setAppDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const column = [
    {
      Header: "Product",
      accessor: "productTitle",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "Vendor",
      accessor: "productVendor",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "SKU",
      accessor: "variantSku",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "Price",
      accessor: "variantPrice",
      type: "Number",
      Cell: (({ value }) => {
        return value ? `$${parseFloat(value).toFixed(2)}` : "-";
      })
    },
    {
      Header: "Quantity",
      accessor: "inventoryQuantity",
      type: "Number",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "Status",
      accessor: "productStatus",
      Cell: (({ value }) => {
        return value ? (
          <span
            className={`font-weight-bold text-capitalize product-status ${value === "active"
              ? "text-success"
              : value === "draft"
                ? "text-primary"
                : "text-secondary"
              }`}
          >
            {value}
          </span>)
          : "-"
      })
    }
  ]

  useEffect(() => {
    let { _ } = formateColumnFilter(column, {});

    setAppDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, []);

  const handleShowDetails = (applicationData) => {
    successToast("Product detail page is coming soon")
    // history.push({
    //   pathname: "/cms/application-detail",
    //   state: {
    //     applicationId: applicationData.Id,
    //   },
    // });
  };

  const products = useLiveQuery(
    async () => {
      setIsLoading(true);
      const AllShopifyProducts = await db.AllShopifyProducts.toArray();
      setIsLoading(false);
      return AllShopifyProducts;
    }
  );

  useEffect(() => {
    if (products?.length) {
      let appData = [...products];
      setAppDetails({
        ...appDetails,
        tableRowsData: products ? appData : [],
        totalData: products.length ?? 0,
      });
    }
  }, [products])


  return (
    <Container data-testid="test-app">
      <Row>
        <ReactTable title={"Shopify products"} columns={column} data={appDetails ? appDetails.tableRowsData : []} isLoading={isLoading} onDisplayDetailClick={handleShowDetails} onClick={() => db.AllShopifyProducts.clear()} />
      </Row>
    </Container>
  );
};

export default ShopifyProducts;
