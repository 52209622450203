import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useLazyGetRecordsQuery,
  useAddMutation,
} from "@/services/pcApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { APPLICATION_SCREEN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ApplicationScreenForm = ({
  parentData,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {
  const [applicationScreenDetails, setApplicationScreenDetails] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [addApplicationScreen, { isLoading: isApplicationScreenAddLoading }] =
    useAddMutation();

  const [
    getApplicationScreenRecords,
    {
      data: applicationScreenList,
      isError: isApplicationScreenError,
      isLoading: isApplicationScreenLoading,
      isFetching: isApplicationscreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getApplicationScreenRecords({
      entity: "CMS/ApplicationScreens",
      entities: { Screen: {}, Applicationssss: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      filter: `(ApplicationId eq ${parentData})`,
      sort: sort,
      tag: APPLICATION_SCREEN_TAG,
    });
    setApplicationScreenDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [parentData, pageSize, page, sort]);

  useEffect(() => {
    if (
      !isApplicationScreenLoading &&
      !isApplicationScreenError &&
      applicationScreenList &&
      !isApplicationscreenFetching
    ) {
      if (Object.keys(parentData)?.length > 0) {
        setApplicationScreenDetails({
          ...applicationScreenDetails,
          tableRowsData: applicationScreenList.value
            ? applicationScreenList.value
            : [],
          totalData:
            applicationScreenList && applicationScreenList["@odata.count"]
              ? applicationScreenList["@odata.count"]
              : 0,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    applicationScreenList,
    isApplicationScreenError,
    isApplicationScreenLoading,
    parentData,
    isApplicationscreenFetching,
  ]);

  const column = [
    {
      Header: "#",
      accessor: "Id",
    },
    {
      Header: "Screen",
      accessor: "Screen.Name",
    },
    {
      Header: "Application",
      accessor: "App.Name",
    },
    {
      Header: "Sort Order",
      accessor: "SortOrder",
      type: "Number",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const screenValidationSchema = Yup.object().shape({
    Screen: Yup.mixed().required("Please select screen."),
  });

  const onSubmit = (values) => {
    const applicationScreenDetail = {
      ApplicationId: parentData.Id,
      ScreenId: values.Screen.Id,
    };

    addApplicationScreen({
      entity: "CMS/ApplicationScreen",
      data: applicationScreenDetail,
      tag: APPLICATION_SCREEN_TAG,
    }).then((response) => {
      if (!response.error) {
        successToast("Application-screen added successfully.");
      }
    });
    setIsAddModalOpen({ ...isAddModalOpen, screen: false });
  };

  return (
    <>
      <Formik
        initialValues={{
          Application: parentData ? parentData : "",
          Screen: "",
        }}
        validationSchema={screenValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;

          return (
            <Form className="mt-2">
              <AsyncPaginates
                value={values.Application}
                label="Application"
                name="Application"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Application}
                fieldTouched={touched.Application}
                handleBlur={handleBlur}
                readonly={parentData && parentData}
              />
              <AsyncPaginates
                value={values.Screen}
                label="Screen"
                name="Screen"
                entity="CMS/Screens"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                required
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  type="reset"
                  onClick={() => {
                    handleSubmit() && formik.resetForm();
                  }}
                  color="success"
                >
                  {isApplicationScreenAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}{" "}
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {applicationScreenDetails && (
          <DataReactTable
            reactTableData={applicationScreenDetails}
            onAddClick={() => { }}
            title="Application Screen"
            headerContent={false}
            isFetching={isApplicationscreenFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
          />
        )}
      </div>
    </>
  );
};

ApplicationScreenForm.propTypes = {
  parentData: PropTypes.number.isRequired,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object,
};

export default ApplicationScreenForm;
