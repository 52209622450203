import React from "react";
import { Route } from "react-router-dom";
//Auth
import User from "@/components/Auth/User/User/User";
import UserType from "@/components/Auth/User/UserType/UserType";
import Role from "@/components/Auth/Role/Role/Role";
import RoleType from "@/components/Auth/Role/RoleType/RoleType";
import AuthMedia from "@/components/Auth/User/AuthMedia/AuthMedia";

const AuthRoutes = () => (
  <div>
    <Route path="/auth/user" component={User} />
    <Route path="/auth/user-type" component={UserType} />
    <Route path="/auth/media" component={AuthMedia} />
    <Route path="/auth/role" component={Role} />
    <Route path="/auth/role-type" component={RoleType} />
  </div>
);

export default AuthRoutes;
