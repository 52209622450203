import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { Button, Label, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import { RELATION_REQUEST_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import Modal from "@/shared/components/Modal";
import { useLazyGetRecordsQuery, useAddMutation } from "@/services/pcApi";

const SendInvitationForm = ({ setIsAddModalOpen }) => {
    const [sendReletionRequest, { isLoading: isRelationRequestLoading }] = useAddMutation();
    const [isLoadOptions, setisLoadOptions] = useState([]);
    const [isAlreadyRequested, setIsAlreadyRequested] = useState({
        model: false,
        message: ''
    });

    const entityValidationSchema = Yup.object().shape({
        Email: Yup.mixed().test('is-email', 'Invalid email address', function (val) {
            if (!val?.value) return true;
            return Yup.string().email().isValidSync(val?.value);
        }).required("Please select or enter user."),
        Relation: Yup.mixed().required("Please select any role."),
        Vendors: Yup.mixed().when(['Relation'], {
            is: (relationType) => {
                return relationType?.Name?.toLowerCase() === 'client'
            },
            then: Yup.mixed().required("Please select at least one vendor."),
            otherwise: Yup.mixed(),
        }),
    }, []);

    const [
        getAllUserRecords,
        {
            data: usersRequestList,
            isError: isUserRequestError,
            isLoading: isUserLoading,
            isFetching: isUserFetching,
        },
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getAllUserRecords({
            entity: "Auth/Users",
            query: "$select=Email"
        });
    }, []);


    useEffect(() => {
        if (!isUserLoading && !isUserRequestError && usersRequestList && !isUserFetching) {
            const a = usersRequestList?.value?.map((user) => {
                return {
                    value: user.Email,
                    label: user.Email,
                }
            })
            setisLoadOptions(a)
        }
    }, [usersRequestList, isUserRequestError, isUserFetching, isUserLoading]);

    const onSubmit = (values) => {
        const entityDetail = {
            Email: values?.Email?.value,
            RelationTypeId: values?.Relation?.Id,
            Vendors: values?.Vendors && values.Relation.Name.toLowerCase() === "client" ? encodeURIComponent(JSON.stringify(values?.Vendors)) : []
        };
        sendReletionRequest({
            entity: `Org/SendRelationInvitationToUser?email=${entityDetail?.Email}&relationId=${entityDetail?.RelationTypeId}&vendors=${entityDetail.Vendors}`,
            data: entityDetail,
            tag: RELATION_REQUEST_TAG,
        }).then((response) => {
            if (!response.error) {
                if (response?.data?.message) setIsAlreadyRequested({ status: true, data: response?.data?.message })
                else {
                    !isRelationRequestLoading && setIsAddModalOpen(false);
                    successToast("Entity added successfully.");
                }
            }
        });
    };

    return (
        <>
            <Formik
                initialValues={{
                    Email: "",
                    Relation: "",
                    Vendors: "",
                }}
                onSubmit={onSubmit}
                validationSchema={entityValidationSchema}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    } = formik;
                    return (
                        <Form>
                            <div className="mb-2">
                                <Label for="Email">
                                    Select Email
                                    <font color="red"> *</font>
                                </Label>
                                <CreatableSelect
                                    className={`lazySelect`}
                                    name="Email"
                                    value={values.Email}
                                    options={isLoadOptions}
                                    placueholder={`Select email`}
                                    onBlr={handleBlur}
                                    onChange={(e) => setFieldValue("Email", e)}
                                />
                                {touched.Email && errors.Email && <font color="red">{errors.Email}</font>}
                            </div>

                            <AsyncPaginates
                                value={values.Relation}
                                label="Relation"
                                name="Relation"
                                entity="OrgReference/Relations"
                                setFieldValue={setFieldValue}
                                fieldErrors={errors.Relation}
                                fieldTouched={touched.Relation}
                                handleBlur={handleBlur}
                                required
                            />
                            {values?.Relation?.Name?.toLowerCase() === "client" && <AsyncPaginates
                                value={values.Vendors}
                                label="Vendors"
                                name="Vendors"
                                entity="ShopifyReference/ShopifyVendors"
                                setFieldValue={setFieldValue}
                                fieldErrors={errors.Vendors}
                                fieldTouched={touched.Vendors}
                                handleBlur={handleBlur}
                                isMulti={true}
                                required
                            />}
                            <Button
                                disabled={isRelationRequestLoading}
                                onClick={handleSubmit}
                                className="modal_ok float-right"
                                color="success"
                            >
                                {(isRelationRequestLoading) && (
                                    <Spinner size="sm" variant="light" />
                                )}{" "}
                                Save
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
            {isAlreadyRequested?.status && (
                <Modal
                    color="warning"
                    title="Warning"
                    message={isAlreadyRequested?.data}
                    handleOkClick={() => {
                        setIsAlreadyRequested({ ...isAlreadyRequested, status: !isAlreadyRequested.status })
                        setIsAddModalOpen(false)
                    }}
                    toggle={() => {
                        setIsAlreadyRequested({ ...isAlreadyRequested, status: !isAlreadyRequested.status })
                        setIsAddModalOpen(false)
                    }}
                />
            )}
        </>
    );
};

SendInvitationForm.propTypes = {
    setIsAddModalOpen: PropTypes.func,
};

export default SendInvitationForm;
