import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        {Object.entries(tabs).map((item, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                disabled={item[1].props?.parentId === 0}
                className={classnames({
                  active: activeTab === (index + 1).toString(),
                })}
                onClick={() => toggle((index + 1).toString())}
              >
                {item[0]}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent
        activeTab={activeTab}
        className={classnames({
          disable: true,
        })}
      >
        {Object.entries(tabs).map((item, index) => {
          return (
            <TabPane tabId={(index + 1).toString()} key={index}>
              {item[1]}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default Tabs;
