import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useUpdateMutation } from "@/services/pcApi";
import { SHOPIFY_VENDORS_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ShopifyVendorsForm = ({
  editData,
  setIsAddModalOpen,
}) => {
  const [updateShopifyVendors, { isLoading: isShopifyVendorsUpdateLoading }] = useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(editData ? editData : null);

  const shopifyVendorsValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter App Name"),
    Commission: Yup.string().required("Please enter Commission"),
  });

  const onSubmit = (values) => {
    const shopifyVendorsDetail = {
      Name: values.Name,
      Description: values.Description,
      Commission: values.Commission,
    };

    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      shopifyVendorsDetail.Id = editRecordDetail.Id;
      updateShopifyVendors({
        entity: "ShopifyReference/Vendor",
        data: shopifyVendorsDetail,
        tag: SHOPIFY_VENDORS_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("ShopifyVendors updated successfully.");
          setIsAddModalOpen(false)
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editRecordDetail && editRecordDetail.Name ? editRecordDetail.Name : "",
        Description: editRecordDetail && editRecordDetail.Description ? editRecordDetail.Description : "",
        Commission: editRecordDetail && editRecordDetail.Commission ? editRecordDetail.Commission : "",
      }}
      onSubmit={onSubmit}
      validationSchema={shopifyVendorsValidationSchema}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly={editData && Object.entries(editData)?.length}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Commission"
              name="Commission"
              fieldErrors={errors.Commission}
              fieldTouched={touched.Commission}
              fieldValue={values.Commission}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <Button
              disabled={isShopifyVendorsUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {isShopifyVendorsUpdateLoading && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ShopifyVendorsForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
};

export default ShopifyVendorsForm;
