import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { SCREEN_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenMenuForm from "./ScreenMenuForm";
import { getAllScreenMenus } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";

const ScreenMenu = () => {
  const [screenMenuDetails, setScreenMenuDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteScreenMenu, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getScreenMenuRecords,
    {
      data: screenMenuList,
      isError: isScreenMenuError,
      isLoading: isScreenMenuLoading,
      isFetching: isScreenMenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Screen Name",
      accessor: "ScreenDTO.Name",
      filter: true,
    },
    {
      Header: "Menu Name",
      accessor: "MenuDTO.Name",
      filter: true,
    },
  ];

  const handleEditData = (screenMenuData) => {
    setSelectedRecord(screenMenuData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteScreenMenu({
      entity: "CMS/ScreenMenu",
      id: selectedRecord.Id,
      tag: [SCREEN_MENU_TAG, MENU_TAG],
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Screen-menu deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getScreenMenuRecords({
      entity: "CMS/ScreenMenus",
      entities: { Menu: {}, Screen: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: [SCREEN_MENU_TAG, MENU_TAG],
    });
    setScreenMenuDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isScreenMenuLoading &&
      !isScreenMenuError &&
      screenMenuList &&
      !isScreenMenuFetching
    ) {
      dispatch(getAllScreenMenus(screenMenuList.value));
      let screenMenuData = [...screenMenuList.value];
      setScreenMenuDetails({
        ...screenMenuDetails,
        tableRowsData: screenMenuList.value ? screenMenuData : [],
        totalData:
          screenMenuList && screenMenuList["@odata.count"]
            ? screenMenuList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isScreenMenuError,
    screenMenuList,
    isScreenMenuLoading,
    isScreenMenuFetching,
  ]);
  return (
    <>
      <Container>
        <Row>
          {screenMenuDetails && (
            <DataReactTable
              reactTableData={screenMenuDetails}
              onAddClick={() => {
                setIsAddModalOpen(true);
                setSelectedRecord();
              }}
              title="Screen Menu"
              isFetching={isScreenMenuFetching}
              setPageNumber={setPage}
              recordsPerPage={pageSize}
              setRecordsPerPage={setPageSize}
              setSort={setSort}
              setGlobalSearchKeyword={setGlobalSearchKeyword}
              setColumnFilter={setColumnFilter}
            />
          )}
        </Row>
        {isAddModalOpen && (
          <AddModal
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            color="success"
            title={`${selectedRecord ? "Edit" : "Add"} Screen Menu`}
            header
            btn="Success"
            message={
              <ScreenMenuForm
                editData={selectedRecord ? selectedRecord : {}}
                setIsAddModalOpen={setIsAddModalOpen}
              />
            }
          />
        )}
        {isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )}
      </Container>
    </>
  )
}

export default ScreenMenu
