import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Profile from '../../../Account/Profile/index';

const Account=() => (
  <Switch>
    <Route path="/account/profile" component={Profile} />
  </Switch>
);

export default Account;