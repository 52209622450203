import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import Modal from "@/shared/components/Modal";
import { successToast } from "@/shared/components/toast";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import { APPLICATION_SCREEN_TAG, SCREEN_CONTENT_TAG } from "@/shared/tagFile";
import AddModal from "@/shared/components/AddModal";
import Tabs from "@/shared/components/Tabs";
import ApplicationForm from "../../Application/Applications/ApplicationForm";
import ApplicationScreenForm from "../../Application/Applications/ApplicationScreenForm";
import ScreenContentForm from "./ScreenContentForm";

const Crosslinks = ({ displayData }) => {
  const { t } = useTranslation("common");
  const [deleteScreen, { isLoading: isDeleting }] = useDeleteMutation();
  const [applicationscreenDetails, setApplicationScreenDetails] = useState([]);
  const [screenContentDetails, setScreenContentDetails] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [isAddModalOpen, setIsAddModalOpen] = useState({
    applicationScreen: false,
    screenContent: false,
  });
  const history = useHistory();

  const [
    getApplicationScreenRecords,
    {
      data: applicationScreenData,
      isError: isApplicationScreenError,
      isFetching: isApplicationScreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  const [
    getScreenContentRecords,
    {
      data: screenContentData,
      isError: isScreenContentError,
      isFetching: isScreenContentFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (displayData) {
      getApplicationScreenRecords({
        entity: "CMS/ApplicationScreens",
        top: 3,
        filter: `ScreenId eq ${displayData?.id}`,
        entities: { Screen: {}, Application: { entities: { ApplicationType: {} } } },
        tag: APPLICATION_SCREEN_TAG,
      });
    }
    getScreenContentRecords({
      entity: "CMS/ScreenContents",
      top: 3,
      filter: `ScreenId eq ${displayData.id}`,
      entities: { Screen: {}, Content: {} },
      tag: SCREEN_CONTENT_TAG,
    });
    // eslint-disable-next-line
  }, [displayData]);

  useEffect(() => {
    if (!isApplicationScreenFetching && !isApplicationScreenError && applicationScreenData) {
      setApplicationScreenDetails(applicationScreenData.value);
    }
  }, [applicationScreenData, isApplicationScreenError, isApplicationScreenFetching,]);

  useEffect(() => {
    if (!isScreenContentFetching && !isScreenContentError && screenContentData) {
      setScreenContentDetails(screenContentData.value);
    }
  }, [screenContentData, isScreenContentError, isScreenContentFetching]);

  const onAddClick = (title) => {
    let formName = `${title.charAt(0).toLowerCase()}${title
      .slice(1)
      .replace(/\s|\(|[0-9]|\)/g, "")}`;
    setIsAddModalOpen({ ...isAddModalOpen, [formName]: true });
  };

  const applicationScreenTab = {
    "Add New": (
      <ApplicationForm
        parentId={displayData?.id}
        setIsAddModalOpen={setIsAddModalOpen}
        isAddModalOpen={isAddModalOpen}
      />
    ),
    "Use Current": (
      <ApplicationScreenForm
        parentId={displayData?.id}
        setIsAddModalOpen={setIsAddModalOpen}
        isAddModalOpen={isAddModalOpen}
      />
    ),
  };

  const handleDelete = () => {
    deleteScreen({
      entity: deleteRecord.entity,
      id: deleteRecord.Id,
      tag: deleteRecord.tag,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(`${deleteRecord.entity} deleted successfully.`);
      }
    });
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("gamecodex.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <CrosslinkPanel
                title={`Application Screen (${applicationScreenData && applicationScreenData["@odata.count"]
                  ? applicationScreenData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  applicationscreenDetails &&
                    applicationscreenDetails.length > 0 ? (
                    <div>
                      {applicationscreenDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMSReference/ApplicationScreen`,
                                    tag: APPLICATION_SCREEN_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Name: </b>
                                  {value.ApplicationDTO?.Name}
                                </div>
                                <div>
                                  <b>Discription: </b>
                                  {value.ApplicationDTO?.Description}
                                </div>
                                <div>
                                  <b>Application Type: </b>
                                  {value.ApplicationDTO?.ApplicationTypeDTO?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          className="disabledCursor"
                          onClick={(event) => {
                            history.push("/cms/application-screen");
                          }}
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="success"
              ></CrosslinkPanel>
              <CrosslinkPanel
                title={`Screen Content (${screenContentData && screenContentData["@odata.count"]
                  ? screenContentData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  screenContentDetails && screenContentDetails?.length > 0 ? (
                    <div>
                      {screenContentDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMS/ScreenContent`,
                                    tag: SCREEN_CONTENT_TAG,
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Content Name: </b>
                                  {value.ContentDTO?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          className="disabledCursor"
                          onClick={(event) => {
                            history.push("/cms/screen-content");
                          }}
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="danger"
              ></CrosslinkPanel>
            </div>
          </CardBody>
        </Card>
      </Col>
      {
        isAddModalOpen.applicationScreen && (
          <AddModal
            toggle={() =>
              setIsAddModalOpen({ ...isAddModalOpen, applicationScreen: false })
            }
            color="success"
            title={`Add Application`}
            header
            btn="Success"
            xl
            message={
              <Tabs
                tabs={applicationScreenTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            }
          />
        )
      }
      {
        isAddModalOpen.screenContent && screenContentDetails?.length && (
          <AddModal
            toggle={() =>
              setIsAddModalOpen({ ...isAddModalOpen, screenContent: false })
            }
            color="success"
            title={`Add Screen Content`}
            header
            btn="Success"
            xl
            message={
              <ScreenContentForm
                displayData={screenContentDetails[0]}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
              />
            }
          />
        )
      }
      {
        isDeleteModalOpen && (
          <Modal
            color="danger"
            title="Delete"
            message={`Are you sure you want to delete?`}
            handleOkClick={() => handleDelete()}
            toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
            isDeleting={isDeleting}
          />
        )
      }
    </>
  );
};

export default Crosslinks;
