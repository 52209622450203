import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { SCREEN_TAG } from "@/shared/tagFile";
import Tabs from "@/shared/components/Tabs";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ScreenForm from "./ScreenForm";
import { getAllScreen } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";
import SEOForm from "./SEOForm";

const Screen = () => {
  const [screenDetails, setScreenDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [screenValues, setScreenValues] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [deleteScreen, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    getScreenRecords,
    {
      data: screenList,
      isError: isScreenError,
      isLoading: isScreenLoading,
      isFetching: isScreenFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Path",
      accessor: "Path",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Screen Type",
      accessor: "ScreenTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Meta Title",
      accessor: "MetaTitle",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Description",
      accessor: "MetaDescription",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Viewport",
      accessor: "MetaViewport",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Meta Charset",
      accessor: "MetaCharset",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "IsIndex",
      accessor: "IsIndex",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: "IsFollow",
      accessor: "IsFollow",
      type: "Boolean",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
  ];

  const handleShowDetails = (screenData) => {
    history.push({
      pathname: "/cms/screen-detail",
      state: {
        screenId: screenData.Id,
      },
    });
  };

  const handleEditData = (screenData) => {
    setSelectedRecord(screenData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteScreen({
      entity: "CMS/Screen",
      id: selectedRecord.Id,
      tag: SCREEN_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Screen deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getScreenRecords({
      entity: "CMS/Screens",
      entities: { ScreenType: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SCREEN_TAG,
    });
    setScreenDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!isScreenLoading && !isScreenError && screenList && !isScreenFetching) {
      dispatch(getAllScreen(screenList.value));
      let screenData = [...screenList.value];
      setScreenDetails({
        ...screenDetails,
        tableRowsData: screenList.value ? screenData : [],
        totalData:
          screenList && screenList["@odata.count"]
            ? screenList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
        onDisplayDetailClick: (data) => handleShowDetails(data),
      });
    }
    // eslint-disable-next-line
  }, [isScreenError, screenList, isScreenLoading, isScreenFetching]);

  const tabs = {
    Screen: (
      <ScreenForm
        editData={selectedRecord ? selectedRecord : {}}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setScreenValues={setScreenValues}
      />
    ),
    SEO: (
      <SEOForm
        value={screenValues && screenValues}
        editData={selectedRecord ? selectedRecord : {}}
        setActiveTab={setActiveTab}
        setIsAddModalOpen={setIsAddModalOpen}
        isAddModalOpen={isAddModalOpen}
      />),
  };

  const toggle = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setActiveTab("1");
    setSelectedRecord();
  };

  return (
    <Container>
      <Row>
        {screenDetails && (
          <DataReactTable
            reactTableData={screenDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Screen"
            isFetching={isScreenFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={toggle}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Screen`}
          header
          btn="Success"
          message={
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default Screen;
