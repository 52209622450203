import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { MEDIA_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import MediaForm from "./MediaForm";
import { getAllMedia } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";

const CmsMedia = () => {
  const [cmsMediaDetails, setCmsMediaDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteCmsMedia, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getCmsMediaRecords,
    {
      data: cmsMediaList,
      isError: isCmsMediaError,
      isLoading: isCmsMediaLoading,
      isFetching: isCmsMediaFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Media Name",
      accessor: "Name",
      filter: true,
      Cell: (val) => {
        return (
          <a
            href={val.row.original?.URL}
            target="_blank"
            rel="noreferrer"
          >
            {val.value}
          </a>
        );
      },
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Media Type",
      accessor: "MediaTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Media URL",
      accessor: "URL",
      filter: true,
      Cell: ({ value }) => {
        const [isCollapsed, setIsCollapsed] = useState(true);

        const toggleCollapse = () => {
          setIsCollapsed(!isCollapsed);
        };
        return (
          <div style={{ pointerEvents: 'auto' }}>
            {isCollapsed ? (
              <div onClick={toggleCollapse} >
                {value.slice(0, 50)}
                {value.length > 50 && '...'}
              </div >
            ) : (
              <div onClick={toggleCollapse}>{value}</div>
            )}
          </div >
        );
      }
    },
  ];

  // const handleEditData = (cmsMediaData) => {
  //   setSelectedRecord(cmsMediaData);
  //   setIsAddModalOpen(true);
  // };

  const handleDelete = () => {
    deleteCmsMedia({
      entity: "CMS/CMSMedia",
      id: selectedRecord.Id,
      tag: MEDIA_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Media deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getCmsMediaRecords({
      entity: "CMS/CMSMedias",
      entities: { MediaType: {} },
      expand: {},
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: MEDIA_TAG,
    });
    setCmsMediaDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isCmsMediaLoading &&
      !isCmsMediaError &&
      cmsMediaList &&
      !isCmsMediaFetching
    ) {
      dispatch(getAllMedia(cmsMediaList.value));
      let cmsMediaData = [...cmsMediaList.value];
      setCmsMediaDetails({
        ...cmsMediaDetails,
        tableRowsData: cmsMediaList.value ? cmsMediaData : [],
        totalData:
          cmsMediaList && cmsMediaList["@odata.count"]
            ? cmsMediaList["@odata.count"]
            : 0,
        // onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isCmsMediaError,
    cmsMediaList,
    isCmsMediaLoading,
    isCmsMediaFetching,
  ]);
  return (
    <Container>
      <Row>
        {cmsMediaDetails && (
          <DataReactTable
            reactTableData={cmsMediaDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Media"
            isFetching={isCmsMediaFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Media`}
          header
          btn="Success"
          message={
            <MediaForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default CmsMedia;
