import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { APPLICATION_SCREEN_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const AppScreenForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addApplicationScreen, { isLoading: isApplicationScreenAddLoading }] =
    useAddMutation();
  const [
    updateApplicationScreen,
    { isLoading: isApplicationScreenUpdateLoading },
  ] = useUpdateMutation();

  const screenValidationSchema = Yup.object().shape({
    Application: Yup.mixed().required("Please select app."),
    Screen: Yup.mixed().required("Please select screen."),
  });

  const onSubmit = (values) => {
    const appScreenDetail = {
      ApplicationId: values.Application.Id,
      ScreenId: values.Screen.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      appScreenDetail.Id = editData.Id;
      updateApplicationScreen({
        entity: "CMS/ApplicationScreen",
        data: appScreenDetail,
        tag: APPLICATION_SCREEN_TAG,
      }).then((response) => {
        if (!response.error) {
          !isApplicationScreenUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Application-screen updated successfully.");
        }
      });
    } else {
      addApplicationScreen({
        entity: "CMS/ApplicationScreen",
        data: appScreenDetail,
        tag: APPLICATION_SCREEN_TAG,
      }).then((response) => {
        if (!response.error) {
          !isApplicationScreenAddLoading && setIsAddModalOpen(false);
          successToast("Application-screen added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Application: editData && editData.ApplicationDTO ? editData.ApplicationDTO : "",
        Screen: editData && editData.ScreenDTO ? editData.ScreenDTO : "",
      }}
      validationSchema={screenValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.Application}
              label="Application"
              name="Application"
              entity="CMS/Applications"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Application}
              fieldTouched={touched.Application}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Application}
              required
            />
            <AsyncPaginates
              value={values.Screen}
              label="Screen"
              name="Screen"
              entity="CMS/Screens"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Screen}
              fieldTouched={touched.Screen}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isApplicationScreenAddLoading ||
                isApplicationScreenUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isApplicationScreenAddLoading ||
                isApplicationScreenUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

AppScreenForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default AppScreenForm;
