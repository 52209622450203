import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { useSelector } from "react-redux";

const ProfileMain = () => {

  const user = useSelector((state) => state.user);
  
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={user.picture || user.avatar} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{user.name}</p>
              <p className="profile__work">{user.role}</p>
              <p className="profile__contact">{user.email}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
