import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer
} from '@/redux/reducers/index';
import appConfigReducer from '@/redux/reducers/appConfigReducer';
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { pcApi } from '@/services/pcApi'
import authorizationReducer from "@/components/Auth/AuthSlice";
import cmsReducer from "../../components/CMS/CMSSlice";
import orgReducer from "../../components/Org/OrgSlice";
import ShopifyReducer from "../../components/Shopify/ShopifySlice";

export const store = configureStore({
  reducer: {
    [pcApi.reducerPath]: pcApi.reducer,
    theme: themeReducer,
    rtl: rtlReducer,
    roundBorders: roundBordersReducer,
    blocksShadows: blocksShadowsReducer,
    appConfig: appConfigReducer,
    customizer: customizerReducer,
    sidebar: sidebarReducer,
    user: authReducer,
    auth: authorizationReducer,
    cmsData: cmsReducer,
    orgData: orgReducer,
    shopifyData: ShopifyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pcApi.middleware),
})

setupListeners(store.dispatch)

export default store