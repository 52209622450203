import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Label, Spinner, Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import moment from "moment";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { SUPLIMENTALSALES_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "@/containers/Dexie Database/db";

const SupplementalSalesForm = ({
  editData,
  setIsAddModalOpen,
}) => {

  const [addSupplementalSales, { isLoading: isSupplementalSalesAddLoading }] = useAddMutation();
  const [updateSupplementalSales, { isLoading: isSupplementalSalesUpdateLoading }] = useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(editData ? editData : null);
  const [isProductOption, setIsProductOption] = useState([])

  const supplementalSalesValidationSchema = Yup.object().shape({
    Product: Yup.mixed().required("Please select product."),
    Price: Yup.number().required("Please enter a price").min(1, "The value must be positive"),
    Discount: Yup.number().min(0, "The value must be positive"),
    ReturnDiscount: Yup.number().min(0, "The value must be positive")
      .test('maxValue', 'Return discount cannot exceed Price * Return Quantity', function (value) {
        const { Price, ReturnQuantity } = this.parent;
        const maxReturnDiscount = Price * ReturnQuantity;
        return value <= maxReturnDiscount;
      }),
    Quantity: Yup.number().min(0, "The value must be positive").test(
      "oneOfRequired",
      "Please fill at least one quantity.",
      function (value) {
        return value || this.parent.ReturnQuantity;
      }
    ),
    ReturnQuantity: Yup.number().min(0, "The value must be positive").test(
      "oneOfRequired",
      "Please fill at least one quantity.",
      function (value) {
        return value || this.parent.Quantity;
      }
    ),
    CreatedAt: Yup.date().required("Please select date."),
  });

  const onSubmit = (values) => {
    const SupplementalSalesDetail = {
      ShopifyProductTitle: values.Product.title,
      Vendor: values.Product.vendor,
      ShopifyProductId: values.Product.id,
      ShopifySKU: values.Variant.sku,
      ShopifyVariantId: values.Variant.varinatId,
      Quantity: values.Quantity || 0,
      CreatedAt: values.CreatedAt,
      Price: values.Price || 0,
      Discount: values.Discount || 0,
      ReturnQuantity: values.ReturnQuantity || 0,
      ReturnDiscount: values.ReturnDiscount || 0,
    };

    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      SupplementalSalesDetail.Id = editRecordDetail.Id;
      updateSupplementalSales({
        entity: "ShopifyReference/SupplementalEntry",
        data: SupplementalSalesDetail,
        tag: SUPLIMENTALSALES_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Supplemental Sales updated successfully.");
          setIsAddModalOpen(false)
        }
      });
    } else {
      addSupplementalSales({
        entity: "ShopifyReference/SupplementalEntry",
        data: SupplementalSalesDetail,
        tag: SUPLIMENTALSALES_TAG,
      }).then((response) => {
        if (!response.error) {
          successToast("Supplemental Sales added successfully.");
          setIsAddModalOpen(false)
        }
      });
    }
  };

  const products = useLiveQuery(
    async () => {
      const AllShopifyProducts = await db.AllShopifyProducts?.toArray();
      return AllShopifyProducts;
    }
  );

  useEffect(() => {
    let newArray = []
    if (products?.length) {
      products.forEach(item => {
        const existingProduct = newArray.find(product => product.id === item.productId);

        if (existingProduct) {
          existingProduct.variants.push({
            id: item.id,
            productId: item.productId,
            title: item.variantTitle,
            varinatId: item.varinatId,
            price: parseFloat(item.variantPrice),
            sku: item.variantSku,
            inventoryItemId: item.inventoryItemId,
            inventoryQuantity: item.inventoryQuantity,
            inventoryManagement: item.inventoryTrackable ? 'shopify' : 'other',
          });
        } else {
          newArray.push({
            id: item.productId,
            images: item.productImage,
            title: item.productTitle,
            vendor: item.productVendor,
            status: item.productStatus,
            variants: [{
              id: item.id,
              productId: item.productId,
              title: item.variantTitle,
              varinatId: item.varinatId,
              price: parseFloat(item.variantPrice),
              sku: item.variantSku,
              inventoryItemId: item.inventoryItemId,
              inventoryQuantity: item.inventoryQuantity,
              inventoryManagement: item.inventoryTrackable ? 'shopify' : 'other',
            }],
          });
        }
      });
    }
    setIsProductOption(newArray)
  }, [products])

  return (
    <Formik
      initialValues={{
        Product: editRecordDetail && editRecordDetail?.ShopifyProductTitle ? isProductOption.find(product => product.id === editRecordDetail?.ShopifyProductId) : "",
        Variant: editRecordDetail && editRecordDetail?.ShopifySKU ?
          isProductOption.find(product => product.id === editRecordDetail?.ShopifyProductId)?.variants?.find(variants => variants.varinatId === editRecordDetail.ShopifyVariantId)
          : "",
        Vendor: editRecordDetail && editRecordDetail?.Vendor ? editRecordDetail?.Vendor : "",
        CreatedAt: editRecordDetail && editRecordDetail.CreatedAt ? editRecordDetail.CreatedAt : "",
        Quantity: editRecordDetail && editRecordDetail?.Quantity ? editRecordDetail?.Quantity : 0,
        Price: editRecordDetail && editRecordDetail?.Price ? editRecordDetail?.Price : 0,
        Discount: editRecordDetail && editRecordDetail?.Discount ? editRecordDetail?.Discount : 0,
        ReturnQuantity: editRecordDetail && editRecordDetail?.ReturnQuantity ? editRecordDetail?.ReturnQuantity : 0,
        ReturnDiscount: editRecordDetail && editRecordDetail?.ReturnDiscount ? editRecordDetail?.ReturnDiscount : 0,
      }}
      onSubmit={onSubmit}
      validationSchema={supplementalSalesValidationSchema}
      enableReinitialize
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="Product">
                    Product <font color="red"> *</font>
                  </Label>
                  <Select
                    className={`lazySelect ${editData && editData?.ShopifyProductId && "disabled"}`}
                    name="Product"
                    value={values.Product}
                    options={isProductOption}
                    getOptionLabel={(option) => {
                      return option.title
                    }}
                    getOptionValue={(option) => {
                      return option.title
                    }}
                    placeholder={`Select Product`}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("Product", e)
                      setFieldValue("Variant", e.variants?.[0])
                    }}
                    isDisabled={editData && editData?.ShopifyProductId ? true : false}
                  />
                  {touched.Product && errors.Product && (
                    <font color="red">{errors.Product}</font>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="Variant">
                    Variant
                  </Label>
                  <Select
                    className={`lazySelect ${editData && editData?.ShopifyProductId && "disabled"}`}
                    name="Variant"
                    value={values.Variant}
                    options={values?.Product?.variants ?? []}
                    getOptionLabel={(option) => { return option.sku }}
                    getOptionValue={(option) => { return option.sku }}
                    placeholder={`Select Variant`}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("Variant", e)}
                    isDisabled={editData?.ShopifyProductId || !values.Product}
                  />
                </div>
              </Col>
              <Col md={6}>
                <CustomInput label="Vendor" name="Vendor" fieldValue={values?.Product?.vendor} readonly />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Quantity"
                  name="Quantity"
                  type="number"
                  fieldErrors={errors.Quantity}
                  fieldTouched={touched.Quantity}
                  fieldValue={values.Quantity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={!values.ReturnQuantity}
                  min={0}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Price"
                  name="Price"
                  type="number"
                  fieldErrors={errors.Price}
                  fieldTouched={touched.Price}
                  fieldValue={values.Price}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  min={0}
                  required
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Return Quantity"
                  name="ReturnQuantity"
                  type="number"
                  fieldErrors={errors.ReturnQuantity}
                  fieldTouched={touched.ReturnQuantity}
                  fieldValue={values.ReturnQuantity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={!values.Quantity}
                  min={0}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Return Discount"
                  name="ReturnDiscount"
                  type="number"
                  fieldErrors={errors.ReturnDiscount}
                  fieldTouched={touched.ReturnDiscount}
                  fieldValue={values.ReturnDiscount}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  readonly={!values.ReturnQuantity}
                  min={0}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Discount"
                  name="Discount"
                  type="number"
                  fieldErrors={errors.Discount}
                  fieldTouched={touched.Discount}
                  fieldValue={values.Discount}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  min={0}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Date"
                  name="CreatedAt"
                  type="date"
                  fieldErrors={errors.CreatedAt}
                  fieldTouched={touched.CreatedAt}
                  fieldValue={new Date(values.CreatedAt).toISOString().substring(0, 10)}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  min={0}
                  required
                />
              </Col>
            </Row>
            <Button
              disabled={isSupplementalSalesAddLoading || isSupplementalSalesUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isSupplementalSalesAddLoading ||
                isSupplementalSalesUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SupplementalSalesForm.propTypes = {
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  parentId: PropTypes.number,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object || PropTypes.bool,
  setCurrentSupplementalSalesId: PropTypes.func,
};

export default SupplementalSalesForm;
