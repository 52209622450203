import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import DownIcon from "mdi-react/ChevronDownIcon";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import Loading from "@/shared/components/Loading";
import TopbarNavLink from "./TopbarNavLink";
import TopbarNavCategory from "./TopbarNavCategory";

const TopbarNavAdmin = () => {
  const role = useSelector((state) => state.user.role);
  const [displayComponent, setDisplayComponent] = useState({
    component: <Loading loading={true} />,
  });

  useEffect(() => {
    setTimeout(() => {
      setDisplayComponent({ component: <Redirect to="/" /> });
    }, 10000);
  }, []);
  if (
    role &&
    role.length &&
    !(role.includes(ADMIN) || role.includes(SUPER_ADMIN)) &&
    role.includes(USER)
  ) {
    return <Redirect to="/" />;
  }

  return role &&
    role.length > 0 &&
    (role.includes(ADMIN) || role.includes(SUPER_ADMIN)) ? (
    <UncontrolledDropdown className="topbar__nav-dropdown">
      <DropdownToggle className="topbar__nav-dropdown-toggle">
        Data Admin <DownIcon />
      </DropdownToggle>
      <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
        <DropdownItem>
          <TopbarNavLink
            title="Overview"
            icon="envelope"
            route="/admin_dashboard"
          />
        </DropdownItem>
        {role.includes(SUPER_ADMIN) && (<>
          <DropdownItem>
            <TopbarNavCategory title="Auth" icon="user">
              <TopbarNavCategory title="Role" icon="user">
                <TopbarNavLink title="Role" route="/auth/role" />
                <TopbarNavLink title="Role Type" route="/auth/role-type" />
              </TopbarNavCategory>
              <TopbarNavCategory title="User" icon="user">
                <TopbarNavLink title="User" route="/auth/user" />
                <TopbarNavLink title="User Type" route="/auth/user-type" />
              </TopbarNavCategory>
              <TopbarNavLink title="Auth Media" route="/auth/media" />
            </TopbarNavCategory>

            <TopbarNavCategory title="Org" icon="user" isNew>
              <TopbarNavCategory title="Entities" icon="user" isNew>
                <TopbarNavLink title="Entity" route="/org/entity" />
                <TopbarNavLink title="Entity Type" route="/org/entity-type" />
                <TopbarNavLink
                  title="Entity Relation"
                  route="/org/entity-relation"
                />
                <TopbarNavLink
                  title="Relation"
                  route="/org/relation"
                />
              </TopbarNavCategory>
            </TopbarNavCategory>
          </DropdownItem>
        </>)}
        <DropdownItem>
          <TopbarNavCategory title="CMS" icon="user" isNew>
            <TopbarNavCategory title="Applications" icon="user" isNew>
              <TopbarNavLink title="Application" route="/cms/application" />
              <TopbarNavLink
                title="Application Type"
                route="/cms/application-type"
              />
            </TopbarNavCategory>

            <TopbarNavCategory title="Screens" icon="user" isNew>
              <TopbarNavLink title="Screen" route="/cms/screen" />
              <TopbarNavLink title="Screen Type" route="/org/screen-type" />
              <TopbarNavLink
                title="Screen Content"
                route="/cms/screen-content"
              />
              <TopbarNavLink
                title="Application Screen"
                route="/cms/application-screen"
              />
              {/* <TopbarNavLink title="Screen Zone" route="/cms/screen-zone" />
              <TopbarNavLink
                title="Screen Content"
                route="/cms/screen-content"
              /> */}
            </TopbarNavCategory>

            <TopbarNavCategory title="Component" icon="user" isNew>
              <TopbarNavLink
                title="Component"
                route="/cms/component"
                icon="user"
              />
            </TopbarNavCategory>

            <TopbarNavCategory title="Contents" icon="user" isNew>
              <TopbarNavLink title="Content" route="/cms/content" />
              <TopbarNavLink title="Content Type" route="/org/content-type" />
              {/*<TopbarNavLink
              title="Content Location"
              route="/cms/content-location"
            />
             <TopbarNavLink title="Content Media" route="/org/content-media" />
            <TopbarNavLink title="Content Menu" route="/org/content-menu" /> */}
            </TopbarNavCategory>

            <TopbarNavCategory title="Media" icon="user">
              <TopbarNavLink title="Media" route="/cms/media" />
              <TopbarNavLink title="Media Type" route="/cms/media-type" />
            </TopbarNavCategory>

            <TopbarNavCategory title="Menu" icon="user">
              <TopbarNavLink title="Menu" route="/cms/menu" />
              <TopbarNavLink title="Menu Type" route="/cms/menu-type" />
              <TopbarNavLink title="Application Menu" route="/cms/application-menu" />
              <TopbarNavLink title="Screen Menu" route="/cms/screen-menu" />
            </TopbarNavCategory>

            <TopbarNavCategory title="Component" icon="user" isNew>
              <TopbarNavLink
                title="App Version"
                route="/cms/app-version"
                icon="user"
              />
            </TopbarNavCategory>
            <TopbarNavCategory title="Component" icon="user" isNew>
              <TopbarNavLink
                title="Shopify Vednors"
                route="/shopify/vendors"
                icon="user"
              />
              <TopbarNavLink
                title="Shopify Products"
                route="/shopify/products"
                icon="user"
              />
              <TopbarNavLink
                title="Supplemental Sales"
                route="shopify/supplemental-sales"
                icon="user"
              />
            </TopbarNavCategory>

          </TopbarNavCategory>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown >
  ) : (
    displayComponent.component
  )
};

export default TopbarNavAdmin;
