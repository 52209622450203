import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { SCREEN_CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenContentForm = ({ editData, setIsAddModalOpen, parentData }) => {
  const [addScreenContent, { isLoading: isScreenContentAddLoading }] =
    useAddMutation();
  const [updateScreenContent, { isLoading: isScreenContentUpdateLoading }] =
    useUpdateMutation();

  const screenContentValidationSchema = Yup.object().shape({
    Screen: Yup.mixed().required("Please select screen."),
    Content: Yup.mixed().required("Please select content."),
    Component: Yup.mixed().required("Please select component."),
  });

  const onSubmit = (values) => {
    const screenContentDetail = {
      ScreenId: values.Screen.Id,
      ContentId: values.Content.Id,
      ComponentId: values.Component.Id,
    };
    if (editData && Object.entries(editData).length > 0) {
      screenContentDetail.Id = editData.Id;
      updateScreenContent({
        entity: "CMS/ScreenContent",
        data: screenContentDetail,
        tag: SCREEN_CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenContentAddLoading && setIsAddModalOpen(false);
          successToast("Screen-content updated successfully.");
        }
      });
    } else {
      addScreenContent({
        entity: "CMS/ScreenContent",
        data: screenContentDetail,
        tag: SCREEN_CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenContentAddLoading && setIsAddModalOpen(false);
          successToast("Screen-content added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Screen:
          editData && editData.Screen
            ? editData.Screen
            : parentData && parentData.Screen
              ? parentData.Screen
              : "",
        Content:
          editData && editData.Content
            ? editData.Content
            : parentData && parentData.Content
              ? parentData.Content
              : "",
        Component:
          editData && editData.Component
            ? editData.Component
            : parentData && parentData.Component
              ? parentData.Component
              : "",
      }}
      validationSchema={screenContentValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.Screen}
              label="Screen"
              name="Screen"
              entity="CMS/Screens"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Screen}
              fieldTouched={touched.Screen}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Screen}
              required
            />
            <AsyncPaginates
              value={values.Content}
              label="Content"
              name="Content"
              entity="CMS/Contents"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Content}
              fieldTouched={touched.Content}
              handleBlur={handleBlur}
              readonly={parentData && parentData.Content}
              required
            />
            <AsyncPaginates
              value={values.Component}
              label="Component"
              name="Component"
              entity="CMSReference/Components"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Component}
              fieldTouched={touched.Component}
              handleBlur={handleBlur}
              required
            />
            <Button
              disabled={
                isScreenContentAddLoading || isScreenContentUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenContentAddLoading || isScreenContentUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenContentForm.propTypes = {
  parentData: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
};

export default ScreenContentForm;
