import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  CustomizerProps,
  SidebarProps,
  ThemeProps,
  RTLProps,
  BlocksShadowsProps,
  RoundBordersProps,
} from "@/shared/prop-types/ReducerProps";
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from "@/redux/actions/sidebarActions";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "@/redux/actions/themeActions";
import {
  changeDirectionToRTL,
  changeDirectionToLTR,
} from "@/redux/actions/rtlActions";
import { toggleTopNavigation } from "@/redux/actions/customizerActions";
import {
  changeRoundBordersToOnAction,
  changeRoundBordersToOffAction,
} from "@/redux/actions/roundBordersActions";
import {
  changeBlocksShadowsToOnAction,
  changeBlocksShadowsToOffAction,
} from "@/redux/actions/blocksShadowsActions";
import { USER } from "@/shared/userRoles";
import { USER_TAG } from "@/shared/tagFile";
import { useAddMutation } from "@/services/pcApi";
import Topbar from "./topbar/Topbar";
import TopbarWithNavigation from "./topbar_with_navigation/TopbarWithNavigation";
import Sidebar from "./components/sidebar/Sidebar";
import Customizer from "./customizer/Customizer";

import { useAuth0 } from "@auth0/auth0-react";
import { auth } from "@/redux/actions/authActions";
import { useLazyGetSimpleRecordsQuery } from "../../services/pcApi";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../Dexie Database/db";
import { SHOPIFY_PRODUCT_TAG } from "../../shared/tagFile";

const Layout = ({
  dispatch,
  customizer,
  sidebar,
  theme,
  rtl,
  roundBorders,
  blocksShadows,
}) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [addUser] = useAddMutation();
  useEffect(() => {
    if (isAuthenticated) {
      user.role = [USER];
      Object.entries(user).forEach(([key, value]) => {
        if (key.includes("roles") && value.length > 0) {
          user.role = value;
        }
      });
      const userDetail = {
        Name: user.name,
        Description: user.Description ? user.Description : "",
        Email: user.email,
        SsoIdentifier: user.sub,
      };
      const getToken = async () => {
        var accessToken = "";
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        localStorage.setItem("accessToken", accessToken);
      };
      getToken();
      addUser({
        entity: "Auth/CreateUser",
        data: userDetail,
        tag: USER_TAG,
      }).then((response) => {
        dispatch(
          auth({ ...user, isAuthenticate: true, UserId: response.data.id })
        );
      });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  const changeToRTL = () => {
    dispatch(changeDirectionToRTL());
  };

  const changeToLTR = () => {
    dispatch(changeDirectionToLTR());
  };

  const topNavigation = () => {
    dispatch(toggleTopNavigation());
  };

  const changeRoundBordersOn = () => {
    dispatch(changeRoundBordersToOnAction());
  };

  const changeRoundBordersOff = () => {
    dispatch(changeRoundBordersToOffAction());
  };

  const changeBlocksShadowsOn = () => {
    dispatch(changeBlocksShadowsToOnAction());
  };

  const changeBlocksShadowsOff = () => {
    dispatch(changeBlocksShadowsToOffAction());
  };

  const layoutClass = classNames({
    layout: true,
    "layout--collapse": sidebar.collapse,
    "layout--top-navigation": customizer.topNavigation,
  });


  const [getAllShopifyProductRecords] = useLazyGetSimpleRecordsQuery();
  const [allProducts, setAllProducts] = useState([]);
  const [pageInfo, setPageInfo] = useState();
  const [firstProductData, setFirstProductData] = useState([])
  const [isProductsLength, setIsProductsLength] = useState(false);

  const products = useLiveQuery(
    async () => {
      const AllShopifyProducts = await db.AllShopifyProducts.toArray();
      return AllShopifyProducts;
    }
  );

  const fetchProducts = (entity) => {
    return getAllShopifyProductRecords({
      entity: entity,
      tag: SHOPIFY_PRODUCT_TAG,
    });
  };

  useEffect(() => {
    if (products?.length === 0 && !isProductsLength) {
      setFirstProductData([])
      setAllProducts([])
      const fetchData = () => {
        let entity = `Shopify/GetProductDetailFromShopify`
        fetchProducts(entity).then(({ data }) => {
          setFirstProductData(data?.items);
          setPageInfo(data);
        })
      };
      fetchData();
    }
  }, [products]);

  useEffect(() => {
    const fetchNextPage = () => {
      let entity = `Shopify/GetProductDetailFromShopify?pageInfo=${pageInfo?.linkHeader?.nextLink.pageInfo}`
      fetchProducts(entity)
        .then(({ data }) => {
          setAllProducts([...allProducts, ...data.items]);
          setPageInfo(data);
        });
    };

    if (pageInfo && pageInfo?.hasNextPage) {
      fetchNextPage();
    } else if (pageInfo && !pageInfo?.hasNextPage) {
      setIsProductsLength(true)
    }
  }, [pageInfo]);

  useEffect(() => {
    if (pageInfo && !pageInfo?.hasNextPage) {
      setAllProducts([...firstProductData, ...allProducts])
      let newAllProduct = [...firstProductData, ...allProducts]
      let time = new Date()
      db.AllShopifyProducts.clear();
      newAllProduct?.forEach((item, index) => {
        item.variants.forEach(variant => {
          db.AllShopifyProducts.add({
            Id: index + 1,
            varinatId: variant?.id,
            productImage: item?.images?.length ? item.images[0]?.src : '',
            productId: variant?.productId,
            productTitle: item?.title,
            productVendor: item?.vendor,
            productStatus: item?.status,
            inventoryItemId: variant?.inventoryItemId,
            variantTitle: variant?.title,
            variantPrice: variant?.price?.toString(),
            variantSku: variant?.sku,
            inventoryQuantity: variant?.inventoryQuantity,
            inventoryTrackable: variant?.inventoryManagement == "shopify" ? true : false,
            barcode: variant?.barcode ?? 0,
            lastSynced: time,
          })
        })
      })
    }
  }, [pageInfo])

  return (
    <div className={layoutClass}>
      <Customizer
        customizer={customizer}
        sidebar={sidebar}
        theme={theme}
        rtl={rtl}
        roundBorders={roundBorders}
        blocksShadows={blocksShadows}
        changeSidebarVisibility={sidebarVisibility}
        toggleTopNavigation={topNavigation}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeToRTL={changeToRTL}
        changeToLTR={changeToLTR}
        changeRoundBordersOn={changeRoundBordersOn}
        changeRoundBordersOff={changeRoundBordersOff}
        changeBlocksShadowsOn={changeBlocksShadowsOn}
        changeBlocksShadowsOff={changeBlocksShadowsOff}
      />
      {customizer.topNavigation ? (
        <TopbarWithNavigation
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      ) : (
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
      )}
      <Sidebar
        sidebar={sidebar}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        topNavigation={customizer.topNavigation}
      />
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  roundBorders: RoundBordersProps.isRequired,
  blocksShadows: BlocksShadowsProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
    rtl: state.rtl,
    roundBorders: state.roundBorders,
    blocksShadows: state.blocksShadows,
  }))(Layout)
);
