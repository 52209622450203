import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useLazyGetRecordsQuery, useAddMutation } from "@/services/pcApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { SCREEN_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenMenuForm = ({
  parentId,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {
  const [screenMenuDetails, setScreenMenuDetails] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [addScreenMenu, { isLoading: isScreenMenuAddLoading }] =
    useAddMutation();

  const [
    getScreenMenuRecords,
    {
      data: screenMenuList,
      isError: isScreenMenuError,
      isLoading: isScreenMenuLoading,
      isFetching: isScreenmenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getScreenMenuRecords({
      entity: "CMS/ScreenMenus",
      entities: { Menu: {}, Screen: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      filter: `(MenuId eq ${parentId.Id})`,
      sort: sort,
      tag: [SCREEN_MENU_TAG, MENU_TAG],
    });
    setScreenMenuDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [parentId, pageSize, page, sort]);

  useEffect(() => {
    if (
      !isScreenMenuLoading &&
      !isScreenMenuError &&
      screenMenuList &&
      !isScreenmenuFetching
    ) {
      if (Object.entries(parentId)?.length > 0) {
        setScreenMenuDetails({
          ...screenMenuDetails,
          tableRowsData: screenMenuList.value
            ? screenMenuList.value
            : [],
          totalData:
            screenMenuList && screenMenuList["@odata.count"]
              ? screenMenuList["@odata.count"]
              : 0,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    screenMenuList,
    isScreenMenuError,
    isScreenMenuLoading,
    parentId,
    isScreenmenuFetching,
  ]);

  const column = [
    {
      Header: "#",
      accessor: "Id",
    },
    {
      Header: "Menu",
      accessor: "MenuDTO.Name",
    },
    {
      Header: "Screen",
      accessor: "ScreenDTO.Name",
    },
  ];

  const screenMenuValidationSchema = Yup.object().shape({
    Screen: Yup.mixed().required("Please select screen."),
  });

  const onSubmit = (values) => {
    const screenMenuDetail = {
      MenuId: parentId.Id,
      ScreenId: values.Screen.Id,
    };

    addScreenMenu({
      entity: "CMS/ScreenMenu",
      data: screenMenuDetail,
      tag: [SCREEN_MENU_TAG, MENU_TAG],
    }).then((response) => {
      if (!response.error) {
        successToast("Screen-menu added successfully.");
      }
      setIsAddModalOpen({ ...isAddModalOpen, screenMenu: false });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          Menu: parentId && parentId ? parentId : "",
          Screen: "",
          SortOrder: "",
        }}
        validationSchema={screenMenuValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;

          return (
            <Form>
              <AsyncPaginates
                value={values.Screen}
                label="Screen"
                name="Screen"
                entity="CMS/Screens"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Screen}
                fieldTouched={touched.Screen}
                handleBlur={handleBlur}
                required
              />
              <AsyncPaginates
                value={values.Menu}
                label="Menu"
                name="Menu"
                entity="CMS/Menu"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Menu}
                fieldTouched={touched.Menu}
                handleBlur={handleBlur}
                readonly={parentId && parentId}
                required
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  onClick={handleSubmit}
                  className="modal_ok float-right"
                  color="success"
                >
                  {isScreenMenuAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}{" "}
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {screenMenuDetails && (
          <DataReactTable
            reactTableData={screenMenuDetails}
            onAddClick={() => { }}
            title="Screen Menu"
            headerContent={false}
            isFetching={isScreenmenuFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
          />
        )}
      </div>
    </>
  );
};

ScreenMenuForm.propTypes = {
  parentId: PropTypes.number.isRequired,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object,
};

export default ScreenMenuForm;
