import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useAddMutation,
  useDeleteMutation,
} from "@/services/pcApi";
import { SHOPIFY_VENDORS_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ShopifyVendorsForm from "./ShopifyVendorsForm";
import { getAllShopifyVendors } from "../ShopifySlice";
import "react-toastify/dist/ReactToastify.css";

const ShopifyVendors = () => {
  const [appDetails, setAppDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [addShopifyVendors, { isLoading: isShopifyVendorsAddLoading }] = useAddMutation();
  const [deleteApp, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getShopifyVendorsRecords,
    {
      data: shopifyVendorsList,
      isError: isShopifyVendorsError,
      isLoading: isShopifyVendorsLoading,
      isFetching: isShopifyVendorsFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-"
      },
    },
    {
      Header: "Commission",
      accessor: "Commission",
      type: "Number",
      filter: true,
      Cell: (({ value }) => {
        return value ? `${value}%` : "0";
      })
    },
  ];

  const handleEditData = (shopifyVendorsData) => {
    setSelectedRecord(shopifyVendorsData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteApp({
      entity: "ShopifyReference/Vendor",
      id: selectedRecord.Id,
      tag: SHOPIFY_VENDORS_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("ShopifyVendors deleted successfully.");
      }
    });
  };

  const handleRefreshVendors = () => {
    addShopifyVendors({
      entity: "Shopify/CreateShopifyVendors",
      tag: SHOPIFY_VENDORS_TAG,
    }).then((response) => {
      if (!response.error) {
        if (response.data) successToast("ShopifyVendors updated successfully.");
        else successToast("Shopify vendors are already updated.");
      }
    });
  }

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getShopifyVendorsRecords({
      entity: "ShopifyReference/ShopifyVendors",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: SHOPIFY_VENDORS_TAG,
    });
    setAppDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isShopifyVendorsLoading &&
      !isShopifyVendorsError &&
      shopifyVendorsList &&
      !isShopifyVendorsFetching
    ) {
      dispatch(getAllShopifyVendors(shopifyVendorsList.value));
      let appData = [...shopifyVendorsList.value];
      setAppDetails({
        ...appDetails,
        tableRowsData: shopifyVendorsList.value ? appData : [],
        totalData:
          shopifyVendorsList && shopifyVendorsList["@odata.count"]
            ? shopifyVendorsList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
      });
    }
    // eslint-disable-next-line
  }, [
    isShopifyVendorsError,
    shopifyVendorsList,
    isShopifyVendorsLoading,
    isShopifyVendorsFetching,
  ]);

  return (
    <Container data-testid="test-app">
      <Row>
        {appDetails && (
          <DataReactTable
            reactTableData={appDetails}
            onAddClick={() => {
              handleRefreshVendors()
            }}
            buttonText={"Refresh"}
            isButtonLoading={isShopifyVendorsAddLoading}
            title={`Shopify Vendors`}
            isFetching={isShopifyVendorsFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          testid="addModal"
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} ShopifyVendors`}
          header
          btn="Success"
          message={
            <ShopifyVendorsForm
              editData={selectedRecord}
              setIsAddModalOpen={setIsAddModalOpen}
              isAddModalOpen={isAddModalOpen} />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete ${selectedRecord.Name}?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ShopifyVendors;
