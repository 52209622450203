import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { USER_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const UserForm = ({ editData, setIsOpenAddModal }) => {
  const [addUser, { isLoading: isUserAddLoading }] = useAddMutation();
  const [updateUser, { isLoading: isUserUpdateLoading }] = useUpdateMutation();
  const userValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    UserType: Yup.mixed().required("Please select user-type."),
  });

  const onSubmit = (values) => {
    const userDetail = {
      Name: values.Name,
      Description: values.Description,
      Email: values.EmailAddress,
      UserTypeId: values.UserType.Id,
      SsoIdentifier: values.SsoIdentifier,
    };

    if (editData && Object.entries(editData).length > 0) {
      userDetail.Id = editData.Id;
      updateUser({
        entity: "Auth/User",
        data: userDetail,
        tag: USER_TAG,
      }).then((respoanse) => {
        if (!respoanse.error) {
          !isUserUpdateLoading &&
            setIsOpenAddModal(false) &&
            successToast("User updated successfully.");
        }
      });
    } else {
      addUser({
        entity: "Auth/User",
        data: userDetail,
        tag: USER_TAG,
      }).then((response) => {
        if (!response.error) {
          !isUserAddLoading && setIsOpenAddModal(false);
          successToast("User added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        UserType: editData && editData.UserTypeDTO ? editData.UserTypeDTO : "",
        EmailAddress: editData && editData.Email ? editData.Email : "",
        SsoIdentifier:
          editData && editData.SsoIdentifier ? editData.SsoIdentifier : "",
      }}
      validationSchema={userValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="Email Address"
              name="EmailAddress"
              fieldErrors={errors.EmailAddress}
              fieldTouched={touched.EmailAddress}
              fieldValue={values.EmailAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
            />
            <AsyncPaginates
              value={values.UserType}
              label="User Type"
              name="UserType"
              entity="UserType"
              setFieldValue={setFieldValue}
              fieldErrors={errors.UserType}
              fieldTouched={touched.UserType}
              handleBlur={handleBlur}
              required
            />
            <CustomInput
              label="Sso Identifier"
              name="SsoIdentifier"
              fieldErrors={errors.SsoIdentifier}
              fieldTouched={touched.SsoIdentifier}
              fieldValue={values.SsoIdentifier}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readonly
            />
            <Button
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
              disabled={isUserAddLoading || isUserUpdateLoading}
            >
              {(isUserAddLoading || isUserUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

UserForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsOpenAddModal: PropTypes.func.isRequired,
};

export default UserForm;
