import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import CustomInput from "@/shared/components/form/CustomInput";
import { AsyncPaginate } from "react-select-async-paginate";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import {
  SCREEN_TAG,
  APPLICATION_SCREEN_TAG,
} from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const SEOForm = ({
  value,
  editData,
  setActiveTab,
  setIsAddModalOpen,
  isAddModalOpen,
  parentId,
}) => {

  const [addScreen, { isLoading: isScreenAddLoading }] = useAddMutation();
  const [addApplicationScreen, { isLoading: isApplicationScreenAddLoading }] =
    useAddMutation();
  const [updateScreen, { isLoading: isScreenUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(Object.values(editData)?.length ? editData : false);
  const ScreenValue = value ? value : editData

  const isDontedOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ]

  const onSubmit = (values) => {
    if (Object.values(ScreenValue).length === 0) {
      setActiveTab("1")
    }
    if (values) {
      const screenDetail = {
        Name: ScreenValue?.Name,
        Description: ScreenValue?.Description,
        ScreenTypeId: ScreenValue?.ScreenType.Id,
        Path: ScreenValue?.Path,
        MetaTitle: values.MetaTitle,
        MetaDescription: values.MetaDescription,
        MetaViewport: values.MetaViewport,
        MetaCharset: values.MetaCharset,
        IsIndex: values.IsIndex.value,
        IsFollow: values.IsFollow.value,
      };
      if (editRecordDetail) {
        screenDetail.Id = editRecordDetail.Id;
        updateScreen({
          entity: "CMS/Screen",
          data: screenDetail,
          tag: SCREEN_TAG,
        }).then((response) => {
          if (!response.error) {
            if (setActiveTab) {
              setIsAddModalOpen(false);
              successToast("Screen updated successfully.");
            }
          }
        });
      } else {
        addScreen({
          entity: "CMS/Screen",
          data: screenDetail,
          tag: SCREEN_TAG,
        }).then((response) => {
          if (!response.error) {
            setIsAddModalOpen(false);
            successToast("Screen added successfully.");
          }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        MetaTitle:
          editRecordDetail && editRecordDetail.MetaTitle
            ? editRecordDetail.MetaTitle
            : "",
        MetaDescription:
          editRecordDetail && editRecordDetail.MetaDescription
            ? editRecordDetail.MetaDescription
            : "",
        MetaViewport:
          editRecordDetail && editRecordDetail.MetaViewport
            ? editRecordDetail.MetaViewport
            : "",
        MetaCharset:
          editRecordDetail && editRecordDetail.MetaCharset
            ? editRecordDetail.MetaCharset
            : "",
        IsIndex:
          editRecordDetail && Object.entries(editRecordDetail)?.length
            ? {
              label: editRecordDetail?.IsIndex ? "Yes" : "No",
              value: editRecordDetail?.IsIndex,
            }
            : "",
        IsFollow:
          editRecordDetail && Object.entries(editRecordDetail)?.length
            ? {
              label: editRecordDetail?.IsFollow ? "Yes" : "No",
              value: editRecordDetail?.IsFollow,
            }
            : "",
      }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Meta Title"
              name="MetaTitle"
              fieldErrors={errors.MetaTitle}
              fieldTouched={touched.MetaTitle}
              fieldValue={values.MetaTitle}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaDescription"
              name="MetaDescription"
              fieldErrors={errors.MetaDescription}
              fieldTouched={touched.MetaDescription}
              fieldValue={values.MetaDescription}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaViewport"
              name="MetaViewport"
              fieldErrors={errors.MetaViewport}
              fieldTouched={touched.MetaViewport}
              fieldValue={values.MetaViewport}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <CustomInput
              label="MetaCharset"
              name="MetaCharset"
              fieldErrors={errors.MetaCharset}
              fieldTouched={touched.MetaCharset}
              fieldValue={values.MetaCharset}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="mb-3">
              <Label for="IsIndex">
                IsIndex
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsIndex"
                value={values.IsIndex}
                options={isDontedOptions}
                placeholder={`Select IsIndex`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsIndex", e)}
              />
            </div>
            <div className="mb-3">
              <Label for="IsFollow">
                IsFollow
              </Label>
              <AsyncPaginate
                className={`lazySelect`}
                name="IsFollow"
                value={values.IsFollow}
                options={isDontedOptions}
                placeholder={`Select IsFollow`}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("IsFollow", e)}
              />
            </div>
            <Button
              disabled={
                isScreenAddLoading ||
                isScreenUpdateLoading ||
                isApplicationScreenAddLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenAddLoading ||
                isScreenUpdateLoading ||
                isApplicationScreenAddLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

SEOForm.propTypes = {
  value: PropTypes.object,
  editData: PropTypes.object,
  setActiveTab: PropTypes.func,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object,
  parentId: PropTypes.number,
};

export default SEOForm;
