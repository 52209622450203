import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { RELATION_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const RelationForm = ({ editData, setIsAddModalOpen }) => {
  const [addRelation, { isLoading: isRelationAddLoading }] =
    useAddMutation();
  const [
    updateRelation,
    { isLoading: isRelationUpdateLoading },
  ] = useUpdateMutation();

  const relationValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    if (editData && Object.entries(editData).length > 0) {
      values.Id = editData.Id;
      updateRelation({
        entity: "OrgReference/Relation",
        data: values,
        tag: RELATION_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRelationUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Relation updated successfully.");
        }
      });
    } else {
      addRelation({
        entity: "OrgReference/Relation",
        data: values,
        tag: RELATION_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRelationAddLoading && setIsAddModalOpen(false);
          successToast("Relation added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={relationValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={
                isRelationAddLoading ||
                isRelationUpdateLoading
              }
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isRelationAddLoading ||
                isRelationUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RelationForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default RelationForm;
