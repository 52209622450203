import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { SCREEN_TYPE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ScreenTypeForm = ({ editData, setIsAddModalOpen }) => {
  const [addScreenType, { isLoading: isScreenTypeAddLoading }] =
    useAddMutation();
  const [updateScreenType, { isLoading: isScreenTypeUpdateLoading }] =
    useUpdateMutation();

  const screenTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
  });

  const onSubmit = (values) => {
    if (editData && Object.entries(editData).length > 0) {
      values.Id = editData.Id;
      updateScreenType({
        entity: "CMSReference/ScreenType",
        data: values,
        tag: SCREEN_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenTypeUpdateLoading &&
            setIsAddModalOpen(false) &&
            successToast("Screen-type updated successfully.");
        }
      });
    } else {
      addScreenType({
        entity: "CMSReference/ScreenType",
        data: values,
        tag: SCREEN_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isScreenTypeAddLoading && setIsAddModalOpen(false);
          successToast("Screen-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
      }}
      validationSchema={screenTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              disabled={isScreenTypeAddLoading || isScreenTypeUpdateLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isScreenTypeAddLoading || isScreenTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

ScreenTypeForm.propTypes = {
  editData: PropTypes.object.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default ScreenTypeForm;
