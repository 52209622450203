import React from "react";
import Panel from "@/shared/components/Panel";

const CrosslinkPanel = ({ title, description, colorStyle, onAddClick }) => {
  return (
    <Panel
      xs={12}
      md={12}
      lg={12}
      color={colorStyle}
      title={title}
      onAddClick={onAddClick}
    >
      <div className="typography-message">{description}</div>
    </Panel>
  );
};

export default CrosslinkPanel;
