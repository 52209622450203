import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import Modal from "@/shared/components/Modal";
import { successToast } from "@/shared/components/toast";
import CrosslinkPanel from "@/shared/components/entity_detail/CrosslinkPanel";
import {
  MENU_TAG,
  SCREEN_MENU_TAG,
  APPLICATION_MENU_TAG,
} from "@/shared/tagFile";
import AddModal from "@/shared/components/AddModal";
import ApplicationMenuForm from "./ApplicationMenuForm";
import ScreenMenuForm from "./ScreenMenuForm";

const Crosslinks = ({ displayData }) => {
  const { t } = useTranslation("common");
  const [deleteMenu, { isLoading: isDeleting }] = useDeleteMutation();
  const [screenMenuDetails, setScreenMenuDetails] = useState([]);
  const [applicationMenuDetails, setApplicationMenuDetails] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const history = useHistory();
  const [isAddModalOpen, setIsAddModalOpen] = useState({
    screenMenu: false,
    applicationMenu: false,
  });

  const [
    getScreenMenuRecords,
    {
      data: screenMenuData,
      isError: isScreenMenuError,
      isFetching: isScreenMenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  const [
    getApplicationMenuRecords,
    {
      data: applicationMenuData,
      isError: isApplicationMenuError,
      isFetching: isApplicationMenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getScreenMenuRecords({
      entity: "CMS/ScreenMenus",
      top: 3,
      filter: `MenuId eq ${displayData.Id}`,
      entities: { Menu: {}, Screen: {} },
      tag: [SCREEN_MENU_TAG, MENU_TAG],
    });
    getApplicationMenuRecords({
      entity: "CMS/ApplicationMenus",
      top: 3,
      filter: `MenuId eq ${displayData.Id}`,
      entities: { Menu: {}, Application: {} },
      tag: [APPLICATION_MENU_TAG, MENU_TAG],
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isScreenMenuFetching && !isScreenMenuError && screenMenuData) {
      setScreenMenuDetails(screenMenuData.value);
    }
  }, [screenMenuData, isScreenMenuError, isScreenMenuFetching]);

  useEffect(() => {
    if (!isApplicationMenuFetching && !isApplicationMenuError && applicationMenuData) {
      setApplicationMenuDetails(applicationMenuData.value);
    }
  }, [applicationMenuData, isApplicationMenuError, isApplicationMenuFetching]);

  const onAddClick = (title) => {
    let formName = `${title.charAt(0).toLowerCase()}${title
      .slice(1)
      .replace(/\s|\(|[0-9]|\)/g, "")}`;
    setIsAddModalOpen({ ...isAddModalOpen, [formName]: true });
  };

  const handleDelete = () => {
    deleteMenu({
      entity: deleteRecord.entity,
      id: deleteRecord.Id,
      tag: deleteRecord.tag,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast(`${deleteRecord.entity} deleted successfully.`);
      }
    });
  };

  return (
    <>
      <Col xl={3}>
        <Card className="vh-90">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {t("gamecodex.org.entity.crosslinks.section_title")}
              </h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <CrosslinkPanel
                title={`Screen Menu (${screenMenuData && screenMenuData["@odata.count"]
                  ? screenMenuData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  screenMenuDetails && screenMenuDetails.length > 0 ? (
                    <div>
                      {screenMenuDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMS/ScreenMenu`,
                                    tag: [SCREEN_MENU_TAG, MENU_TAG],
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Screen Name: </b>
                                  {value.ScreenDTO?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to='#'
                          onClick={(event) => {
                            history.push("/cms/screen-menu");
                          }}
                          className="disabledCursor"
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="warning"
              ></CrosslinkPanel>
              <CrosslinkPanel
                title={`Application Menu (${applicationMenuData && applicationMenuData["@odata.count"]
                  ? applicationMenuData["@odata.count"]
                  : "0"
                  })`}
                onAddClick={onAddClick}
                description={
                  applicationMenuDetails && applicationMenuDetails.length > 0 ? (
                    <div>
                      {applicationMenuDetails.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className="border-bottom-secondary d-flex">
                              <div
                                className="align-self-center mr-2 cursor-pointer"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeleteRecord({
                                    ...value,
                                    entity: `CMS/ApplicationMenu`,
                                    tag: [APPLICATION_MENU_TAG, MENU_TAG],
                                  });
                                }}
                              >
                                <MinusCircleIcon />
                              </div>
                              <div
                                className="border-bottom-secondary"
                                key={index}
                              >
                                <div>
                                  <b>Application Name: </b>
                                  {value.ApplicationDTO?.Name}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        );
                      })}
                      <div className="typography-message">
                        <Link
                          to="#"
                          onClick={(event) => {
                            history.push("/cms/application-menu");
                          }}
                          className="disabledCursor"
                        >
                          View All Records
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>No Records Found</div>
                  )
                }
                colorStyle="danger"
              ></CrosslinkPanel>
            </div>
          </CardBody>
        </Card>
      </Col>
      {
        isAddModalOpen.applicationMenu && (
          <AddModal
            toggle={() =>
              setIsAddModalOpen({ ...isAddModalOpen, applicationMenu: false })
            }
            color="success"
            title={`Add Application`}
            header
            btn="Success"
            xl
            message={
              <ApplicationMenuForm
                parentId={applicationMenuDetails[0]?.MenuDTO}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
              />
            }
          />
        )
      }
      {
        isAddModalOpen.screenMenu && screenMenuDetails?.length && (
          <AddModal
            toggle={() =>
              setIsAddModalOpen({ ...isAddModalOpen, screenMenu: false })
            }
            color="success"
            title={`Add Screen Menu`}
            header
            btn="Success"
            xl
            message={
              <ScreenMenuForm
                parentId={screenMenuDetails[0].ScreenDTO}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
              />
            }
          />
        )
      }
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default Crosslinks;
