import React, { useState, useEffect } from "react";
import moment from "moment";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import { useLazyGetRecordsQuery } from "@/services/pcApi";
import { successToast } from "@/shared/components/toast";
import { BACKERKIT_ORDERS_TAG } from "@/shared/tagFile";
import { formateColumnFilter } from "@/utils/columnFilter";
import BackerKitOrdersForm from "./BackerKitOrdersForm";
import "react-toastify/dist/ReactToastify.css";

const BackerKitOrders = () => {
  const [appDetails, setAppDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const [
    getBackerKitOrderRecords,
    {
      data: backerKitOrdersList,
      isError: isBackerKitOrdersError,
      isLoading: isBackerKitOrdersLoading,
      isFetching: isBackerKitOrdersFetching,
    },
  ] = useLazyGetRecordsQuery();

  const handleShowDetails = () => {
    successToast("Details page is coming soon")
  };

  const column = [
    {
      Header: "Id",
      accessor: "Id",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "Order Number",
      accessor: "BackerKitOrderId",
      Cell: (({ value }) => {
        return value ?? "-"
      })
    },
    {
      Header: "Processed Date",
      accessor: "PledgeAt",
      type: "Date",
      filter: false,
      Cell: (({ value }) => {
        return value ? moment(value).format('YYYY-MM-DD') : "-"
      })
    },
    {
      Header: "Items",
      filter: false,
      Cell: ((value) => {
        const totalQuantity = value?.row?.original?.BackerKitFulfillOrderSkuDTO?.reduce((acc, currentItem) => {
          return acc + currentItem.Quantity;
        }, 0);
        return (totalQuantity <= 1) ? `${totalQuantity} item` : `${totalQuantity} items`
      })
    },
    {
      Header: "Status",
      accessor: "OrderStatusDTO.Name",
      filter: false,
      Cell: (({ value }) => {
        return value ?? "-"
      })
    }
  ]

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getBackerKitOrderRecords({
      entity: "BackerKit_Fulfill/BackerKitFulfillOrders",
      entities: {
        PledgeStatus: {}, BackerKitFulfillOrderSkus: {}, "BackerKitFulfillOrderSkus.Sku": {}, OrderStatus: {}, Customer: {}, "Customer.Address": {},
      },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: BACKERKIT_ORDERS_TAG,
    });
    setAppDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isBackerKitOrdersLoading &&
      !isBackerKitOrdersError &&
      backerKitOrdersList &&
      !isBackerKitOrdersFetching
    ) {
      let appData = [...backerKitOrdersList.value];
      setAppDetails({
        ...appDetails,
        tableRowsData: backerKitOrdersList.value ? appData : [],
        totalData:
          backerKitOrdersList && backerKitOrdersList["@odata.count"]
            ? backerKitOrdersList["@odata.count"]
            : 0,
        onDisplayDetailClick: () => handleShowDetails(),
      });
    }
    // eslint-disable-next-line
  }, [
    isBackerKitOrdersError,
    backerKitOrdersList,
    isBackerKitOrdersLoading,
    isBackerKitOrdersFetching,
  ]);

  return (
    <Container data-testid="test-app">
      <Row>
        {appDetails && (
          <DataReactTable
            reactTableData={appDetails}
            onAddClick={() => {
              setIsAddModalOpen(true)
            }}
            buttonText={"Import CSV"}
            title={`BackerKit Orders`}
            isFetching={isBackerKitOrdersFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          testid="addModal"
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title="Import orders"
          header
          btn="Success"
          message={
            <BackerKitOrdersForm
              setIsAddModalOpen={setIsAddModalOpen}
              isAddModalOpen={isAddModalOpen} />
          }
        />
      )}
    </Container>
  );
};

export default BackerKitOrders;
