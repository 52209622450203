import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { USER_TAG } from "@/shared/tagFile";
import { useAddMutation } from "@/services/pcApi";
import { SUPER_ADMIN, ADMIN, USER } from "@/shared/userRoles";
import { auth } from "@/redux/actions/authActions";
import Loading from "@/shared/components/Loading";

const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;
const img = `${process.env.PUBLIC_URL}/img/landing/macbook.png`;

const Header = ({ setIsAuthorized }) => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();
  const [addUser, { isLoading: isUserAddLoading }] = useAddMutation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      user.role = [USER];
      Object.entries(user).forEach(([key, value]) => {
        if (key.includes("roles") && value.length > 0) {
          user.role = value;
        }
      });
      dispatch(
        auth({
          ...user,
          isAuthenticate: true,
        })
      );
      const userDetail = {
        Name: user.name,
        Description: user.Description ? user.Description : "",
        Email: user.email,
        SsoIdentifier: user.sub,
      };
      var accessToken = "";
      const getToken = async () => {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        localStorage.setItem("accessToken", accessToken);
      };
      getToken();
      addUser({
        entity: "Auth/CreateUser",
        data: userDetail,
        tag: USER_TAG,
      }).then(() => {
        if (user.role.includes(ADMIN) || user.role.includes(SUPER_ADMIN)) {
          history.push("/admin_dashboard");
        } else {
          history.push("/");
          localStorage.removeItem("accessToken");
          setIsAuthorized(false);
        }
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return isLoading || isUserAddLoading ? (
    <Loading loading={isLoading} />
  ) : (
    <div
      className="landing__header"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Container>
        <Row>
          <Col className="landing_col_container" md={12}>
            <h2 className="landing__header-title">
              Comprehensive management for all intrasource applications from the
              PC
            </h2>
            <p className="landing__header-subhead">
              Centralized administration for your entire portfolio.
            </p>

            <NavLink
              className="landing__btn landing__btn--header"
              to="#"
              onClick={loginWithRedirect}
            >
              Enter the Admin Site
            </NavLink>
            <img className="landing__header-img" src={img} alt="macbook" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
