import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "@/shared/components/form/CustomInput";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { ROLE_TYPE_TAG, ROLE_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const RoleTypeForm = ({
  editData,
  setIsOpenAddModal,
  parentData,
  isOpenAddModal,
}) => {
  const [addRoleType, { isLoading: isRoleTypeAddLoading }] = useAddMutation();
  const [addRole, { isLoading: isRoleAddLoading }] = useAddMutation();
  const [updateRoleType, { isLoading: isRoleTypeUpdateLoading }] =
    useUpdateMutation();

  const roleTypeValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    SortOrder: Yup.number().min(1).nullable(true),
  });

  const onSubmit = (values) => {
    const roleTypeDetail = {
      Name: values.Name,
      Description: values.Description,
    };

    if (values.SortOrder) roleTypeDetail.SortOrder = parseInt(values.SortOrder);

    if (editData && Object.entries(editData).length > 0) {
      roleTypeDetail.Id = editData.Id;
      updateRoleType({
        entity: "AuthReference/RoleType",
        data: roleTypeDetail,
        tag: ROLE_TYPE_TAG,
      }).then((respoanse) => {
        if (!respoanse.error) {
          !isRoleTypeUpdateLoading && setIsOpenAddModal(false);
          successToast("Role-type updated successfully.");
        }
      });
    } else {
      addRoleType({
        entity: "AuthReference/RoleType",
        data: roleTypeDetail,
        tag: ROLE_TYPE_TAG,
      }).then((response) => {
        if (!response.error) {
          if (isOpenAddModal.role) {
            const roleDetail = {
              Name: `${values.Name}_${parentData.Name}`,
              RoleTypeId: response.data.Id,
            };
            if (values.SortOrder)
              roleDetail.SortOrder = parseInt(values.SortOrder);
            addRole({
              entity: "Auth/Role",
              data: roleDetail,
              tag: ROLE_TAG,
            }).then((response) => {
              if (!response.error) {
                !isRoleAddLoading &&
                  setIsOpenAddModal({ ...isOpenAddModal, role: false });
              }
            });
          } else {
            !isRoleTypeAddLoading && setIsOpenAddModal(false);
          }
          successToast("Role-type added successfully.");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        Name: editData && editData.Name ? editData.Name : "",
        Description:
          editData && editData.Description ? editData.Description : "",
        SortOrder: editData && editData.SortOrder ? editData.SortOrder : 999,
      }}
      validationSchema={roleTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        } = formik;

        return (
          <Form>
            <CustomInput
              label="Name"
              name="Name"
              fieldErrors={errors.Name}
              fieldTouched={touched.Name}
              fieldValue={values.Name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              required
            />
            <CustomInput
              label="Description"
              name="Description"
              type="textarea"
              fieldErrors={errors.Description}
              fieldTouched={touched.Description}
              fieldValue={values.Description}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Button
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
              disabled={isRoleTypeAddLoading || isRoleTypeUpdateLoading}
            >
              {(isRoleTypeAddLoading || isRoleTypeUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RoleTypeForm.propTypes = {
  editData: PropTypes.object,
  setIsOpenAddModal: PropTypes.func.isRequired,
  parentData: PropTypes.object,
  isOpenAddModal: PropTypes.object,
};

export default RoleTypeForm;
