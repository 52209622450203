import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from 'lodash';
import CustomInput from "@/shared/components/form/CustomInput";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { CONTENT_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import InsertImage from "./InsertImage";
import InsertList from "./InsertList";

const ContentForm = ({
  editData,
  setIsAddModalOpen,
}) => {
  const [addContent, { isLoading: isContentAddLoading }] = useAddMutation();
  const [addMedia, { isLoading: isMediaAddLoading }] = useAddMutation();
  const [updateContent, { isLoading: isContentUpdateLoading }] =
    useUpdateMutation();
  const [editRecordDetail, setEditRecordDetail] = useState(
    editData ? editData : null
  );
  const contentValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter name."),
    ContentType: Yup.mixed().required("Please select content-type."),
  });

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  const onSubmit = (values) => {
    const filteredImages = values.ContentObject?.images && values.ContentObject.images?.filter((image) => image.url);
    if (filteredImages?.length) {
      values.ContentObject.images = filteredImages
    } else {
      delete values.ContentObject?.images
    }
    const contentDetail = {
      Name: values.Name,
      Description: values.Description,
      ContentTypeId: values.ContentType.Id,
      ContentObject: JSON.stringify(values.ContentObject),
    };
    if (editRecordDetail && Object.entries(editRecordDetail).length > 0) {
      contentDetail.Id = editRecordDetail.Id;
      updateContent({
        entity: "CMS/Content",
        data: contentDetail,
        tag: CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          !isContentUpdateLoading && setIsAddModalOpen(false);
          successToast("Content updated successfully.");
        }
      });
    } else {
      addContent({
        entity: "CMS/Content",
        data: contentDetail,
        tag: CONTENT_TAG,
      }).then((response) => {
        if (!response.error) {
          setEditRecordDetail(response.data);
          !isContentAddLoading && setIsAddModalOpen(false);
          successToast("Content added successfully.");
        }
      });
    }
  };

  const onFieldChange = (event, replace, index, value) => {
    let resp = {};
    event.forEach((val) => {
      var item = val;
      resp[item["label"].split(" ").join("")] = item.__isNew__
        ? ""
        : value[item.label];
      return resp;
    });
    replace(index, resp);
  };

  const getFieldValue = (field, data, index) => {
    switch (field) {
      case "images":
        return Object.keys(data).map((val) => ({
          label: val,
          value: val,
          isFixed:
            editData && editData.ContentObject
              ? JSON.parse(editData?.ContentObject)["images"]
                ? JSON.parse(editData?.ContentObject)["images"][index]
                  ? Object.keys(
                    JSON.parse(editData?.ContentObject)["images"][index]
                  ).includes(val)
                  : val === "name" || val === "url"
                : val === "name" || val === "url"
              : val === "name" || val === "url",
        }));
      case "lists":
        return Object.keys(data).map((val) => {
          return {
            label: val,
            value: val,
            isFixed:
              editData && editData.ContentObject
                ? JSON.parse(editData?.ContentObject)["lists"]
                  ? JSON.parse(editData?.ContentObject)["lists"][index]
                    ? Object.keys(
                      JSON.parse(editData?.ContentObject)["lists"][index]
                    ).includes(val)
                    : val === "name"
                      ? true
                      : false
                  : val === "name"
                    ? true
                    : false
                : val === "name"
                  ? true
                  : false,
          };
        });
      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{
        Name:
          editRecordDetail && editRecordDetail.Name
            ? editRecordDetail.Name
            : "",
        Description:
          editRecordDetail && editRecordDetail.Description
            ? editRecordDetail.Description
            : "",
        ContentType:
          editRecordDetail && editRecordDetail.ContentTypeDTO
            ? editRecordDetail.ContentTypeDTO
            : "",
        ContentObject:
          editRecordDetail && editRecordDetail.ContentObject
            ? JSON.parse(editRecordDetail.ContentObject)
            : "",
      }}
      validationSchema={contentValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize={false}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        } = formik;
        return (
          <Form>
            <Row className="mt-3" style={{ maxHeight: Object.keys(editData).length || values.ContentType ? "590px" : "", height: Object.keys(editData).length || values.ContentType ? "100%" : "", overflow: Object.keys(editData).length || values.ContentType ? "auto" : "" }}>
              <Col>
                <Row>
                  <Col sm={12} md={values.ContentObject?.images?.length || values.ContentObject?.lists?.length ? 6 : 12} lg={values.ContentObject?.images?.length || values.ContentObject?.lists?.length ? 4 : 6}>
                    {Object.keys(editData ?? {}).length > 0 && <CustomInput label="Id" fieldValue={editData.Id} readonly />}
                    <CustomInput
                      label="Name"
                      name="Name"
                      fieldErrors={errors.Name}
                      fieldTouched={touched.Name}
                      fieldValue={values.Name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      required
                    />
                    <CustomInput
                      label="Description"
                      name="Description"
                      type="textarea"
                      fieldErrors={errors.Description}
                      fieldTouched={touched.Description}
                      fieldValue={values.Description}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <AsyncPaginates
                      value={values.ContentType}
                      label="Content Type"
                      name="ContentType"
                      entity="CMSReference/ContentTypes"
                      setFieldValue={setFieldValue}
                      fieldErrors={errors.ContentType}
                      fieldTouched={touched.ContentType}
                      isAllowToAddContent={true}
                      handleBlur={handleBlur}
                      required
                      handleEditRecords={editRecordDetail}
                    />
                    <Row>
                      {values?.ContentObject &&
                        Object.keys(values.ContentObject).map((data, fieldIndex) => {
                          if (data !== "images" && data !== "lists") {
                            return (
                              <Col
                                md={12}
                                key={fieldIndex}
                              >
                                <CustomInput
                                  label={_.startCase(data)}
                                  name={data}
                                  placeholder={_.startCase(data)}
                                  type={
                                    data.toLocaleLowerCase() === "description" &&
                                    "textarea"
                                  }
                                  fieldValue={values.ContentObject[data]}
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    const contentObjectData =
                                      values.ContentObject && values.ContentObject;
                                    contentObjectData[data] = e.target.value;
                                    setFieldValue("ContentObject", contentObjectData);
                                  }}
                                />
                              </Col>
                            );
                          }
                        })}
                    </Row>
                  </Col>
                  {((values.ContentObject?.images &&
                    values.ContentObject?.images?.length) ||
                    (values.ContentObject?.lists &&
                      values.ContentObject?.lists?.length)) && (
                      <Col
                        sm={
                          values.ContentObject?.images?.length ||
                            values.ContentObject?.lists?.length
                            ? 12
                            : ""
                        }
                        md={
                          values.ContentObject?.images?.length ||
                            values.ContentObject?.lists?.length
                            ? 6
                            : ""
                        }
                        lg={
                          values.ContentObject?.images?.length ||
                            values.ContentObject?.lists?.length
                            ? 4
                            : ""
                        }
                      >
                        <Row>
                          <InsertImage
                            formik={formik}
                            styles={styles}
                            getFieldValue={getFieldValue}
                            onFieldChange={onFieldChange}
                            addMedia={addMedia}
                            isMediaAddLoading={isMediaAddLoading}
                          />
                          <InsertList
                            formik={formik}
                            styles={styles}
                            getFieldValue={getFieldValue}
                            onFieldChange={onFieldChange}
                          />
                        </Row>
                      </Col>
                    )}
                  <Col
                    sm={12}
                    lg={
                      values.ContentObject?.images?.length ||
                        values.ContentObject?.lists?.length
                        ? 4
                        : 6
                    }
                  >
                    <CustomInput
                      label="ContentObject"
                      name="ContentObject"
                      type="codeblock"
                      fieldErrors={errors.ContentObject}
                      fieldTouched={touched.ContentObject}
                      fieldValue={
                        values.ContentObject
                          ? JSON.stringify(values.ContentObject, null, 4)
                          : JSON.stringify({})
                      }
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      readonly
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-end mt-3">
              <Button
                disabled={
                  isContentAddLoading ||
                  isContentUpdateLoading ||
                  isMediaAddLoading
                }
                onClick={handleSubmit}
                className="modal_ok float-right"
                color="success"
              >
                {(isContentAddLoading || isContentUpdateLoading) && (
                  <Spinner size="sm" variant="light" />
                )}{" "}
                Save
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

ContentForm.propTypes = {
  editData: PropTypes.object,
  setIsAddModalOpen: PropTypes.func,
};

export default ContentForm;
