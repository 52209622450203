import React from "react";
import { Route } from "react-router-dom";
import ApplicationType from "@/components/CMS/Application/ApplicationTypes/ApplicationType";
import Application from "@/components/CMS/Application/Applications/Application";
import ApplicationDetail from "@/components/CMS/Application/ApplicationDetail/ApplicationDetail";
import ScreenType from "@/components/CMS/Screen/ScreenTypes/ScreenType";
import ScreenDetail from "@/components/CMS/Screen/ScreenDetail/ScreenDetail";
import Screen from "@/components/CMS/Screen/Screen/Screen";
import Content from "@/components/CMS/Content/Content/Content";
import ContentType from "@/components/CMS/Content/ContentTypes/ContentType";
import ContentDetail from "@/components/CMS/Content/ContentDetail/ContentDetail";
import ApplicationContent from "@/components/CMS/Content/ApplicationContent/ApplicationContent";
import ApplicationScreen from "@/components/CMS/Screen/ApplicationScreen/ApplicationScreen";
import ScreenContent from "@/components/CMS/Screen/ScreenContent/ScreenContent";
import Component from "@/components/CMS/Component/Component";
import MenuType from "@/components/CMS/Menu/MenuType/MenuType";
import Menu from "@/components/CMS/Menu/Menu/Menu";
import ApplicationMenu from "@/components/CMS/Menu/ApplicationMenu/ApplicationMenu";
import ScreenMenu from "@/components/CMS/Menu/ScreenMenu/ScreenMenu";
import MenuDetail from "@/components/CMS/Menu/MenuDetail/MenuDetail";
import Media from "@/components/CMS/Media/Media/Media";
import MediaType from "@/components/CMS/Media/MediaType/MediaType";
import AppVersion from "@/components/CMS/AppVersion/AppVersion";

const CMSRoutes = () => (
  <div>
    {/* application */}
    <Route path="/cms/application" component={Application} />
    <Route path="/cms/application-type" component={ApplicationType} />
    <Route path="/cms/application-detail" component={ApplicationDetail} />
    {/* screen */}
    <Route path="/cms/screen" component={Screen} />
    <Route path="/cms/screen-detail" component={ScreenDetail} />
    <Route path="/cms/screen-type" component={ScreenType} />
    <Route path="/cms/screen-content" component={ScreenContent} />
    <Route path="/cms/application-screen" component={ApplicationScreen} />
    {/* component */}
    <Route path="/cms/component" component={Component} />
    {/* content */}
    <Route path="/cms/content" component={Content} />
    <Route path="/cms/content-type" component={ContentType} />
    <Route path="/cms/content-detail" component={ContentDetail} />
    <Route path="/cms/application-content" component={ApplicationContent} />
    {/* media */}
    <Route path="/cms/media" component={Media} />
    <Route path="/cms/media-type" component={MediaType} />
    {/* menu */}
    <Route path="/cms/menu" component={Menu} />
    <Route path="/cms/menu-type" component={MenuType} />
    <Route path="/cms/menu-detail" component={MenuDetail} />
    <Route path="/cms/application-menu" component={ApplicationMenu} />
    <Route path="/cms/screen-menu" component={ScreenMenu} />
    {/* AppVersion */}
    <Route path="/cms/app-version" component={AppVersion} />
  </div>
);

export default CMSRoutes;
