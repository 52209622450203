import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import { useLazyGetRecordsQuery, useAddMutation } from "@/services/pcApi";
import AsyncPaginates from "@/shared/components/LazySelect";
import { APPLICATION_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";

const ApplicationMenuForm = ({
  parentId,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {
  const [applicationMenuDetails, setApplicationMenuDetails] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [addApplicationMenu, { isLoading: isApplicationMenuAddLoading }] =
    useAddMutation();

  const [
    getApplicationMenuRecords,
    {
      data: applicationMenuList,
      isError: isApplicationMenuError,
      isLoading: isApplicationMenuLoading,
      isFetching: isApplicationmenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getApplicationMenuRecords({
      entity: "CMS/ApplicationMenus",
      entities: { Menu: {}, Application: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      filter: `(MenuId eq ${parentId?.Id})`,
      sort: sort,
      tag: [APPLICATION_MENU_TAG, MENU_TAG],
    });
    setApplicationMenuDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [parentId, pageSize, page, sort]);

  useEffect(() => {
    if (
      !isApplicationMenuLoading &&
      !isApplicationMenuError &&
      applicationMenuList &&
      !isApplicationmenuFetching
    ) {
      if (Object.entries(parentId)?.length > 0) {
        setApplicationMenuDetails({
          ...applicationMenuDetails,
          tableRowsData: applicationMenuList.value
            ? applicationMenuList.value
            : [],
          totalData:
            applicationMenuList && applicationMenuList["@odata.count"]
              ? applicationMenuList["@odata.count"]
              : 0,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    applicationMenuList,
    isApplicationMenuError,
    isApplicationMenuLoading,
    parentId,
    isApplicationmenuFetching,
  ]);

  const column = [
    {
      Header: "#",
      accessor: "Id",
    },
    {
      Header: "Menu",
      accessor: "MenuDTO.Name",
    },
    {
      Header: "Application",
      accessor: "ApplicationDTO.Name",
    },
  ];

  const applicationMenuValidationSchema = Yup.object().shape({
    Application: Yup.mixed().required("Please select application."),
  });

  const onSubmit = (values) => {
    const applicationMenuDetail = {
      MenuId: parentId.Id,
      ApplicationId: values.Application.Id,
    };

    addApplicationMenu({
      entity: "CMS/ApplicationMenu",
      data: applicationMenuDetail,
      tag: [APPLICATION_MENU_TAG, MENU_TAG],
    }).then((response) => {
      if (!response.error) {
        successToast("Application-menu added successfully.");
      }
      setIsAddModalOpen({ ...isAddModalOpen, applicationMenu: false });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          Application: "",
          Menu: parentId && parentId ? parentId : "",
        }}
        validationSchema={applicationMenuValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = formik;

          return (
            <Form>
              <AsyncPaginates
                value={values.Application}
                label="Application"
                name="Application"
                entity="CMS/Applications"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Application}
                fieldTouched={touched.Application}
                handleBlur={handleBlur}
                required
              />
              <AsyncPaginates
                value={values.Menu}
                label="Menu"
                name="Menu"
                entity="CMS/Menus"
                setFieldValue={setFieldValue}
                fieldErrors={errors.Menu}
                fieldTouched={touched.Menu}
                handleBlur={handleBlur}
                readonly={parentId && parentId}
                required
              />
              <div className="modal_ok d-flex justify-content-end">
                <Button
                  onClick={handleSubmit}
                  className="modal_ok float-right"
                  color="success"
                >
                  {isApplicationMenuAddLoading && (
                    <Spinner size="sm" variant="light" />
                  )}{" "}
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {applicationMenuDetails && (
          <DataReactTable
            reactTableData={applicationMenuDetails}
            onAddClick={() => { }}
            title="Application Menu"
            headerContent={false}
            isFetching={isApplicationmenuFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
          />
        )}
      </div>
    </>
  );
};

ApplicationMenuForm.propTypes = {
  parentId: PropTypes.number.isRequired,
  setIsAddModalOpen: PropTypes.func,
  isAddModalOpen: PropTypes.object,
};

export default ApplicationMenuForm;
