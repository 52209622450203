import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import Activity from "@/shared/components/entity_detail/Activity";
import Summary from "@/shared/components/entity_detail/Summary";
import AddModal from "@/shared/components/AddModal";
import { useLazyGetRecordsByIdQuery } from "@/services/pcApi";
import { SCREEN_TAG } from "@/shared/tagFile";
import Collapse from "@/shared/components/Collapse";
import ScreenForm from "../Screen/ScreenForm";
import Crosslinks from "./Crosslinks";
import "react-toastify/dist/ReactToastify.css";

const ScreenDetail = (props) => {
  const screenId = JSON.parse(props.location.state?.screenId);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [screenDetail, setScreenDetail] = useState();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const [
    getScreenRecords,
    {
      data: screenList,
      isError: isScreenError,
      isLoading: isScreenLoading,
      isFetching: isScreenFetching,
    },
  ] = useLazyGetRecordsByIdQuery();

  useEffect(() => {
    if (screenId) {
      getScreenRecords({
        entity: "CMS/Screen",
        entities: { ScreenType: {} },
        id: screenId,
        tag: SCREEN_TAG,
      });
    }
    // eslint-disable-next-line
  }, [screenId]);

  useEffect(() => {
    if (!isScreenLoading && !isScreenError && screenList && !isScreenFetching) {
      setScreenDetail(screenList);
    }
    // eslint-disable-next-line
  }, [isScreenError, screenList, isScreenLoading, isScreenFetching]);

  return (
    <Container>
      {screenDetail && (screenDetail?.Name || screenDetail?.name) ? (
        <Row>
          <Summary
            displayData={screenDetail}
            title="Screen"
            setIsAddModalOpen={setIsAddModalOpen}
            restContent={
              screenDetail ? (
                <>
                  <hr />
                  <div>
                    <Collapse
                      className="with-shadow"
                      title="About this screen"
                      collapse={isCollapseOpen}
                      onClick={() => {
                        setIsCollapseOpen(!isCollapseOpen);
                      }}
                    >
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Path:</th>
                            <td>
                              {screenDetail.path ? screenDetail.path : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                </>
              ) : null
            }
          ></Summary>
          <Activity></Activity>
          <Crosslinks displayData={screenDetail}></Crosslinks>
          {isAddModalOpen && (
            <AddModal
              toggle={() => setIsAddModalOpen(!isAddModalOpen)}
              color="success"
              title="Edit Screen"
              header
              btn="Success"
              message={
                <ScreenForm
                  editData={screenDetail}
                  setIsAddModalOpen={setIsAddModalOpen}
                />
              }
            />
          )}
        </Row>
      ) : (
        <Spinner size="sm" variant="light" />
      )}
    </Container>
  );
};

export default ScreenDetail;
