import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { USER_TAG } from "@/shared/tagFile";
import { formateColumnFilter } from "@/utils/columnFilter";
import { successToast } from "@/shared/components/toast";
import UserForm from "./UserForm";
import { getAllUsers } from "../../AuthSlice";
import "react-toastify/dist/ReactToastify.css";

const User = () => {
  const [userDetails, setUserDetails] = useState();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteUser, { isLoading: isDeleting }] = useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const [
    getUserRecords,
    {
      data: userList,
      isError: userError,
      isLoading: userLoading,
      isFetching: userFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Name",
      accessor: "Name",
      filter: true,
    },
    {
      Header: "Description",
      accessor: "Description",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Email",
      accessor: "Email",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "UserType",
      accessor: "UserTypeDTO.Name",
      filter: true,
    },
    {
      Header: "Sso Identifier",
      accessor: "SsoIdentifier",
      filter: true,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
  ];

  const handleSelectedRecord = (userData) => {
    setSelectedRecord(userData);
    setIsOpenAddModal(true);
  };

  const handleDelete = () => {
    deleteUser({
      entity: "Auth/User",
      id: selectedRecord.Id,
      tag: USER_TAG,
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsOpenDeleteModal(!isOpenDeleteModal);
        successToast("User deleted successfully.");
      }
    });
  };

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );
    getUserRecords({
      entity: "Auth/Users",
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      entities: { UserType: {} },
      tag: USER_TAG,
    });
    setUserDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (!userLoading && !userError && userList && !userFetching) {
      dispatch(getAllUsers(userList.value));
      let userData = [...userList.value];
      setUserDetails({
        ...userDetails,
        tableRowsData: userList.value ? userData : [],
        totalData:
          userList && userList["@odata.count"] ? userList["@odata.count"] : 0,
        onEditClick: (data) => handleSelectedRecord(data),
        onDeleteClick: (data) => {
          setIsOpenDeleteModal(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [userError, userList, userLoading, userFetching]);

  const deleteMessage = () => {
    return (
      <div>
        Are you sure you want to delete {selectedRecord?.Name}?
        <div className="warning-div">
          <i>
            This will delete all the associated records <br />
            ( Like - Entity, Role, Contacts, UserLocations, UserRelationRequests, EntityRelation, VendorRelation)
          </i>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        {userDetails && (
          <DataReactTable
            reactTableData={userDetails}
            title="User"
            isFetching={userFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isOpenAddModal && (
        <AddModal
          toggle={() => setIsOpenAddModal(!isOpenAddModal)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} User`}
          header
          btn="Success"
          message={
            <UserForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsOpenAddModal={setIsOpenAddModal}
            />
          }
        />
      )}
      {isOpenDeleteModal && (
        <Modal
          color="danger"
          title="Delete"
          message={deleteMessage()}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default User;
