import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import AsyncPaginates from "@/shared/components/LazySelect";
import { useAddMutation, useUpdateMutation } from "@/services/pcApi";
import { successToast } from "@/shared/components/toast";
import { ROLE_TAG } from "@/shared/tagFile";

const RoleForm = ({
  editData,
  setIsOpenAddModal,
}) => {
  const [addRole, { isLoading: isRoleAddLoading }] = useAddMutation();
  const [updateRole, { isLoading: isRoleUpdateLoading }] = useUpdateMutation();

  const roleValidationSchema = Yup.object().shape({
    RoleType: Yup.mixed().required("Please select role-type."),
    User: Yup.mixed().required("Please select entity."),
  });

  const onSubmit = (values) => {
    const roleDetail = {
      RoleTypeId: values.RoleType.Id,
      UserId: values.User.Id,
    };

    if (editData && Object.entries(editData).length > 0) {
      roleDetail.Id = editData.Id;
      updateRole({
        entity: "Auth/Role",
        data: roleDetail,
        tag: ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleUpdateLoading && setIsOpenAddModal(false)
          successToast("Role updated successfully.");
        }
      });
    } else {
      addRole({
        entity: "Auth/Role",
        data: roleDetail,
        tag: ROLE_TAG,
      }).then((response) => {
        if (!response.error) {
          !isRoleAddLoading && setIsOpenAddModal(false)
          successToast("Role added successfully.");
        }
      });
    }
  };
  return (
    <Formik
      initialValues={{
        RoleType: editData && editData.RoleTypeDTO ? editData.RoleTypeDTO : "",
        User:
          editData && editData.UserDTO
            ? editData.UserDTO
            : "",
      }}
      validationSchema={roleValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Form>
            <AsyncPaginates
              value={values.User}
              label="User"
              name="User"
              entity="Auth/Users"
              setFieldValue={setFieldValue}
              fieldErrors={errors.User}
              fieldTouched={touched.User}
              handleBlur={handleBlur}
              required
            />
            <AsyncPaginates
              value={values.RoleType}
              label="Role Type"
              name="RoleType"
              entity="AuthReference/RoleTypes"
              setFieldValue={setFieldValue}
              fieldErrors={errors.RoleType}
              fieldTouched={touched.RoleType}
              handleBlur={handleBlur}
              required
            />
            <Button
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
              disabled={isRoleAddLoading || isRoleUpdateLoading}
            >
              {(isRoleAddLoading || isRoleUpdateLoading) && (
                <Spinner size="sm" variant="light" />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RoleForm.propTypes = {
  editData: PropTypes.object,
  setIsOpenAddModal: PropTypes.func.isRequired,
};

export default RoleForm;
