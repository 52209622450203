import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Row, Spinner, Table } from 'reactstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Pagination, PaginationItem, PaginationLink, FormGroup, Input } from "reactstrap";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import DetailIcon from "mdi-react/EyeIcon";
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import moment from 'moment';

const ReactTable = ({ columns, title, data, isLoading = false, isSortable = true, onClick, onEditClick, onDeleteClick, onDisplayDetailClick }) => {
	const [dataArray, setDataArray] = useState(data);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		prepareRow,
		page,
		state,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		gotoPage,
		setPageSize,
	} = useTable(
		{
			columns,
			data: dataArray,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const setColumnFilter = (column, searchValue) => {
		const filteredItems = data.filter(item => {
			let columnValue;
			let columnWithValue;
			if (column.id.includes('.')) {
				columnValue = getColumnValue(item, column.id);
			} else if (column?.with) {
				columnValue = String(item[column.id]).toLowerCase();
				columnWithValue = String(item[column.with]).toLowerCase();
			} else {
				columnValue = String(item[column.id]).toLowerCase();
			}

			if (columnValue && typeof columnValue === 'string' && columnWithValue && typeof columnWithValue === 'string') {
				return columnValue.toLowerCase().includes(searchValue.toLowerCase()) || columnWithValue.toLowerCase().includes(searchValue.toLowerCase());
			} else if (columnValue && typeof columnValue === 'string') {
				return columnValue.toLowerCase().includes(searchValue.toLowerCase());
			}
			return false;
		});

		if (filteredItems.length > 0) {
			setDataArray(filteredItems);
		} else {
			setDataArray([]);
		}
	};

	const getColumnValue = (item, columnId) => {
		const keys = columnId.split('.');
		let value = item;
		for (const key of keys) {
			if (value && value.hasOwnProperty(key)) {
				value = value[key];
			} else {
				return undefined;
			}
		}
		return value;
	};

	const headerPropsSortable = (props, { column }) => {
		if (column.getSortByToggleProps && isSortable) {
			return column.getSortByToggleProps;
		}
		return { column };
	};
	const headerPropsResize = (props, { column }) => {
		if (column.getResizerProps) {
			return getStylesResizable(props, column.align);
		}
		return { column };
	};

	const { globalFilter, pageIndex, pageSize } = state;

	useEffect(() => {
		setDataArray(data)
	}, [data])

	const arrayPageIndex =
		pageIndex - 2 < 0
			? pageOptions.slice(0, pageIndex + 3)
			: pageOptions.slice(pageIndex - 2, pageIndex + 3);

	return (
		<Col md={12} lg={12}>
			<Card>
				<CardBody>
					<div className="react-table__wrapper">
						<div className="card__title">
							<h5 className="bold-text">{title}</h5>
						</div>
					</div>
					<div>
						<div className='mt-2 table__search d-flex justify-content-between'>
							<div className="d-flex w-25 flex-column">
								<Input
									className="table__search table__search-input"
									value={globalFilter || ''}
									onChange={(e) => setGlobalFilter(e.target.value)}
									placeholder="Search"
								/>
								<span className='font-weight-bold'>Last synced: {data?.length && moment(data?.[0]?.lastSynced).calendar()}</span>
							</div>
							<Button
								color="primary"
								className="rounded float-right"
								onClick={onClick}
								disabled={isLoading}
							>
								{isLoading && (<Spinner size="sm" variant="light" />)}{" "}
								Refresh
							</Button>
						</div>
						{isLoading ? (
							<div className="d-flex justify-content-center my-2">
								<Spinner size="sm" variant="light" />
							</div>
						) : (
							<div className="table react-table">
								<table {...getTableProps()} className="react-table resizable-table">
									<thead className="thead th">
										{headerGroups.map((headerGroup, i) => (
											<tr
												{...headerGroup.getHeaderGroupProps()}
												key={i}
												className="react-table thead tr"
											>
												{headerGroup?.headers?.[0]?.Header.toLowerCase() !== "id" && <th
													colSpan="1"
													role="columnheader"
													style={{ position: "relative", width: "150px" }}
												>
													<span className="react-table__column-header react-table_column-header_no-filter">
														<span className="react-table__column-header sortable ">
															#
														</span>
													</span>
												</th>}
												{
													headerGroup.headers.map((column, index) => {
														return (
															<th key={index}
																{...column.getHeaderProps(headerPropsSortable)}
																{...column.getHeaderProps(headerPropsResize)}
																{...column.getHeaderProps({ style: { width: column.width } })}
															>
																<span
																	className={`react-table__column-header ${column.filter ? "react-table_column-header_no-filter" : ""
																		}`}
																>
																	<span
																		className={isSortable ? `react-table__column-header sortable ` : ""}
																	>
																		{column.render('Header')}
																	</span>
																	{isSortable && column.canSort && column.Header !== "Action" && (
																		<span className="ml-2 react-table__column-header sortable">
																			{column.isSortedDesc === undefined ? (
																				<SortIcon />
																			) : (
																				<span>
																					{column.isSortedDesc ? <SortDescendingIcon /> : <SortAscendingIcon />}
																				</span>
																			)}
																		</span>)}
																</span>
																{column?.filter == false ? <div></div> : (
																	<input
																		type="text"
																		onChange={(e) => setColumnFilter(column, e.target.value)}
																		placeholder={`Search ${column.render('Header')} `}
																		className="column-filter-input"
																	/>
																)}
															</th>
														)
													})
												}
												{(onEditClick || onDeleteClick || onDisplayDetailClick) && <th
													colSpan="1"
													role="columnheader"
													style={{ position: "relative", width: "150px" }}
												>
													<span className="react-table__column-header react-table_column-header_no-filter">
														<span className="react-table__column-header sortable ">
															Action
														</span>
													</span>
												</th>}
											</tr>
										))}
									</thead>
									{isLoading ? "Loading" : dataArray?.length === 0 ? <div>No data found.</div> :
										<tbody {...getTableBodyProps()}>
											{page?.map((row, index) => {
												prepareRow(row);
												const rowIndex = index + pageIndex * pageSize + 1;
												return (
													<tr {...row.getRowProps()} key={index}>
														<td>{rowIndex}</td>
														{/* onClick={() => handleShowDetails(row.original)} style={{ cursor: `${hasHandleShow ? `pointer` : ""}` }}> */}
														{row.cells.map((cell, cellIndex) => {
															if (
																cell.column.Header === "Action" ||
																cell.column.Header === "Media"
															) {
																return (
																	<td key={cellIndex}>
																		{cell.row.original[`${cell.column.Header}`]}
																	</td>
																);
															}
															return (
																<td
																	key={cellIndex}
																	{...cell.getCellProps({
																		style: { width: cell.column.Width },
																	})}>
																	{cell.render('Cell')}
																</td>
															)
														})}
														<td>
															<div className="d-flex">
																{onDisplayDetailClick && (
																	<div
																		className="cursor-pointer"
																		onClick={() => {
																			if (onDisplayDetailClick) {
																				onDisplayDetailClick(row.original);
																			} else {
																				console.log("Eye button clicked.");
																			}
																		}}
																	>
																		<DetailIcon />
																	</div>
																)}
																{onEditClick && (
																	<div
																		className="cursor-pointer"
																		onClick={() => {
																			onEditClick(row.original);
																		}}
																	>
																		<EditIcon />
																	</div>
																)}
																{onDeleteClick && (
																	<div
																		className="cursor-pointer"
																		onClick={() => {
																			onDeleteClick(row.original);
																		}}
																	>
																		<DeleteIcon />
																	</div>
																)}
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>}
									<tfoot className="tfoot">
										{footerGroups.map(group => (
											<tr {...group.getFooterGroupProps()}>
												{group.headers.map(column => (
													<td {...column.getFooterProps()}>{column.render('Footer')}</td>
												))}
											</tr>
										))}
									</tfoot>
								</table>
							</div>
						)}
						<Pagination className="pagination" dir="ltr">
							<div className="pagination">
								<PaginationLink
									className="pagination__link pagination__link--arrow"
									type="button"
									onClick={() => gotoPage(0)}
									disabled={!canPreviousPage}
								>
									<ChevronDoubleLeftIcon className="pagination__link-icon" />
								</PaginationLink>
								<PaginationLink
									className="pagination__link pagination__link--arrow"
									type="button"
									onClick={previousPage}
									disabled={!canPreviousPage}
								>
									<ChevronLeftIcon className="pagination__link-icon" />
								</PaginationLink>
								{isLoading ? <div className="d-flex justify-content-center "><Spinner size="sm" variant="light" /></div> : arrayPageIndex.map((i) => (
									<PaginationItem
										className="pagination__item"
										active={pageIndex === i}
										key={i}
									>
										<PaginationLink
											key={i}
											className="pagination__link"
											type="button"
											onClick={() => gotoPage(i)}
										>
											{i + 1}
										</PaginationLink>
									</PaginationItem>
								))}
								<PaginationItem className="pagination__item">
									<PaginationLink
										className="pagination__link pagination__link--arrow"
										type="button"
										onClick={nextPage}
										disabled={!canNextPage}
									>
										<ChevronRightIcon className="pagination__link-icon" />
									</PaginationLink>
								</PaginationItem>
								<PaginationItem className="pagination__item">
									<PaginationLink
										className="pagination__link pagination__link--arrow"
										type="button"
										onClick={() => gotoPage(pageOptions.length - 1)}
										disabled={!canNextPage}
									>
										<ChevronDoubleRightIcon className="pagination__link-icon" />
									</PaginationLink>
								</PaginationItem>
								{isLoading ? '' : <PaginationItem className="pagination__item pagination-info">
									Showing {pageSize * pageIndex + 1} to{" "}
									{pageSize * pageIndex + page.length} of {pageOptions.length}
								</PaginationItem>}
								{[10, 20, 30, 40, 50].length > 1 && (
									<PaginationItem className="pagination__item">
										<FormGroup className="pagination__select-form ">
											<Input
												className="pagination__item pagination-info"
												type="select"
												name="select"
												id="exampleSelect"
												value={pageSize}
												onChange={(event) => {
													setPageSize(Number(event.target.value));
												}}
											>
												{[10, 20, 30, 40, 50].map((item) => (
													<option
														className="pagination__item pagination__item-option"
														key={item}
														value={item}
													>
														Show {item}
													</option>
												))}
											</Input>
										</FormGroup>
									</PaginationItem>
								)}
							</div>
						</Pagination>
					</div>
				</CardBody>
			</Card>
		</Col >
	);
};

export default ReactTable;
