import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import DataReactTable from "@/containers/Tables/DataTable/components/DataReactTable";
import AddModal from "@/shared/components/AddModal";
import Modal from "@/shared/components/Modal";
import {
  useLazyGetRecordsQuery,
  useDeleteMutation,
} from "@/services/pcApi";
import { APPLICATION_MENU_TAG, MENU_TAG } from "@/shared/tagFile";
import { successToast } from "@/shared/components/toast";
import { formateColumnFilter } from "@/utils/columnFilter";
import ApplicationMenuForm from "./ApplicationMenuForm";
import { getAllApplicationMenus } from "../../CMSSlice";
import "react-toastify/dist/ReactToastify.css";

const ApplicationMenu = () => {
  const [applicationMenuDetails, setApplicationMenuDetails] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteApplicationMenu, { isLoading: isDeleting }] =
    useDeleteMutation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [globalSearchKeyword, setGlobalSearchKeyword] = useState(null);
  const [columnFilter, setColumnFilter] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [
    getApplicationMenuRecords,
    {
      data: applicationMenuList,
      isError: isApplicationMenuError,
      isLoading: isApplicationMenuLoading,
      isFetching: isApplicationMenuFetching,
    },
  ] = useLazyGetRecordsQuery();

  const column = [
    {
      Header: "#",
      accessor: "Id",
      type: "Number",
    },
    {
      Header: "Application Name",
      accessor: "ApplicationDTO.Name",
      filter: true,
    },
    {
      Header: "Menu Name",
      accessor: "MenuDTO.Name",
      filter: true,
    },
  ];

  const handleEditData = (applicationMenuData) => {
    setSelectedRecord(applicationMenuData);
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    deleteApplicationMenu({
      entity: "CMS/ApplicationMenu",
      id: selectedRecord.Id,
      tag: [APPLICATION_MENU_TAG, MENU_TAG],
    }).then((response) => {
      if (!response.error) {
        !isDeleting && setIsDeleteModalOpen(!isDeleteModalOpen);
        successToast("Application-menu deleted successfully.");
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state?.name) {
      setColumnFilter({
        [`${location.state.title.split(" ").join("")}.Name`]:
          location.state.name,
      });
    }
    history.push({ pathname: location.pathname, state: {} });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { filterColumn, filterQuery } = formateColumnFilter(
      column,
      columnFilter
    );

    getApplicationMenuRecords({
      entity: "CMS/ApplicationMenus",
      entities: { Menu: {}, Application: {} },
      top: pageSize,
      skip: (page - 1) * pageSize,
      sort: sort,
      filter: filterQuery,
      globalFilter: {
        columns: filterColumn,
        globalSearchKeyword: globalSearchKeyword,
      },
      tag: [APPLICATION_MENU_TAG, MENU_TAG],
    });
    setApplicationMenuDetails({ tableHeaderData: column, tableRowsData: [] });
    // eslint-disable-next-line
  }, [pageSize, page, sort, globalSearchKeyword, columnFilter]);

  useEffect(() => {
    if (
      !isApplicationMenuLoading &&
      !isApplicationMenuError &&
      applicationMenuList &&
      !isApplicationMenuFetching
    ) {
      dispatch(getAllApplicationMenus(applicationMenuList.value));
      let applicationMenuData = [...applicationMenuList.value];
      setApplicationMenuDetails({
        ...applicationMenuDetails,
        tableRowsData: applicationMenuList.value ? applicationMenuData : [],
        totalData:
          applicationMenuList && applicationMenuList["@odata.count"]
            ? applicationMenuList["@odata.count"]
            : 0,
        onEditClick: (data) => handleEditData(data),
        onDeleteClick: (data) => {
          setIsDeleteModalOpen(true);
          setSelectedRecord(data);
        },
      });
    }
    // eslint-disable-next-line
  }, [
    isApplicationMenuError,
    applicationMenuList,
    isApplicationMenuLoading,
    isApplicationMenuFetching,
  ]);

  return (
    <Container>
      <Row>
        {applicationMenuDetails && (
          <DataReactTable
            reactTableData={applicationMenuDetails}
            onAddClick={() => {
              setIsAddModalOpen(true);
              setSelectedRecord();
            }}
            title="Application Menu"
            isFetching={isApplicationMenuFetching}
            setPageNumber={setPage}
            recordsPerPage={pageSize}
            setRecordsPerPage={setPageSize}
            setSort={setSort}
            setGlobalSearchKeyword={setGlobalSearchKeyword}
            setColumnFilter={setColumnFilter}
          />
        )}
      </Row>
      {isAddModalOpen && (
        <AddModal
          toggle={() => setIsAddModalOpen(!isAddModalOpen)}
          color="success"
          title={`${selectedRecord ? "Edit" : "Add"} Application Menu`}
          header
          btn="Success"
          message={
            <ApplicationMenuForm
              editData={selectedRecord ? selectedRecord : {}}
              setIsAddModalOpen={setIsAddModalOpen}
            />
          }
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          color="danger"
          title="Delete"
          message={`Are you sure you want to delete?`}
          handleOkClick={() => handleDelete()}
          toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          isDeleting={isDeleting}
        />
      )}
    </Container>
  );
};

export default ApplicationMenu;
