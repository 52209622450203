import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EditIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import DetailIcon from "mdi-react/EyeIcon";
import { ThemeProps } from "@/shared/prop-types/ReducerProps";
import { Spinner } from "reactstrap";

const ReactTableDefaultBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  isFetching,
  onEditClick,
  onDeleteClick,
  onDisplayDetailClick,
}) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page && page.length > 0 ? (
      page.map((row, index) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()} key={index}>
            {row.cells.map((cell, cellIndex) => {
              if (
                cell.column.Header === "Action" ||
                cell.column.Header === "Media"
              ) {
                return (
                  <td key={cellIndex}>
                    {cell.row.original[`${cell.column.Header}`]}
                  </td>
                );
              }
              return (
                <td
                  key={cellIndex}
                  {...cell.getCellProps({
                    style: { width: cell.column.Width },
                  })}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}
            <td>
              <div className="d-flex">
                {onDisplayDetailClick && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (onDisplayDetailClick) {
                        onDisplayDetailClick(row.original);
                      } else {
                        console.log("Eye button clicked.");
                      }
                    }}
                  >
                    <DetailIcon />
                  </div>
                )}
                {onEditClick && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      onEditClick(row.original);
                    }}
                  >
                    <EditIcon />
                  </div>
                )}
                {onDeleteClick && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      onDeleteClick(row.original);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
      })
    ) : page.length === 0 && !isFetching ? (
      <tr>
        <td colSpan={4}>No data found</td>
      </tr>
    ) : (
      <tr>
        <td>
          <Spinner className="table-fetch-spinner" />
        </td>
      </tr>
    )}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  isFetching,
  onEditClick,
  onDeleteClick,
  onDisplayDetailClick,
}) => (
  <Fragment>
    <ReactTableDefaultBody
      page={page}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
      isFetching={isFetching}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onDisplayDetailClick={onDisplayDetailClick}
    />
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  theme: ThemeProps.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  theme: state.theme,
}))(ReactTableBody);
