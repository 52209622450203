import { createSlice } from "@reduxjs/toolkit";

const ShopifySlice = createSlice({
  name: "shopify",
  initialState: {
    shopifyVendors: [],
    suplimentalSales: [],
  },
  reducers: {
    getAllShopifyVendors: (state, action) => {
      state.shopifyVendors = action.payload;
    },
    getAllSupplementalSales: (state, action) => {
      state.suplimentalSales = action.payload;
    },
  },
});

export default ShopifySlice.reducer;
export const {
  getAllShopifyVendors,
  getAllSupplementalSales,
} = ShopifySlice.actions;
